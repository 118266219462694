@import '../../theme/vars.scss'; 

.modal {
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: $font-size-base;
  text-align: center;
}

.doc {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: $spacing-default;
  justify-content: space-between;

  &Error {
    color: $color-red;
  }

  &Item {
    text-align: left;
  }

  &Link {
    align-items: center;
    justify-content: center;
  }
}
