@import '../../theme/vars.scss'; 

.form {
  display: flex;
  flex-direction: column;
  gap: $spacing-default;
}

.fieldset {
  border: 0;
  display: flex;
  flex-direction: column;
  gap: $spacing-small;
}

.block {
  background-color: $color-gray-100;
  border-radius: $border-radius-input;
  padding: $spacing-default;

  &__shipment {
    display: flex;
    flex-direction: column;
    gap: $spacing-small;
  }

  &__rating {
    align-items: center;
    display: flex;
    gap: $spacing-small;
  }

  &__comment {
    display: flex;
    flex-direction: column;
    gap: $spacing-default;
  }
}

.row {
  display: flex;
  flex-direction: row;
  gap: $spacing-default;
}

.column {
  display: flex;
  flex-direction: column;
  gap: $spacing-tiny;
}

.truck {
  position: relative;

  &__count {
    position: absolute;
    right: 10px;
    top: 3px;
  }
}