@import '../../theme/vars.scss'; 

.form {
  display: flex;
  flex-direction: column;
  gap: $spacing-default;
}

.fieldset {
  background-color: $color-gray-100;
  border: 0;
  display: flex;
  flex-direction: column;
  gap: $spacing-small;
  padding: $spacing-default;
}

