@import '../../theme/vars.scss'; 

.servererror {
  animation: appear 0.2s ease-in;
  animation-direction: normal;
  background-color: $color-gray-100;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  @include zIndex(30);
  @include zIndex(8);

  &Image {
    height: 140px;
    width: 140px;
  }

  &Wrap {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: 40%;
    transform: translate(-50%, -50%);
  }
}

.textBase {
  color: $color-black;
  font-size: 36px;
}

.subTextBase {
  color: $color-black;
  font-size: $font-size-base;
  margin-top: $spacing-small;
}

.wrapBg {
  background-color: $color-white;
  margin-top: $spacing-large;
  padding-bottom: $spacing-large;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 30px;
  position: relative;
  text-align: center;
  width: 788px;
}

.footer {
  bottom: $spacing-default;
  color: $color-black;
  font-size: $font-size-base;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.social {
  bottom: (-$spacing-default);
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.version {
  font-size: $font-size-base;
  margin-top: $spacing-default;
}

.process {
  height: 141px;
}
