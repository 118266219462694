@import '../../theme/vars.scss'; 

.catalog {
  column-count: 3;
  flex-grow: 0;
  list-style: none;
  padding: 0;

  @include mq(lg, md) {
    column-count: 2;
    width: 100%;
  }

  @include mq(md) {
    column-count: 1;
    width: 100%;
  }

  &_pageCatalog {
    margin-top: $spacing-big;
  }
}
