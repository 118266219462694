@import '../../theme/vars.scss'; 

.circle {
  display: block;
}

.mask,
.fill {
  border-radius: $border-radius-circle;
  outline: 1px solid transparent;
  position: absolute;
  transition: transform 1s;
}

.fill {
  background-color: $color-sapphirine;

  &Completed {
    background-color: $color-green;
  }

  &Hovered {
    background-color: $color-orange;
  }
}

.radialProgress {
  background-color: $color-gray-200;
  border-radius: $border-radius-circle;
  position: relative;

  &medium {
    $circle-size: 70px;
    height: $circle-size;
    width: $circle-size;

    .mask,
    .fill {
      height: $circle-size;
      width: $circle-size;
    }

    .mask {
      clip: rect(0, $circle-size, $circle-size, $circle-size * 0.5);
    }

    .fill {
      clip: rect(0, $circle-size * 0.5, $circle-size, 0);
    }

    .inset {
      height: $circle-size - 20;
      width: $circle-size - 20;
    }
  }

  &small {
    $circle-size: 38px;
    height: $circle-size;
    width: $circle-size;

    .mask,
    .fill {
      height: $circle-size;
      width: $circle-size;
    }

    .mask {
      clip: rect(0, $circle-size, $circle-size, $circle-size  * 0.5);
    }

    .fill {
      clip: rect(0, $circle-size * 0.5, $circle-size, 0);
    }

    .inset {
      height: $circle-size - 6;
      width: $circle-size - 6;
    }
  }
}

.inset {
  align-items: center;
  background-color: $color-white;
  border-radius: $border-radius-circle;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
}

.percentage {
  display: flex;
  font-weight: $font-semibold;

  &Hovered {
    color: $color-orange;
  }
}
