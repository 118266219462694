@import '../../theme/vars.scss'; 

.headerIcon {
  align-items: center;
  display: flex;
  filter: $filter-image-blue;
  height: 40px;

  &Loyalty {
    filter: none;
  }
}

.iconWrapper {
  align-items: flex-start;
  background-color: $header-menu-item-color;
  border-radius: $border-radius-circle;
  display: flex;
  height: 27px;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  width: 27px;

  &Loyalty {
    align-items: center;
    background-color: $color-yellow;
  }

  &_flame {
    background-color: $color-gray-200;
    height: 27px;

    .headerIcon {
      filter: $filter-image-orange;
      margin-top: -8px;
    }

    &.iconWrapper_disabled {
      .headerIcon {
        filter: $filter-image-gray;
      }
    }
  }

  &_headerCatalog {
    .headerIcon {
      height: 20px;
      width: 20px;
    }
  }

  &_history {
    .headerIcon {
      height: 27px;
      width: 22px;
    }
  }

  &_918ok {
    background: none;
    width: auto;

    .headerIcon {
      filter: none;
      height: 32px;
      overflow: hidden;
      width: 88px;
    }
  }

  &_loyaltyStatus {
    .headerIcon {
      height: 27px;
      overflow: hidden;
      width: 22px;
    }
  }

  &_bronzovaya {
    background-color: $filters-link-color;
    color: $color-white;
  }

  &_serebryanaya {
    background-color: $color-gray-200;
    color: $color-black;
  }

  &_zolotaya {
    background-color: $color-yellow;
    color: $color-black;

    .headerIcon {
      color: $color-white;
    }
  }

  &_vip {
    background-color: $maincolor;
    color: $color-white;

    .headerIcon {
      color: $color-white;
    }
  }

  &_disabled {
    background-color: $color-gray-200;
    color: $color-gray-300;
  }

  &_loyalty {
    background-color: transparent;
    border-radius: $border-radius-default;
    height: auto;
    width: auto;

    .headerIcon {
      filter: none;
      height: 34px;
      width: 99px;
    }
  }

  &_whyWe {
    .headerIcon {
      height: 27px;
      width: 36px;
    }
  }

  &_actual {
    .headerIcon {
      height: 33px;
      width: 33px;
    }
  }

  &_registration {
    .headerIcon {
      height: 27px;
      width: 26px;
    }
  }

  &_phone {
    .headerIcon {
      height: 23px;
      width: 23px;
    }
  }

  &_personalCabinet {
    .headerIcon {
      height: 20px;
      width: 22px;
    }
  }

  &_iconOffers {
    .headerIcon {
      height: 35px;
      width: 34px;
    }
  }

  &_help {
    .headerIcon {
      height: 26px;
      width: 26px;
    }
  }

  &_iconGear {
    .headerIcon {
      height: 27px;
      width: 27px;
    }
  }

  &_percent {
    .headerIcon {
      height: 20px;
      width: 20px;
    }
  }

  &_manager {
    .headerIcon {
      height: 30px;
      width: 29px;
    }
  }

  &_phone,
  &_manager,
  &_personalCabinet,
  &_help,
  &_whyWe,
  &_registration,
  &_iconOffers,
  &_manager {
    color: $header-icon-color;
  }
}

.menuItemsCount {
  background-color: $color-red;
  border-radius: 25px;
  color: $color-white;
  font-weight: $font-semibold;
  position: absolute;
  right: (-$spacing-default);
  text-align: center;
  top: -5px;
  width: 28px;
  @include zIndex(44);
}

.headerLink {
  align-items: center;
  display: flex;
  height: 20px;
  justify-content: center;
  line-height: $line-height-small;

  &Disabled {
    color: $color-gray-300;
  }

  &Empty {
    white-space: pre;
  }
}

.headerDropDown {
  background-color: $color-white;
  border: 1px solid $color-gray-200;
  border-radius: $border-radius-input;
  box-shadow: 0 4px 4px rgba(192, 192, 192, 0.5);
  font-weight: normal;
  opacity: 0;
  position: absolute;
  text-align: left;
  top: 66px;
  transition: visibility 0s linear 0.3s;
  visibility: hidden;
  @include zIndex(11);
}

.headerMenuItem {
  align-items: center;
  display: flex;
  height: 66px;
  justify-content: center;

  &:hover,
  &_active {
    .headerLink {
      color: $header-text-hover-color;

      &Loyalty {
        color: $color-black;
      }
    }

    .headerIcon {
      filter: $filter-image-orange;

      &Loyalty {
        filter: $filter-image-blue;
      }
    }

    .iconWrapper {
      background-color: $header-icon-hover-background;

      &Loyalty {
        background-color: rgba(35, 58, 187, 0.3);
      }
    }
  }

  @include desktopHover {
    cursor: pointer;

    .headerDropDown {
      opacity: 1;
      visibility: visible;
    }

    .iconWrapper {
      &_disabled {
        background-color: $color-gray-200;
        color: $color-gray-300;
      }

      &_918ok {
        background: none;
      }

      &_loyalty {
        background-color: transparent;
      }
    }
  }
}

.headerMenuColor {
  @include desktopHover {
    .headerIcon {
      filter: $filter-image-orange;
    }
  }
}

.headerMenuBlock {
  align-items: center;
  display: flex;
  justify-content: center;

  &Item {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 61px;
    justify-content: space-between;
  }
}
