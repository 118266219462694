@import '../../theme/vars.scss'; 

.modal {
  &Container {
    display: flex;
    font-size: $font-size-base;

    @include mq(lg) {
      gap: $spacing-default;
      margin-bottom: 60px;
    }

    @include mq(md) {
      align-items: center;
      flex-direction: column;
    }
  }
}

.rules {
  border-left: 2px solid $color-gray-200;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: $spacing-large;
  width: 556px;

  @include mq(lg) {
    border: unset;
    justify-content: flex-start;
    max-width: 400px;
    padding: 0;
    width: 100%;
  }

  &Top {
    display: flex;
    flex-direction: column;
  }

  &Table {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacing-large;
  }

  &Header {
    display: flex;
    font-weight: $font-semibold;
    margin-bottom: $spacing-small;

    @include mq(lg) {
      font-size: $font-size-base;
    }
  }

  &Row {
    display: flex;
    margin: $spacing-tiny 0;
  }

  &Column {
    align-items: center;
    display: flex;
    line-height: $line-height-default;

    @include mq(lg) {
      font-size: $font-size-base;
    }

    &Icon {
      margin-right: $spacing-small;
      width: 32px;
    }
  }

  &Step {
    align-items: center;
    border: 1px solid $color-blue;
    border-radius: $border-radius-circle;
    color: $color-blue;
    display: flex;
    font-size: $font-size-h2;
    height: 32px;
    justify-content: center;
    width: 32px;
  }

  &Icon {
    filter: $filter-image-blue;
    height: 20px;
    width: 20px;
  }

  &Bottom {
    display: flex;
    flex-direction: column;

    @include mq(lg) {
      font-size: $font-size-base;
    }

    &Title {
      font-weight: $font-semibold;
      margin-bottom: $spacing-small;
    }

    &Phone {
      color: $color-blue;
      margin-top: $spacing-tiny;
      @include typo-h1-lg();
    }
  }
}

