@import '../../theme/vars.scss'; 

.headerAuth {
  display: flex;
  justify-content: center;

  &__fields {
    background-color: transparent;
    display: grid;
    gap: $spacing-tiny;
    padding: $spacing-default $spacing-tiny;
    position: relative;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: $spacing-tiny;
    padding: $spacing-default $spacing-tiny;
    position: relative;
  }
}

.pageAuth {
  display: inline-block;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 498px;
  padding: $spacing-default $spacing-big;
  position: relative;
  text-align: left;
  vertical-align: middle;
  white-space: initial;
  width: 100%;

  &__fields {
    background-color: $color-gray-100;
    border-radius: $border-radius-input;
    display: grid;
    gap: $spacing-tiny;
    padding: $spacing-default;
    position: relative;
  }

  &__buttons {
    align-items: flex-end;
    display: flex;
    flex-direction: column-reverse;
    gap: $spacing-default;
    padding: $spacing-small 0;
    position: relative;
  }

  &__showPassword {
    align-items: center;
    bottom: $spacing-default;
    color: $color-blue-200;
    display: flex;
    font-size: $font-size-base;
    height: 32px;
    justify-content: left;
    padding: 0;
    position: absolute;
    text-align: right;

    &_header {
      right: $spacing-small;
    }

    &_pageAuth {
      right: $spacing-big;
    }

    @include desktopHover {
      cursor: pointer;
    }
  }

  &__regWrap {
    @include mq(lg) {
      display: none;
    }
  }
}

.headerDropDown {
  border-radius: $border-radius-input;
  box-shadow: 0 4px 4px rgba(192, 192, 192, 0.5);
  font-weight: normal;
  position: absolute;
  text-align: left;
  @include zIndex(40);

  .tooltip {
    border-right-color: $color-red;
    left: -1px;
    right: auto;
    transform: scaleX(-1);

    &::before {
      border-bottom-color: $color-red;
    }
  }
}

.headerAuthErrors {
  background-color: $color-white;
  border: 1px solid $color-red;
  color: $color-red;
  display: block;
  left: $spacing-default;
  line-height: $line-height-default;
  padding: $spacing-small $spacing-default;
  top: 90px;
  width: 250px;
}

.tooltip {
  background: $color-white;
  border-right: 1px solid $color-white;
  height: 11px;
  position: absolute;
  right: -1px;
  top: -8px;
  width: 11px;

  &::before {
    background: transparent;
    border-bottom: 1px solid $color-white;
    content: '';
    display: block;
    height: 10px;
    position: absolute;
    right: 1px;
    top: -5px;
    transform: rotate(-35deg);
    width: 13px;
  }

  &_errorTitle {
    font-weight: $font-semibold;
  }
}

.authErrors {
  background-color: $color-red-100;
  border-radius: $border-radius-input;
  box-shadow: none;
  color: $color-red;
  display: block;
  font-size: $font-size-base;
  line-height: $line-height-default;
  padding: $spacing-tiny $spacing-small;
  position: absolute;
  right: $spacing-tiny;
  top: (-$spacing-default);
  width: auto;

  .authErrorTitle {
    align-items: center;
    display: flex;
    font-size: $font-size-base;
    justify-content: center;
  }
}
