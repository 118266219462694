@import '../../theme/vars.scss'; 

.header {
  display: none;

  @include mq(lg) {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &__temp {
    background: $color-red-100;
    color: $color-red;
    font-size: $font-size-base;
    position: relative;
    width: 100%;
    @include zIndex(31);
  }

  &__notifications {
    background-color: $color-gray-100;
    display: flex;
    justify-content: center;
    padding: $spacing-tiny 0;
    width: 100%;

    &_wrapper {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__info {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include mq(lg, md) {
      margin: $spacing-big 0 $spacing-small;
    }

    @include mq(md) {
      margin: $spacing-tiny 0;
    }
  }

  &__search {
    position: relative;
    width: 100%;

    &_wrapper {
      height: 46px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      @include zIndex(44);
    }
  }

  &__phone {
    flex-direction: row;
    font-size: $font-size-base;
    height: auto;

    :global {
      .src-components-Header-HeaderMenuItem--headerMenuBlockItem {
        flex-direction: row;
        height: auto;
      }

      .src-components-Header-HeaderMenuItem--headerLink {
        margin-left: $spacing-default;
      }

      .src-components-Header-HeaderMenuItem--headerLinkEmpty {
        margin-left: 0;
      }
    }

    @include mq(lg, md) {
      flex: 1;
    }

    @include mq(md) {
      margin-right: $spacing-default;
    }

    &_unauth {
      justify-content: flex-end;

      @include mq(md) {
        margin-right: 0;
      }
    }
  }

  &__basket {
    height: 38px;
    width: 172px;

    &_position {
      @include zIndex(8);
    }

    &_loyalty {
      @include mq(md) {
        :global {
          .src-components-Header-HeaderGift--wrapper {
            flex-direction: row;
            gap: $spacing-small;
            height: 38px;
          }
        }
      }
    }

    &_fixed_loyalty {
      position: relative;

      :global {
        .src-components-Header-HeaderGift--wrapper {
          flex-direction: row;
          gap: $spacing-small;
          height: 38px;
        }

        .src-components-Header-HeaderGift--basketCount {
          bottom: $spacing-tiny;
        }

        .src-components-Header-HeaderGift--wrapPrice {
          color: $color-white;
        }
      }
    }

    &_content {
      display: flex;
      gap: $spacing-small;

      @include zIndex(12);
    }

    &_absolute {
      position: absolute;
    }

    &_fixed {
      display: flex;

      @include mq(lg) {
        align-items: center;
        margin: 0 auto;
        position: absolute;
        right: 0;
        top: $spacing-tiny;
        width: 100%;

        :nth-child(2) {
          display: none;
          height: 0;
          width: 0;
        }
      }

      @include mq(lg, md) {
        :global {
          .src-components-Header-HeaderCart--basketCount {
            top: 0;
          }
        }
      }

      :global {
        .src-components-Header-HeaderCart--icon {
          filter: invert(1);
        }

        .src-components-Header-HeaderCart--wrapInfo,
        .src-components-Header-HeaderCart--wrapPrice {
          color: $color-white;
        }
      }
    }
  }

  &__panel {
    height: 46px;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    width: 100%;
    @include zIndex(46);

    &::before {
      background-color: $maincolor;
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transform: scaleX(0);
      transition: all 0.1s ease-out;
    }

    &_loyalty::before {
      background-color: $color-purple;
    }

    &_bookmark::before {
      background-color: $bookmarkmaincolor;
    }

    &_visible {
      opacity: 1;
      @include zIndex(8);

      &::before {
        transform: scaleX(1);
      }
    }
  }

  &__button {
    &_change {
      border: 1px solid $color-white;
      border-radius: $border-radius-button;
      padding: $spacing-tiny 0 $spacing-tiny $spacing-tiny;
    }
  }
}
