@import '../../theme/vars.scss'; 

.cartChange {
  width: auto;
}

.title {
  font-size: $font-size-h2;
  width: 100%;
}

.desc {
  font-size: $font-size-base;
}

.carts {
  padding-left: $spacing-tiny;
  width: auto;
}

.cart {
  border: 1px solid $color-gray-200;
  border-radius: $border-radius-button;
  display: flex;
  flex-direction: column;
  margin-bottom: $spacing-small;
  min-height: 135px;
  padding: $spacing-default $spacing-small;
  position: relative;
  width: 290px;

  @include mq(md) {
    width: calc(100% - $spacing-small);
  }

  @include desktopHover {
    cursor: pointer;
  }

  &Wide {
    width: 100%;
  }

  &Title {
    align-items: center;
    display: flex;
    padding-bottom: $spacing-tiny;
  }

  &Name {
    color: $color-blue;
    font-size: $font-size-base;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &Icon {
    margin-right: $spacing-tiny;
  }

  &Count {
    display: flex;
    font-size: $font-size-base;
    padding-bottom: $spacing-tiny;
  }

  &Sum {
    align-items: center;
    display: flex;
    font-size: $font-size-base;
    justify-content: flex-start;
    padding-bottom: (-$spacing-tiny);
  }

  &Points {
    align-items: center;
    color: $color-purple;
    display: flex;
    font-size: $font-size-base;
    justify-content: flex-start;
  }
}

.add {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;

  &Icon {
    height: auto;
    margin-right: $spacing-small;
    width: 17px;
  }

  &Name {
    color: $color-blue;
    font-size: $font-size-base;
  }
}

.action {
  border: 1px dashed $color-blue;
  justify-content: center;

  &Empty {
    padding-bottom: $spacing-default;
  }
}

.act {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.active {
  border: 1px solid $color-blue;
}

.icon {
  filter: $filter-image-blue;
}

.key {
  padding-right: $spacing-tiny;
}

.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: $spacing-small;
  width: 100%;
}

.button {
  align-items: center;
  background-color: $color-blue;
  border: 1px solid $color-blue;
  border-radius: $border-radius-button;
  color: $color-white;
  display: flex;
  font-size: $font-size-base;
  justify-content: center;
  padding: $spacing-small 0;
  transition: all 0.25s ease;
  width: 100%;

  &Cancel {
    background-color: $color-white;
    border-radius: $spacing-tiny 0 0 $spacing-tiny;
    color: $color-blue;

    @include desktopHover {
      background-color: $color-blue;
      color: $color-white;
    }
  }

  &Accept {
    border-radius: 0 4px 4px 0;

    @include desktopHover {
      background-color: $color-blue-200;
      border: 1px solid $color-blue-200;
    }
  }

  &Disabled {
    background-color: $color-gray-200;
    cursor: default;
    pointer-events: none;

    @include desktopHover {
      cursor: default;
    }
  }
}

.settings {
  background-color: $color-gray-200;
  border-radius: 0 4px 0 0;
  position: absolute;
  right: 0;
  top: 0;

  @include mq(lg) {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    gap: $spacing-small;
    right: $spacing-small;
    top: $spacing-default;
  }
}

.setting {
  @include desktopHover {
    cursor: pointer;
  }

  &Icon {
    height: 14px;
    margin: $spacing-tiny;
    width: 14px;

    @include desktopHover {
      filter: $filter-image-blue;
    }
  }

  &First {
    border-radius: 0 4px 0 0;
  }
}

.slider {
  position: relative;

  @include mq(md) {
    @include fadeEffect;
  }

  :global {
    .slick-prev {
      left: 0;
      top: 50%;
    }

    .slick-next {
      right: $spacing-small;
      top: 50%;
    }
  }
}
