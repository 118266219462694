@import '../../theme/vars.scss'; 

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.icon {
  align-items: center;

  border-radius: $border-radius-circle;
  display: flex;
  height: 20px;
  justify-content: center;
  margin-right: $spacing-small;
  width: 20px;

  &Step1 {
    background-color: $color-yellow-200;
  }

  &Step2 {
    background-color: $color-green;
  }

  &Info {
    height: 13px;
  }

  &Check {
    filter: invert(1);
    height: 9px;
    width: 9px;
  }
}

.desc {
  font-size: $font-size-base;
  line-height: $line-height-default;
  padding-bottom: $spacing-large;
  padding-top: $spacing-big;
  text-align: center;
}

.buttons {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.button {
  border: 0;
  border-radius: $border-radius-button;
  font-size: $font-size-base;
  height: 43px;

  &Yes {
    background-color: $maincolor;
    color: $color-white;
    width: 161px;

    @include desktopHover {
      background-color: $color-blue-200;
    }
  }

  &No {
    background-color: transparent;
    color: $color-black;
    width: 114px;

    @include desktopHover {
      background-color: $color-gray-200;
    }
  }
}

.link {
  color: $color-blue;

  @include desktopHover {
    color: $link-color-hover;
    cursor: pointer;
  }
}
