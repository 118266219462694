@import '../../theme/vars.scss'; 

.info {
  height: 0;
  position: relative;
  right: -100%;
  top: 120px;
  width: 0;
  @include zIndex(12);

  &Fixed {
    left: 80px;
    top: $spacing-small;
  }
}

.wrapper {
  background-color: $color-white;
  border: 1px solid $color-gray-200;
  border-radius: $border-radius-button;
  padding: $spacing-default 0;
  position: absolute;
  right: 0;
  width: 485px;
}

.title {
  align-items: center;
  display: flex;
  font-size: $font-size-base;
  justify-content: flex-start;
  margin: 0 $spacing-big;
  position: relative;

  &Delete {
    color: $color-red;
  }

  &Item {
    display: inline;
    text-align: left;
  }
}

.hr {
  background-color: $color-gray-200;
  border: unset;
  color: $color-gray-200;
  height: 1px;
  margin: $spacing-small 0;
}

.products {
  width: 100%;
}

.product {
  align-items: center;
  display: grid;
  font-weight: normal;
  grid-template-columns: 40px 260px 70px 70px;
  margin-left: $spacing-big;
  padding-bottom: $spacing-small;
  padding-right: $spacing-big;
  text-align: left;

  &Image {
    height: 34px;
    width: 34px;
  }
}

.action {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 0 $spacing-big;
}

.button {
  align-items: center;
  background-color: $color-gray-100;
  border-radius: $border-radius-button;
  display: flex;
  margin-right: $spacing-small;
  padding: $spacing-tiny $spacing-small;

  @include desktopHover {
    background-color: lighten($color-gray-100, 10%);
    cursor: pointer;
  }
}

.moveIcon {
  filter: invert(1);
  margin-right: $spacing-tiny;
}

.add {
  background-color: $color-blue;
  color: $color-white;

  @include desktopHover {
    background-color: lighten($color-blue, 10%);
  }
}

.close {
  position: absolute;
  right: (-$spacing-default);

  @include desktopHover {
    cursor: pointer;

    img {
      filter: $filter-image-orange;
    }
  }
}
