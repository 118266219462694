@import '../../theme/vars.scss'; 

.columnRight {
  display: flex;
  pointer-events: all;
}

.rightColumn {
  background-color: $color-gray-100;
  color: $color-black;
  display: flex;
  font-size: $font-size-base;
  height: 100%;
  justify-content: space-between;
  margin-right: $spacing-default;
  padding: $spacing-default 0 $spacing-default $spacing-big;
  position: relative;
  width: auto;
  @include zIndex(43);
}

.catalogList {
  display: block;
}

.mainList {
  column-count: 3;
  column-gap: $spacing-tiny;
  flex-grow: 1;
  list-style: none;
  padding: 0;
  padding-right: $spacing-small;
}

.item {
  color: $color-black;
  line-height: $line-height-default;
  margin-bottom: $spacing-tiny;

  ul {
    margin: 0;
    width: auto;
  }
}

.header {
  break-inside: avoid;
  display: inline-block;
  font-weight: $font-semibold;
  line-height: $line-height-default;
  margin-bottom: $spacing-default;
  page-break-inside: avoid;
  vertical-align: top;
  width: 200px;

  ul {
    width: auto;
  }
}

.list {
  list-style: none;
  margin-top: $spacing-tiny;
  padding: 0;
  width: 230px;
}

.title {
  cursor: pointer;
  font-weight: $font-normal;
  line-height: $line-height-default;

  @include desktopHover {
    text-decoration: underline;
  }

  &.active {
    font-weight: $font-semibold;
  }
}

.titleHeader {
  cursor: pointer;
  font-weight: $font-semibold;
  line-height: $line-height-default;
  padding-right: $spacing-default;

  @include desktopHover {
    text-decoration: underline;
  }
}

.MenuCatalogOverlay {
  display: block;
}
