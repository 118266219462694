@import '../../theme/vars.scss'; 

.userManagers {
  color: $color-black;
  font-size: $font-size-base;
  line-height: $line-height-small;
  padding: $spacing-large $spacing-default $spacing-default;
}

.managerContacts {
  margin-top: $spacing-default;
}

.managerName {
  font-weight: $font-semibold;
}

.userManagersTitle {
  font-weight: $font-semibold;
  margin-bottom: $spacing-small;
}

.managerPhoto {
  margin-bottom: $spacing-tiny;
}

.manager {
  margin: auto;
  margin-bottom: $spacing-default;
  width: 196px;

  @include mq(lg) {
    margin: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.managerPosition .managerPhone {
  line-height: $line-height-default;
}

.managerEmail a {
  color: $maincolor;
  line-height: $line-height-default;

  @include desktopHover {
    color: $link-color-hover;
  }
}
