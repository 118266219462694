@import '../../theme/vars.scss'; 

.container {
  padding-top: $spacing-small;
}

.carts {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: $spacing-small;
  width: auto;
}

.cart {
  margin-right: $spacing-small;
  width: 300px;

  &:nth-child(3n) {
    margin-right: 0;
  }
}
