@import '../../theme/vars.scss'; 

.description {
  font-size: $font-size-base;
  padding-bottom: $spacing-small;
}

.errors {
  color: $color-red;
}

.messages {
  color: $color-black;
}

.messages .errors {
  padding-bottom: $spacing-tiny;
}

.submit {
  align-items: center;
  background-color: $button-secondary-bg;
  border: unset;
  color: $button-secondary-color;
  display: flex;
  font-size: $font-size-h3;
  height: 50px;
  justify-content: center;
  line-height: $line-height-default;
  margin-top: $spacing-big;
  transition: background-color ease-in 0.2s;
  width: 100%;

  &:disabled {
    background-color: $color-gray-200;
    border: 1px solid $color-gray-200;
    color: $color-white;
    cursor: default;
    pointer-events: none;
  }

  @include desktopHover {
    background-color: $button-secondary-bg-hover;
    cursor: pointer;
  }
}

.buttonRelative {
  margin-top: $spacing-small;
}

.input {
  background-color: $color-gray-100;
  display: flex;
  flex-direction: column;
  padding: $spacing-default;
}
