@import '../../theme/vars.scss'; 

.item {
  break-inside: avoid;
  display: flex;
  margin: 0;
  overflow: auto;
  padding: 0 $spacing-small $spacing-big;
  page-break-inside: avoid;
  width: 288px;

  @include mq(md) {
    width: 100%;
  }

  &_catalogPage {
    padding: 0 0 $spacing-big;
  }
}

.imageContainer {
  background-color: $color-white;
  padding-right: $spacing-small;
}

.links {
  width: 252px;

  @include mq(md) {
    width: 100%;
  }

  &Subitems, &Head {
    color: $color-black;
    display: block;
    line-height: $line-height-default;
    padding: $spacing-tiny 0;

    @include desktopHover {
      color: $link-color-hover;
      cursor: pointer;
      text-decoration: none;

      .count {
        color: inherit;
      }
    }
  }

  &Items {
    text-decoration: none;
  }
}

.image {
  &Category {
    align-items: flex-start;
    display: flex;
    max-height: 24px;
    max-width: 24px;
    width: 24px;

    &_icon {
      filter: $filter-image-blue;
    }
  }
}

.count {
  color: $color-gray-300;
  padding-left: $spacing-tiny;
}

.icon {
  height: 4px;
  width: 4px;
}


