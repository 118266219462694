@import '../../theme/vars.scss'; 

.hello {
  width: 100%;

  &Description {
    align-items: center;
    display: flex;
    height: 30px;
    justify-content: space-between;
  }

  &Item {
    font-size: $font-size-base;
    position: relative;
    @include zIndex(17);

    &_success {
      background-color: $color-green;

      .message,
      .link {
        color: $color-white;
      }

      .helloClose {
        border-color: $color-white;
      }

      .icon {
        filter: $filter-image-white;
      }
    }

    &_info {
      background-color: $color-blue-100;

      .message,
      .link {
        color: $color-blue;
      }

      .helloClose {
        border-color: $color-blue;
      }

      .icon {
        filter: $filter-image-blue;
      }
    }

    &_notice {
      background-color: $color-red-100;

      .message,
      .link {
        color: $color-red;
      }

      .helloClose {
        border-color: $color-red;
      }

      .icon {
        filter: $filter-image-red;
      }
    }

    &_warning {
      background-color: $color-yellow;

      .message,
      .link {
        color: $color-black;
      }

      .helloClose {
        border-color: $color-black;
      }
    }

    &_error {
      background-color: $color-red;

      .message,
      .link {
        color: $color-white;
      }

      .helloClose {
        border-color: $color-white;
      }

      .icon {
        filter: $filter-image-white;
      }
    }
  }

  &Close {
    align-items: center;
    border: 1px solid $color-gray-200;
    border-radius: $border-radius-block;
    display: flex;
    height: 20px;
    justify-content: center;
    margin: 0 $spacing-tiny 0 $spacing-default;
    width: 20px;

    @include desktopHover {
      cursor: pointer;
    }
  }
}

.message {
  max-height: 18px;
  overflow: hidden;
  width: 100%;
}

.link {
  align-items: center;
  display: flex;
  font-weight: $font-semibold;

  @include desktopHover {
    opacity: 0.5;
  }
}

.slide {
  display: block;
  font-size: $font-size-base;
}

.data {
  padding: 40px 45px;
}

.slider {
  width: 100%;

  :global {
    .slick-disabled {
      display: none !important;
    }

    .slick-arrow {
      background-color: transparent;
      top: 50%;

      @include desktopHover {
        filter: $filter-image-orange;
      }
    }

    .slick-prev {
      left: -45px;
    }

    .slick-next {
      right: -40px;
    }
  }
}

.content {
  padding-bottom: $spacing-small;
}

.date {
  color: $color-gray-300;
}

.bannerDots {
  margin-top: $spacing-small;
  text-align: center;
  width: 100%;

  li {
    display: inline-block;
  }

  :global {
    .slick-active {
      display: inline-block;

      div {
        background-color: $maincolor;
        border: 2px solid $maincolor;
      }
    }
  }
}

.sliderDot {
  background-color: $color-gray-200;
  border: 2px solid $color-gray-200;
  border-radius: $border-radius-button;
  color: transparent;
  font-size: 0;
  height: 15px;
  margin: $spacing-small $spacing-tiny 0;
  width: 15px;

  @include desktopHover {
    border: 2px solid $maincolor;
    cursor: pointer;
  }
}

.push {
  position: relative;

  &Bell {
    margin-right: $spacing-tiny;
  }

  &Label {
    align-items: center;
    background: $color-gray-200;
    color: $color-white;
    display: flex;
    font-size: $font-size-base;
    height: 24px;
    justify-content: center;
    line-height: $line-height-default;
    padding: 0 $spacing-small;
    position: relative;
    @include zIndex(35);

    &Highlight {
      background: $color-green;

      @include desktopHover {
        color: $color-green;
      }
    }

    &Loyalty {
      border-radius: 11px;
    }

    &Text {
      display: inline-block;
      margin-left: $spacing-small;
    }

    @include desktopHover {
      color: $color-white;
      cursor: pointer;
    }
  }

  &Panel {
    background-color: $color-white;
    border: 1px solid $color-gray-200;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
    color: $color-black;
    display: none;
    font-weight: $font-normal;
    position: absolute;
    right: (-$spacing-default);
    text-align: left;
    top: $spacing-large;
    width: 358px;
    @include zIndex(37);

    &Visible {
      display: block;
    }

    &::before {
      content: '';
      height: 30px;
      left: 0;
      position: absolute;
      right: 0;
      top: (-$spacing-large);
    }
  }

  &Notifications {
    width: 100%;
  }

  &Menu {
    position: relative;
  }

  &Messages {
    padding-top: $spacing-tiny;
  }

  &Message {
    border-bottom: 1px solid $color-gray-100;

    @include desktopHover {
      background-color: $color-gray-100;
    }

    &Link {
      display: flex;
    }

    &Image {
      height: auto;
      max-width: 100%;
      width: auto;
    }

    &Bell {
      color: $color-black;
      filter: $filter-image-white;
    }

    &Icon {
      align-items: flex-start;
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      padding: $spacing-default;
      width: 53px;
    }

    &Content {
      font-size: $font-size-base;
      padding-bottom: $spacing-small;
      padding-right: $spacing-large;
      padding-top: $spacing-small;
    }

    &Title {
      color: $color-black;

      a {
        color: $maincolor;

        @include desktopHover {
          color: $link-color-hover;
        }
      }
    }

    &Date {
      color: $color-gray-300;
    }
  }

  &ShowMore {
    background-color: $color-gray-100;
    font-size: $font-size-base;

    padding: $spacing-tiny $spacing-default;
    text-align: center;
    width: auto;

    @include desktopHover {
      cursor: pointer;
    }
  }

  &Footer {
    align-items: center;
    border-top: 1px solid $color-gray-100;
    justify-content: space-between;
    padding: $spacing-default;

    &Link {
      align-items: center;
      color: $color-blue;
      display: flex;
      font-size: $font-size-base;
      transition: color 0.3s ease-out;

      @include desktopHover {
        color: $color-blue;
      }
    }

    &Bell {
      filter: $filter-image-blue;
      margin-right: $spacing-default;
    }

    &Settings {
      height: 15px;
      width: 15px;
    }
  }

  &Empty {
    align-items: center;
    display: flex;
    height: 127px;
    justify-content: center;
    position: relative;

    &Icon {
      color: $color-gray-100;
      display: block;
      height: 70px;
      opacity: 0.1;
      width: 65px;
    }

    &Text {
      color: $color-black;
      left: 0;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      @include typo-base();
    }
  }
}
