@import '../../theme/vars.scss'; 

.menuCatalog {
  display: flex;
  width: 100%;

  &__first {
    display: flex;

    @include mq(lg, md) {
      width: 288px;
    }

    @include mq(md) {
      width: 100%;
    }
  }

  &__second {
    display: flex;
    flex-direction: column;
    font-size: $font-size-base;
    width: 100%;

    @include mq(lg, md) {
      background-color: $color-gray-100;
      margin-left: $spacing-default;
    }

    @include mq(md) {
      background-color: $color-white;
    }

    &_header {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      margin-bottom: $spacing-big;
      width: 100%;
    }

    &_title {
      align-items: center;
      display: flex;
      margin-bottom: $spacing-small;
      width: 100%;
    }

    &_scroll {
      width: 100%;
    }

    &_content {
      @include mq(lg, md) {
        padding: $spacing-big;
      }

      @include mq(lg, xmd) {
        column-count: 2;
      }
    }

    &_block {
      margin-bottom: $spacing-big;

      @include mq(lg, xmd) {
        page-break-inside: avoid;
        width: 303px;
      }
    }

    &_subitems {
      color: $color-black;
      display: block;
      line-height: $line-height-default;
      padding: $spacing-tiny 0;
    }

    &_count {
      color: $color-gray-300;
      padding-left: $spacing-tiny;
    }
  }
}

.count {
  color: $color-gray-300;
  padding-left: $spacing-tiny;
}