@import '../../theme/vars.scss'; 

.relative {
  position: relative;
}

.productCard {
  background: $color-white;
  border: 1px solid $product-border;
  border-bottom-left-radius: $border-radius-default;
  border-bottom-right-radius: $border-radius-default;
  border-top-left-radius: $border-radius-default;
  border-top-right-radius: $border-radius-default;
  display: flex;
  flex-direction: column;
  height: calc(100% - 5px);
  justify-content: space-between;
  margin: $spacing-tiny;
  position: relative;

  @include desktopHover {
    border-color: $product-border-hover;
  }

  &_inline {
    display: inline-flex;
  }

  &_autoheight {
    height: auto;
  }

  .padding {
    top: -5px;
    @include zIndex(44);
  }

  &_loyalty {
    border-radius: $border-radius-block;
    margin: $spacing-tiny auto;
    width: 100%;

    @include desktopHover {
      border-color: $color-purple;
    }

    .highlighted {
      border-radius: 100px;
    }

    .padding {
      padding: 0 $spacing-small;
    }

    .code {
      margin-bottom: $spacing-small;
    }

    .productInfoTop {
      margin-bottom: $spacing-default;
    }
  }

  &_loyaltySlider {
    margin: 0 auto;

    &:first-child,
    &:nth-child(4n + 1) {
      margin-left: auto;
    }

    &:nth-child(4n) {
      margin-right: auto;
    }
  }

  &Compare {
    margin: 0 auto $spacing-big;

    @include mq(lg) {
      margin: 0 $spacing-tiny $spacing-default;
    }
  }

}

.productCard_loyalty {
  .productInfoBottom {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-bottom: $spacing-default;
  }
}

.imageHover {
  align-items: center;
  background: $color-white;
  border-radius: 34px;
  bottom: $spacing-default;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  color: $color-blue;
  cursor: pointer;
  display: none;
  font-size: $font-size-base;
  height: 30px;
  justify-content: center;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 170px;
  @include zIndex(44);

  &Icon {
    filter: $filter-image-blue;
    margin-right: $spacing-tiny;
    width: 19px;
  }

  @include desktopHover {
    color: $color-orange;

    .imageHoverIcon {
      filter: $filter-image-orange;
    }
  }
}

.currencyValue {
  vertical-align: middle;
}

.code {
  margin-bottom: $spacing-tiny;

  &Loyalty {
    border-radius: 8px;
  }
}

.brand {
  width: 100%;

  &Text {
    color: $color-gray-300;
    display: inline-flex;
    font-size: $font-size-base;
    height: 15px;
    line-height: $line-height-small;
    margin-right: $spacing-tiny;
  }

  &Label {
    color: $color-gray-300;
    display: inline-flex;
    font-size: $font-size-base;
    height: 15px;
    line-height: $line-height-small;
    margin-right: $spacing-tiny;
  }

  &Title {
    color: $color-blue;
    display: inline-flex;
    font-size: $font-size-base;
    height: 15px;
    line-height: $line-height-small;
    margin-right: $spacing-tiny;
    margin-right: 0;
    overflow: hidden;
  }
}

.padding {
  padding: $spacing-small;
}

.paddingLight {
  padding: $spacing-small $spacing-default;
}

.productInfoTop {
  margin-bottom: 0;
}

.productInfoBottom {
  background-color: $color-gray-100;
  border-bottom-left-radius: $border-radius-default;
  border-bottom-right-radius: $border-radius-default;
  padding-bottom: $spacing-large;
  padding-top: $spacing-tiny;

  @include mq(lg) {
    padding: $spacing-small;
  }

  &_withOutPrice {
    margin-bottom: 0;
  }
}

.productName {
  color: $color-black;
  cursor: pointer;
  display: block;
  font-size: $font-size-base;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  word-wrap: break-word;

  @include mq(_,lg) {
    line-height: $line-height-default;
    margin-top: $spacing-tiny;
    max-height: 108px;
  }

  @include desktopHoverFocusActive {
    color: $link-color-hover;
  }

  &Loyalty {
    height: 36px;

    @include desktopHoverFocusActive {
      color: $color-purple;
    }
  }
}

.rest {
  display: flex;
  flex-direction: column;
  height: 93px;
  justify-content: center;
  margin: 0 $spacing-tiny;
  position: relative;

  &::after {
    background-color: $color-gray-200;
    bottom: (-$spacing-tiny);
    content: '';
    height: 1px;
    left: (-$spacing-tiny);
    position: absolute;
    right: (-$spacing-tiny);
  }
}

.orderButtonWrapper {
  margin-right: $spacing-tiny;
}

.cardButton {
  width: 103px !important;
}

.cap {
  background-color: $color-gray-100;
  border-radius: 3px;
  box-shadow: 0 1px 2px $color-gray-300;
  padding: $spacing-small 0;
}

.capText {
  color: $color-black;
  font-size: $font-size-base;

  line-height: $line-height-default;
  text-align: center;

  a {
    text-decoration: underline;

    &hover {
      text-decoration: none;
    }
  }
}

.productCardRelative {
  position: relative;
}

.noCountRest {
  margin-top: $spacing-large;
}

.buttonsContainer {
  margin-top: $spacing-default;
}

.basketButtons {
  display: flex;
  justify-content: space-between;
}

.highlighted {
  display: inline-block;
  line-height: $line-height-small;
  padding: 0 $spacing-tiny;
}

.highlighted_long {
  max-width: 89px;
  position: relative;
  white-space: nowrap;

  &::after {
    border-radius: 2px;
    bottom: 0;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 15px;
  }
}

.barcodeValue {
  background: rgb(52, 175, 49);
  border-radius: 2px;
  color: $color-white;
  padding: 1px 3px;
}

.partGroup {
  color: $color-black;
  font-size: $font-size-base;
  height: 18px;
  margin-bottom: $spacing-tiny;
  margin-left: $spacing-tiny;

  &_hasStockNotice {
    margin-bottom: 0;
  }
}

.partIcon {
  align-items: flex-end;
  display: inline-flex;
  flex-direction: row;
  height: 10px;
  margin-right: $spacing-tiny;
  width: 8px;

  span {
    background: $color-black;
    display: block;
    margin-right: 1px;
    width: 2px;
  }

  span:nth-child(1) {
    height: 9px;
  }

  span:nth-child(2) {
    height: 10px;
  }

  span:nth-child(3) {
    height: 8px;
    margin-right: 0;
  }
}

.counts {
  margin-left: $spacing-tiny;
}

.batch {
  align-items: flex-end;
  display: inline-flex;
}

.productCardInSearchRequirements {
  margin: 0;

  .productName {
    text-decoration: none;
  }
}

.imageWrap {
  align-items: center;
  display: flex;
  height: 228px;
  justify-content: center;
  position: relative;
  width: 100%;

  @include mq(lg) {
    height: auto;
  }

  &_clickable {
    cursor: pointer;
  }

  .previewPicture {
    align-items: center;
    justify-content: center;

    &_loyalty {
      margin: $spacing-small;
    }

    &_grayscale {
      filter: grayscale(100%);
    }
  }

  .productCardImg {
    height: auto;
    max-height: 100%;
    max-width: 100%;
    width: auto;
  }

  @include desktopHover {
    .imageHover {
      display: flex;
    }
  }
}

.stockDelivery {
  align-items: center;
  color: $color-green;
  display: flex;
  font-size: $font-size-base;
  margin-left: $spacing-tiny;
}

.stockDeliveryIcon {
  border: 1px solid;
  color: $color-green;
  height: 10px;
  margin-right: $spacing-small;
  position: relative;
  width: 10px;

  &::after {
    background: currentColor;
    content: '';
    height: 1px;
    left: 2px;
    position: absolute;
    top: 1px;
    width: 4px;
  }
}

.isLoyaltyBottom {
  margin-bottom: 0;
  padding-top: $spacing-small;
}

.prices {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 52px;
}

.labels {
  padding: $spacing-small $spacing-small 0;

  @include mq(md) {
    position: absolute;
    @include zIndex(44)
  }

  &_auth {
    padding: $spacing-small $spacing-small 0 $spacing-large;
  }
}

.honestSign {
  bottom: 0;
  left: 0;
  position: absolute;
}

.actions {
  width: 100%;
}
