@import '../../theme/vars.scss'; 

.header {
  &Top {
    display: flex;
    height: 33px;
    justify-content: space-between;
    padding: $spacing-tiny 0;
  }

  &Info {
    display: flex;
  }
}

.linkToMain {
  align-items: center;
  color: $color-black;
  display: flex;
  font-size: $font-size-base;
  line-height: $line-height-big;

  @include desktopHover {
    color: $color-purple;
  }
}

.linkToMainIcon {
  margin-right: $spacing-tiny;
  transform: rotate(-90deg);

  @include desktopHover {
    color: $color-pink;
    text-decoration: underline;
  }
}

.name,
.contractor {
  position: relative;
}

.contractor:not(:last-child) {
  margin-right: $spacing-tiny;
}

.contractor {
  min-width: 235px;

  &Button {
    align-items: center;
    background-color: $maincolor;
    border: unset;
    border-radius: $border-radius-default;
    color: $header-top-contractor-color;
    display: flex;
    font-size: $font-size-base;
    height: 24px;
    padding: 0 $spacing-small;
    position: relative;
    width: 100%;
    @include zIndex(36);

    @include desktopHover {
      background-color: $maincolor;
    }

    &Bookmark {
      background-color: $bookmarkmaincolor;

      @include desktopHover {
        background-color: $bookmarkmaincolor;
      }
    }

    &Loyalty {
      background-color: $color-purple;
      border-radius: 11px;

      @include desktopHover {
        background-color: $color-purple;
      }
    }

    &Superuser {
      background-color: $color-red;

      @include desktopHover {
        background-color: $color-red;
      }
    }
  }

  .dropdownWrapper {
    display: block;
  }
}

.personalIcon {
  color: $header-top-contractor-color;
  filter: invert(100);
  margin-right: $spacing-small;
}

.dropdown {
  background-color: $color-white;
  border: 1px solid $color-gray-200;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
  color: $color-black;
  font-weight: normal;
  position: absolute;
  right: -1px;
  text-align: left;
  top: 30px;
  width: 100%;
  @include zIndex(11);

  &::after {
    content: '';
    height: 7px;
    left: 0;
    position: absolute;
    right: 0;
    top: -7px;
  }
}
