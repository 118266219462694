@import '../../theme/vars.scss'; 

.productOneActions {
  display: flex;

  .rightBlockWrapper {
    display: flex;
    flex-direction: column;
  }

  .wrapLeftBlock {
    background-color: $color-gray-100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 112px;
    padding-bottom: $spacing-small;
    padding-left: $spacing-default;
    padding-top: $spacing-small;
  }

  .wrapRightBlock {
    background-color: $color-gray-100;
  }

  .wrapButtons {
    display: flex;
    margin-bottom: $spacing-tiny;
  }

  .price {
    @include typo-h1-lg();
    color: $color-black;
    display: inline-block;
    line-height: $line-height-small;
    margin-bottom: $spacing-tiny;
    padding-top: $spacing-tiny;
    vertical-align: middle;

    &_block {
      display: block;
      margin-bottom: $spacing-default;
    }
  }

  .partGroup {
    align-items: center;
    color: $color-black;
    display: flex;
    font-size: $font-size-base;
  }

  .partIcon {
    background: $color-black;
    display: inline-block;
    height: 10px;
    margin-left: $spacing-tiny;
    margin-right: $spacing-small;
    position: relative;
    width: 2px;

    &::before,
    &::after {
      background: $color-black;
      bottom: 0;
      content: '';
      display: block;
      position: absolute;
      width: 2px;
    }

    &::before {
      left: 3px;
      top: 2px;
    }

    &::after {
      right: 3px;
      top: 1px;
    }
  }

  .bath {
    color: $color-black;

    white-space: nowrap;
  }

  .restMini {
    display: inline-block;
    font-size: $font-size-base;

    margin-right: $spacing-default;
    min-width: 70px;
  }

  .orderButtonWrapper {
    margin-bottom: $spacing-small;
    margin-right: 0;
    width: 100%;
  }
}

.productOneActions_table {
  .wrapLeftBlock {
    border-radius: $border-radius-block;
    height: 120px;
    margin-right: $spacing-tiny;
    padding-left: $spacing-large;
    width: 235px;
  }

  .wrapRightBlock {
    border-radius: $border-radius-block;
    display: flex;
    flex-direction: column;
    height: 120px;
    justify-content: center;
    padding: 0 $spacing-default;
    width: 255px;
  }

  .wrapButtons {
    flex-direction: column;
  }

  .restMini {
    margin-left: $spacing-tiny;
  }

  .wrapButtons {
    margin-bottom: 0;
  }

  .orderButtonWrapper {
    margin-bottom: $spacing-small;
    margin-right: 0;
  }
}

.productOneActions_one {
  background-color: $color-gray-100;
  border-radius: $border-radius-block;
  flex-direction: column;
  padding: $spacing-tiny $spacing-default;

  .rightBlockWrapper {
    align-items: center;
  }

  .wrapLeftBlock {
    background-color: transparent;
    display: flex;
    padding: 0;
    width: fit-content;

    @include mq(lg) {
      margin-top: $spacing-default;
      min-height: auto;
    }
  }

  .wrapRightBlock {
    border-radius: $border-radius-block;
    height: 120px;
    max-width: 256px;
    padding:
      $spacing-big
      $spacing-big
      $spacing-large
      $spacing-default;
    width: 100%;

    @include mq(lg) {
      max-width: 100%;
    }
  }

  .wrapButtons {
    flex-direction: column;
  }

  .textDescription {
    margin-bottom: $spacing-tiny;
  }

  .productsBalance {
    margin-left: 0;
  }

  .productsBalancePlus {
    margin-top: -2px;
  }

  .restMini {
    margin-left: $spacing-tiny;
  }
}

.loyaltyButtons {
  margin-top: 2px;
}

.loyaltyButtonsTop {
  margin-bottom: $spacing-default;
  padding: 0 $spacing-tiny $spacing-tiny;
  position: relative;

  &::after {
    background-color: $color-gray-200;
    bottom: -6px;
    content: '';
    height: 1px;
    left: -3px;
    position: absolute;
    right: -3px;
  }
}

.loyaltyLine {
  margin-top: $spacing-small;
}

.loyaltyProductButtons {
  margin-top: $spacing-small;
}
