@import '../../theme/vars.scss'; 

.PropertyTable {
  background-color: transparent;
  table-layout: fixed;

  &_compare {
    .trBase,
    .tdLeftBase {
      height: 46px;
      line-height: $line-height-default;
      overflow: inherit;
    }

    .trBase > .propertyItem {
      border-bottom: unset;
    }

    .trBase:nth-child(even) {
      background-color: $color-white;
    }

    .trBase:nth-child(odd) {
      background-color: $color-gray-100;
    }
  }

  &_quickView {
    padding: 0;
  }

  &_productList {
    border-bottom: 1px solid $color-gray-200;
    border-top: 1px solid $color-gray-200;
    padding: $spacing-small $spacing-tiny;
  }
}

.table {
  display: flex;
  flex-direction: column;
}

.tr {
  &Property {
    align-items: center;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    width: 100%;

    &_align_left {
      justify-content: flex-start;
    }

    &_align_center {
      justify-content: center;
    }

    &_align_right {
      justify-content: flex-end;
    }

    &[href] {
      @include desktopHover {
        color: $link-color-hover;
      }
    }
  }

  &Base {
    color: $color-black;
    font-size: $font-size-base;
    height: 28px;
    line-height: $line-height-big;

    .propertyItem {
      border-bottom: 1px solid $color-gray-100;
    }

    &:last-child > .propertyItem {
      border-bottom-color: transparent;
    }
  }

  &ProductOne,
  &ProductOneQuick {
    color: $color-black;
    font-size: $font-size-base;
    line-height: $line-height-default;
    padding: $spacing-tiny 0;

    .propertyItem {
      max-width: 50%;
    }
  }

  &ProductTabs {
    border-bottom: 1px solid $color-gray-100;
    font-size: $font-size-base;
    padding: $spacing-tiny 0;
  }

  &ProductList {
    color: $color-black;
    font-size: $font-size-base;
    height: auto;
    line-height: $line-height-default;

    .propertyItem {
      max-width: 150px;
    }
  }
}

.tdLeftBase {
  padding-right: $spacing-default;
}

.tdRight {
  text-align: right;
}

.tdLeftProductOne {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tdLeftProductOneQuick {
  overflow: hidden;
  padding-left: $spacing-tiny;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tdRightProductOne {
  padding-right: 0;
}

.tdRightProductOneQuick {
  padding-right: 0;
}

.align_left {
  padding-left: $spacing-tiny;
  text-align: left;
}

.align_center {
  text-align: center;
}

.align_right {
  text-align: right;
}

.overflow {
  text-overflow: ellipsis;

  &.compare {
    align-items: center;
    display: flex;
    height: 32px;
  }
}

.filterLink {
  padding-right: $spacing-tiny;

  &Title {
    color: $color-blue;
  }
}

.copyButton {
  background-color: transparent;
  border: unset;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include desktopHover {
    color: $link-color-hover;
  }

  &_value {
    padding-right: $spacing-tiny;
  }
}

.description {
  align-items: center;
  background-color: $color-gray-200;
  border-radius: $border-radius-circle;
  color: $color-gray-300;
  display: flex;
  height: 18px;
  justify-content: center;
  margin-left: $spacing-small;
  margin-right: auto;
  position: relative;
  top: 0;
  width: 18px;

  &Tooltip {
    color: $color-black;
    cursor: auto;
    padding: $spacing-small;
    width: 328px;
  }

  a {
    text-decoration: underline;
  }
}
