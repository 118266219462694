@import '../../theme/vars.scss'; 

.galleryWrapper {
  display: flex;
  flex-direction: column;
  margin-right: $spacing-small;
  max-height: 496px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 47px;

  &_quickView {
    left: -73px;
    margin: 0;
    min-height: 693px;
    overflow-y: hidden;
    position: absolute;
    top: 0;
    width: 67px;
  }

  &_inline {
    flex-direction: row;
    height: 80px;
    justify-content: center;
    margin-right: 0;
    min-height: 50px;
    padding-top: $spacing-small;
    width: 100%;
  }
}

.photoSlider {
  display: inline-block;
  margin: 47px 0;
  width: 47px;

  :global {
    .slick-track {
      display: flex;
      flex-direction: column;
    }

    .slick-slide {
      border: unset;
    }

    .slick-prev {
      height: 35px;
      left: $spacing-tiny;
      top: (-$spacing-big);
      width: 45px;
    }

    .slick-next {
      height: 34px;
      left: $spacing-tiny;
      top: calc(100% + 28px);
      width: 45px;
    }
  }

  &_quickView {
    margin: 67px 0;
    width: 67px;

    :global {
      .slick-prev {
        height: 50px;
        left: 0;
        top: (-$spacing-large);
        width: 67px;
      }

      .slick-next {
        height: 50px;
        left: 0;
        top: calc(100% + 42px);
        width: 67px;
      }
    }
  }

  /* stylelint-disable */
  &_center {
    margin: (-$spacing-tiny) 0;
    width: stretch;

    :global {
      .slick-track {
        display: flex;
        justify-content: flex-start;
      }
    }

    &_quickView {
      margin: (-$spacing-tiny) 0;
      width: stretch;

      :global {
        .slick-track {
          justify-content: flex-start;
        }
      }
    }
  }

  &_inline {
    margin: 0;
    width: inherit;

    :global {
      .slick-track {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }
  }
}

.sliderArrow {
  background-color: transparent;
  border-radius: $border-radius-input;
  height: 43px;
  left: 2px;
  padding: $spacing-default 0;
  position: absolute;
  top: -47px;
  width: 43px;
  height: 43px;

  @include desktopHover {
    background-color: $color-gray-100;
    color: $color-orange;
  }

  &_next {
    bottom: -47px;
    top: auto;
  }

  &_quickView {
    background-color: $color-white;
    border-radius: 8px;
    height: 67px;
    left: 0;
    padding: $spacing-big;
    top: -67px;
    transform: rotate(90deg);
    width: 67px;

    &_next {
      bottom: -67px;
      top: auto;
    }
  }
}

.productPhotos {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: $spacing-default auto;

  &_quickView {
    margin-top: $spacing-default;

    .productPhotosControl {
      margin-bottom: $spacing-default;
    }
  }
}

.product {
  &Photo {
    border: 2px solid transparent;
    border-radius: 8px;
    cursor: pointer;
    margin: $spacing-tiny 0;
    padding: $spacing-tiny;
    position: relative;
    width: 43px;

    @include desktopHover {
      border-color: $product-preview-picture-hover;
    }

    &_selected {
      border-color: $product-preview-picture-active;

      @include desktopHover {
        border-color: $product-preview-picture-active;
      }
    }

    &_quickView {
      background-color: $color-white;
      border: 2px solid $color-white;
      border-radius: 8px;
      height: 67px;
      padding: $spacing-small;

      @include desktopHover {
        border-color: $product-preview-picture-hover;
      }

      &_selected {
        border-color: $product-preview-picture-active;

        @include desktopHover {
          border-color: $product-preview-picture-active;
        }
      }
    }

    &_inline {
      margin: $spacing-small $spacing-tiny 0;
    }
  }

  &Video {
    border-bottom: 3px solid transparent;
    margin: $spacing-small 0 0 $spacing-tiny;
    padding-bottom: $spacing-tiny;
    position: relative;
    @include zIndex(22);

    @include desktopHover {
      border-bottom-color: $product-preview-picture-hover;
      cursor: pointer;
    }

    &_active {
      border-bottom-color: $product-preview-picture-active;
      cursor: default;
    }

    &_item {
      background-color: $color-blue-100;
      border-radius: 8px;
      height: 43px;
      width: 43px;
    }

    &_quickView {
      background-color: $color-blue-100;
      border: 2px solid $color-blue-100;
      border-bottom: unset;
      border-radius: 8px;
      margin: $spacing-small 0 0;
      padding: $spacing-small;
      @include zIndex(3);

      @include desktopHover {
        border-color: $product-preview-picture-hover;
      }

      &_selected {
        border-color: $product-preview-picture-active;
      }
    }
  }
}

.productPhotosControl {
  background: none;
  border: unset;
  color: $color-gray-200;
  cursor: pointer;
  height: 17px;
  width: 10px;

  @include desktopHover {
    color: $color-black;
  }

  &[disabled] {
    cursor: default;
    opacity: 0.3;

    @include desktopHover {
      color: $color-gray-200;
    }
  }
}

.icon {
  &Info {
    cursor: pointer;
    height: 20px;
    left: $spacing-small;
    position: absolute;
    top: $spacing-small;
    width: 19px;

    &_quickView {
      left: $spacing-big;
      top: $spacing-big;
    }
  }

  &Rotate {
    cursor: pointer;
    height: 30px;
    left: $spacing-small;
    position: absolute;
    top: $spacing-small;
    width: 25px;

    &_quickView {
      left: $spacing-default;
      top: $spacing-default;
    }
  }
}
