@import '../../theme/vars.scss'; 

.button {
  align-items: center;
  border: unset;
  border-radius: $border-radius-button;
  cursor: pointer;
  display: flex;
  font-size: $font-size-base;
  justify-content: center;
  outline: none;
  padding: 12px;
  transition-duration: 0.05s;
  transition-property: color, background-color, border-color, filter, opacity;
  transition-timing-function: ease-in;

  &__color {
    &_main {
      background-color: $maincolor;
      border: 1px solid transparent;
      color: $color-white;

      @include desktopHoverFocusActive {
        background-color: $maincolorhover;
      }
    }

    &_yellow {
      background-color: $color-yellow;
      border: 1px solid transparent;
      color: $color-black;

      @include desktopHoverFocusActive {
        background-color: $color-yellow-hover;
      }
    }

    &_orange {
      background-color: $color-orange;
      border: 1px solid transparent;
      color: $color-white;

      @include desktopHoverFocusActive {
        background-color: $color-orange-hover;
      }
    }

    &_green {
      background-color: $color-green;
      border: 1px solid transparent;
      color: $color-white;

      @include desktopHoverFocusActive {
        background-color: $color-green-hover;
      }
    }

    &_gray {
      background-color: $color-gray-300;
      color: $color-white;

      @include desktopHoverFocusActive {
        opacity: 0.7;
      }
    }

    &_black {
      background-color: $color-black;
      color: $color-white;

      @include desktopHoverFocusActive {
        opacity: 0.7;
      }
    }

    &_transparent {
      background-color: transparent;
    }

    &_transparentOrange {
      background-color: transparent;
      color: $color-orange;

      @include desktopHoverFocusActive {
        color: $color-blue;

        .icon__color_transparentOrange {
          filter: $filter-image-blue;
        }
      }
    }

    &_transparentBlue {
      background-color: transparent;
      color: $color-blue;

      @include desktopHoverFocusActive {
        color: $color-blue;
        opacity: 0.7;

        .icon__color_transparentBlue {
          filter: $filter-image-blue;
        }
      }
    }

    &_transparentWhite {
      background-color: transparent;
      color: $color-white;

      @include desktopHoverFocusActive {
        color: $color-white;
        opacity: 0.7;

        .icon__color_transparentWhite {
          filter: $filter-image-white;
        }
      }
    }

    &_white {
      background-color: $color-white;
      border: 1px solid transparent;
      color: $color-blue;

      @include desktopHoverFocusActive {
        background-color: $color-gray-100;
        color: $color-orange;

        .icon__color_white {
          filter: $filter-image-orange;
        }
      }
    }

    &_whiteBlue {
      background-color: $color-white;
      border: 1px solid $color-blue;
      color: $color-blue;

      @include desktopHoverFocusActive {
        background-color: $maincolor;
        color: $color-white;

        .icon__color_whiteBlue {
          filter: $filter-image-white;
        }
      }
    }

    &_whiteOrange {
      background-color: $color-white;
      border: 1px solid $color-orange;
      color: $color-orange;

      @include desktopHoverFocusActive {
        background-color: $color-orange;
        color: $color-white;
        opacity: 0.7;

        .icon__color_whiteOrange {
          filter: invert(1);
        }
      }
    }

    &_whiteGray {
      background-color: $color-white;
      border: 1px solid $color-gray-200;
      color: $color-gray-400;

      @include desktopHoverFocusActive {
        opacity: 0.7;
      }
    }

    &_blue {
      background-color: $color-blue;
      color: $color-white;

      @include desktopHoverFocusActive {
        background-color: $color-blue;
        opacity: 0.7;
      }
    }

    &_lightBlue {
      background-color: $color-blue-100;
      color: $color-blue;

      @include desktopHoverFocusActive {
        color: $color-orange;

        .icon__color_lightBlue {
          filter: $filter-image-orange;
        }
      }
    }

    &_red {
      background-color: $color-red;
      color: $color-white;

      @include desktopHoverFocusActive {
        background-color: $color-red-200;
      }
    }

    &_purple {
      background-color: $color-purple;
      color: $color-white;

      @include desktopHoverFocusActive {
        background-color: $color-purple-200;
      }
    }
  }

  &__shape {
    &_default {
      border-radius: $border-radius-button;
    }

    &_circle {
      align-items: center;
      border-radius: $border-radius-circle;
      display: flex;
      height: 28px;
      justify-content: center;
      width: 28px;
    }

    &_flat {
      border-radius: $border-radius-default;
    }
  }

  &__size {
    &_micro {
      padding: 0;
    }

    &_tiny {
      padding: 2px 6px;
    }

    &_small {
      padding: 6px;
    }

    &_default {
      padding: 12px;
    }

    &_big {
      padding: 18px;
    }
  }

  &__autowidth {
    width: 100%;
  }

  &:disabled {
    background-color: $color-gray-200;
    border: unset;
    color: $color-white;
    cursor: default;
    pointer-events: none;

    .icon {
      filter: invert(1);
    }
  }

  &__color {
    &_transparent, &_transparentOrange, &_transparentBlue {
      &:disabled {
        background-color: transparent;
        color: $color-gray-200;

        .icon {
          filter: $filter-image-gray-200;
        }
      }
    }
  }
}

.icon {
  margin-right: $spacing-tiny;

  &__shape {
    &_circle {
      margin: 0;
    }

    &_default {
      font: inherit;
    }
  }

  &__color {
    &_main,
    &_gray,
    &_black,
    &_green,
    &_orange,
    &_blue,
    &_red,
    &_purple {
      filter: invert(1);
    }

    &_white,
    &_lightBlue,
    &_whiteBlue,
    &_transparentBlue {
      filter: $filter-image-blue;
    }

    &_whiteOrange,
    &_transparentOrange {
      filter: $filter-image-orange;
    }

    &_whiteGray {
      filter: $filter-image-gray;
    }
  }
}
