@import '../../theme/vars.scss'; 

.dropdownOffers {
  min-height: 60px;
  min-width: 346px;
  padding: 0 0 $spacing-small;
  right: (-$spacing-big);
}

.offers {
  &Empty {
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: center;
  }

  &ShortBlock {
    display: flex;
    flex-direction: column;
    margin: 0 $spacing-small;
  }
}

.offer {
  &Info {
    align-items: center;
    border-top: 1px solid $color-gray-200;
    display: flex;
    justify-content: space-between;
    padding: $spacing-small 0;
  }

  &Left {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    width: 182px;

    &_dates {
      color: $color-gray-300;
    }
  }

  &Title {
    font-weight: $font-semibold;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  &Label {
    align-items: center;
    border-radius: $border-radius-block;;
    display: flex;
    font-weight: $font-semibold;
    height: 24px;
    padding: 0 $spacing-small;
    white-space: nowrap;

    &Active {
      background-color: $color-yellow;
    }

    &End {
      background-color: $color-blue;
      color: $color-white;

      &_disabled {
        background-color: $color-gray-200;
        color: $color-white;
      }
    }

    &New {
      background-color: $color-blue-200;
      color: $color-white;
    }
  }
}

.moreInfoLink {
  color: $color-blue-200;
  display: block;
  margin-top: $spacing-small;

  @include desktopHover {
    color: $link-color-hover;
  }
}

.blockTitle {
  align-items: center;
  display: flex;
  height: 42px;
  justify-content: space-between;

  &Column {
    white-space: nowrap;
    width: 214px;
  }

  &Text {
    color: $color-gray-300;
    font-size: $font-size-h2;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  &Bordered {
    border-top: 1px solid $color-gray-200;
  }
}
