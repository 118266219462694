@import '../../theme/vars.scss'; 

.notMultiple {
  align-items: center;
  background-color: $color-red;
  border: 2px solid $color-red;
  border-radius: 30px;
  color: $color-white;
  display: flex;
  height: 24px;
  margin-left: $spacing-tiny;
  padding: 0 $spacing-tiny;
  @include typo-base();

  @include desktopHover {
    background-color: $color-white;
    color: $color-red;
    cursor: pointer;
  }
}

.hint {
  background-color: $color-white;
  border: 1px solid $color-black;
  border-radius: $border-radius-button;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  color: $color-red;
  min-width: 200px;
  padding: $spacing-tiny;
  position: fixed;
  width: auto;
  @include typo-base($font-normal);
  @include zIndex(14);
}
