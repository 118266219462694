@import '../../theme/vars.scss'; 

.preloader {
  height: 200px;
}

.hint {
  font-size: $font-size-base;
}

.contractor,
.address {
  align-items: center;
  display: flex;
  font-size: $font-size-base;
  justify-content: flex-start;
  margin-right: $spacing-default;
}

.select {
  margin-left: $spacing-tiny;
}

.filter {
  display: flex;
  margin: $spacing-small 0;
}

.invoice {
  background-color: $color-blue-100;
  border-radius: $border-radius-block;
  font-size: $font-size-base;
  margin-top: $spacing-small;
  padding: $spacing-default;

  &Head {
    align-items: center;
    border-bottom: 1px solid $color-gray-200;
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacing-small;
    padding-bottom: $spacing-small;

    &Title {
      @include typo-h2();
    }

    &Status {
      align-items: center;
      background-color: $color-white;
      border-radius: 30px;
      display: flex;
      font-size: $font-size-base;
      height: 28px;
      justify-content: center;
      padding: $spacing-small;
      width: auto;
    }

    &WaitText {
      font-weight: $font-semibold;
      margin-left: $spacing-tiny;
      margin-right: $spacing-tiny;
    }
  }

  &Progress {
    align-items: center;
    border-bottom: 1px solid $color-gray-200;
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacing-small;
    padding-bottom: $spacing-small;

    &Line {
      width: auto;
    }

    &Option {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      min-width: 240px;
      width: auto;
    }
  }

  &Table {
    font-size: $font-size-base;
    width: 100%;

    &Head {
      color: $color-gray-300;
    }

    &Row {
      align-content: start;
      display: grid;
      grid-template-columns: repeat(2, 3fr) repeat(2, 2fr);
    }
  }
}
