@import '../../theme/vars.scss'; 

.layout {
  align-self: center;
  background-size: contain;
  display: flex;
  justify-content: center;
  margin: auto;
  max-width: 1200px;
  min-height: 60vh;
  padding-bottom: 60px;
  position: relative;
  width: 100%;
}

.layers {
  max-width: 1200px;
  width: 100%;

  &::after {
    clear: both;
    content: '';
    display: block;
  }
}

.layer {
  float: left;
  width: 50%;
}

.beforeLayer {
  width: 200%;
}

.afterLayer {
  margin-left: -100%;
  position: relative;
  top: 0;
  width: 200%;
}

