@import '../../theme/vars.scss'; 

$widthButton: ($spacing-big*2);

.search {
  position: relative;
  width: 100%;
}

.close {
  position: absolute;
  right: 60px;
  top: $spacing-default;

  &Icon {
    filter: $filter-image-gray;
    height: 18px;
    width: 18px;

    @include desktopHover {
      cursor: pointer;
      filter: $filter-image-orange;
    }
  }
}

.title {
  display: flex;
  justify-content: space-between;
  padding: $spacing-small;

  &Name {
    @include typo-base();
  }

  &Action {
    color: $color-gray-300;
    @include typo-base();

    @include desktopHover {
      color: $maincolorhover;
      cursor: pointer;
    }
  }
}

.item {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 $spacing-small;
  padding: $spacing-tiny 0;

  @include mq(lg) {
    font-size: $font-size-base;
  }

  &Desc {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &Price {
    width: 150px;
  }

  &Name {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &Action {
    font-size: $font-size-base;
  }
}

.price {
  width: 100px;
}

.action {
  color: $color-gray-300;
  font-weight: $font-semibold;

  @include desktopHover {
    color: $maincolorhover;
  }
}

.actionNext {
  padding: $spacing-tiny;
}

.image {
  align-items: center;
  display: flex;
  height: 38px;
  justify-content: center;
  margin-right: $spacing-small;
  width: 38px;

  @include mq(lg) {
    height: 18px;
    justify-content: flex-start;
    width: 20px;
  }

  &None {
    height: 18px;
    width: 20px;
  }
}

.code1c {
  padding-right: $spacing-small;
}

.icon {
  padding-right: $spacing-small;

  &Search {
    filter: $filter-image-white;
    height: 22px;
    width: 22px;
  }

  &History,
  &Suggest,
  &Next {
    filter: $filter-image-gray;
  }
}

.container {
  position: relative;
  width: 100%;
}

.containerOpen {
  .inputLoyalty {
    border-radius: $border-radius-block;
  }

  .suggestionsContainer {
    background-color: $color-white;
    border: 6px solid $maincolor;
    border-radius: $border-radius-default;
    display: block;
    position: absolute;
    top: 40px;
    width: 100%;
    @include zIndex(11);

    @include mq(lg) {
      border: unset;
      height: calc(100vh - 100px);
      margin-top: $spacing-small;
      overflow: auto;
    }

    &Loyalty {
      border-color: $color-purple;
      border-radius: $border-radius-block;
      margin-top: $spacing-small;

      @include mq(lg) {
        border-top: unset;
      }
    }

    &Bookmark {
      border-color: $bookmarkmaincolor;
    }
  }
}

.input {
  border: 6px solid $maincolor;
  border-radius: $border-radius-input;
  font-size: $font-size-base;
  height: 46px;
  padding: $spacing-small;
  width: 100%;

  &Loyalty {
    border: 6px solid $color-purple;
    border-radius: $border-radius-block;
  }

  &Bookmark {
    border: 6px solid $bookmarkmaincolor;
  }

  &:focus {
    outline: none;
  }

  @include mq(lg) {
    padding: $spacing-small $widthButton $spacing-small $spacing-small;

    &:placeholder-shown {
      text-overflow: ellipsis;
    }
  }

  &Focused {
    outline: none;
  }

  &Open {
    display: block;
  }
}

.suggestion {
  @include desktopHover {
    cursor: pointer;
  }

  &Container {
    display: none;
  }

  &First {
    width: 100%;
  }

  &Highlighted {
    background-color: $search-suggest-highlight;
    color: $maincolor;

    .iconHistory,
    .iconSuggest,
    .iconNext {
      filter: $filter-image-blue;
    }
  }

  &Open {
    display: block;
  }

  &List {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &Content {
    align-items: center;
    background-repeat: no-repeat;
    display: flex;
  }
}

.name {
  line-height: $line-height-default;
  max-width: 450px;
  overflow: hidden;

  &Text {
    display: flex;
    flex-direction: column;
  }
}

.highlight {
  font-weight: $font-bold;
}

.submit {
  align-items: center;
  background: none;
  border-bottom: unset;
  border-left: 6px solid $maincolor;
  border-radius: $border-radius-default;
  border-right: unset;
  border-top: unset;
  bottom: $spacing-tiny;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  right: $spacing-tiny;
  top: $spacing-tiny;
  width: $widthButton;

  &Loyalty {
    background-color: $color-purple;
    border-left: 6px solid $color-purple;

    @include desktopHover {
      background-color: $color-purple-200;
    }
  }

  &Bookmark {
    background-color: $bookmarkmaincolor;
    border-left: 6px solid $bookmarkmaincolor;

    @include desktopHover {
      background-color: $bookmarkmaincolorhover;
    }
  }

  &Origin {
    background-color: $maincolor;

    @include desktopHover {
      background-color: $catalog-link-hover;
    }
  }

  &:focus {
    outline: none;
  }
}

.hr {
  background-color: $color-gray-200;
  border: unset;
  height: 1px;
  margin: 0 $spacing-small;
}
