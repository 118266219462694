@import '../../theme/vars.scss'; 

.icon {
  &_hover {
    @include mq(_, lg) {
      filter: $filter-image-blue-100;
    }
  }

  &_gray {
    filter: $filter-image-gray;
  }
}
