@import '../../theme/vars.scss'; 

.header {
  width: 100%;

  @include mq(lg) {
    display: none;
  }
}

.headerInfo {
  color: $color-black;
  font-size: $font-size-base;
  line-height: $line-height-default;
  padding-bottom: 0;
}

.wrapper {
  background-color: $color-white;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.authBlock {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
}

.block {
  box-sizing: border-box;
  min-height: 84px;
  padding-top: $spacing-small;
  vertical-align: top;
  width: 230px;

  &Fixed {
    @include zIndex(10);
  }
}

.counters {
  padding-left: $spacing-big;
  width: 161px;
}

.oneWay {
  background-color: $color-yellow;
  color: $color-black;
  cursor: pointer;
  font-size: $font-size-base;
  height: 70px;
  line-height: $line-height-default;
  margin-top: $spacing-small;
  padding-top: $spacing-big;
  text-align: center;
  text-decoration: underline;
  width: 230px;

  @include desktopHover {
    text-decoration: none;
  }
}

.iconStar {
  height: 25px;
  width: 28px;

  &_platinum {
    color: #afafaf;
  }

  &_bronzovaya {
    color: #de3b16;
  }
}

.blockStar {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  min-width: 180px;
  padding-left: $spacing-default;
  padding-right: $spacing-large;
  padding-top: 0;
}

.linkStar {
  line-height: $line-height-default;
  white-space: nowrap;
}

.goldStatus {
  display: inline-block;
  line-height: $line-height-big;
  margin-left: $spacing-default;
  text-decoration: underline;

  @include desktopHover {
    text-decoration: none;
  }
}

.blockAccess {
  overflow: hidden;
  position: relative;
  width: 400px;
}

.largeGift {
  color: $color-red-200;
  height: 190px;
  left: $spacing-big;
  position: absolute;
  top: (-$spacing-tiny);
  width: 180px;
}

.headerAccept {
  margin-top: (-$spacing-default);
  position: absolute;
  right: $spacing-default;
  top: 50%;
}

.headerMenuSearchLine {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 144px;
  padding-left: $spacing-small;
  position: relative;
}

.headerMenu {
  display: flex;
  height: 66px;
  justify-content: space-between;
  margin: $spacing-default 0 $spacing-small;
  width: 100%;

  /* stylelint-disable-next-line */
  &>div {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    position: relative;
    text-align: center;
    @include typo-base();
  }
}

.headerMenuLoginForm {
  min-width: 360px;
}

.headerMenuRegistration {
  min-width: 163px;
}

.headerLink {
  color: $color-black;
  display: block;
  line-height: $line-height-default;
  @include typo-base();
}

.headerIcon {
  height: 40px;
}

.searchLine {
  max-width: 950px;
  width: 100%;

  &Fixed {
    align-items: flex-start;
    display: flex;
    position: fixed;
    top: 0;
    @include zIndex(10);
  }

  &Short {
    max-width: 780px;
  }
}

.headerMain {
  display: flex;
  flex-direction: column;
  margin-top: $spacing-small;
  width: 960px;
}

.fixedSearchBg {
  height: 46px;
  opacity: 0;
  position: fixed;
  top: 0;
  width: 100%;
  @include zIndex(46);

  &::before {
    background-color: $maincolor;
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: scaleX(0);
    transition: all 0.1s ease-out;
  }

  &Loyalty::before {
    background-color: $color-purple;
  }

  &Bookmark::before {
    background-color: $bookmarkmaincolor;
  }

  &.isShow {
    opacity: 1;
    @include zIndex(10);

    &::before {
      transform: scaleX(1);
    }
  }
}

.fixedBasket {
  align-items: center;
  height: 50px;
  opacity: 0;
  position: absolute;
  right: 0;
  top: $spacing-tiny;
  visibility: hidden;
  width: 115px;

  &Loyalty {
    .fixedCount {
      border-radius: 100px;
    }
  }

  &.fixedBasketShow {
    align-items: center;
    border: unset;
    display: flex;
    height: 46px;
    margin-right: -610px;
    opacity: 1;
    position: fixed;
    right: 50%;
    top: 0;
    visibility: visible;
    width: 190px;
    @include zIndex(9);
  }

  @include desktopHover {
    .headerDropDownCart {
      display: block;
    }
  }

  .headerDropDownCart {
    top: 50px;
  }
}

.fixedBasketIcon {
  color: $color-white;
  filter: contrast(0) brightness(0) invert(100);
  width: 30px;
}

.currency {
  color: $color-white;
  display: flex;
  flex-direction: column;
  line-height: $line-height-small;
  margin-left: $spacing-small;
  text-align: center;
  width: 110px;

  &Sum {
    height: 18px;
    justify-content: flex-start;
  }
}

.fixedCount {
  background-color: $header-basket-count-bg;
  border-radius: $header-basket-count-br;
  color: $header-basket-count-color;
  display: block;
  min-width: 35px;
  text-align: center;
  text-decoration: none;
}

.cartFixedCount {
  display: flex;
  justify-content: center;
}

.headerDropDown {
  background-color: $color-white;
  border: 1px solid $color-gray-200;
  border-radius: $border-radius-input;
  box-shadow: 0 4px 4px rgba(192, 192, 192, 0.5);
  font-weight: normal;
  position: absolute;
  text-align: left;
  top: 80px;
  @include zIndex(11);
}

.dropdownLk {
  margin-left: -25%;
  right: $spacing-tiny;
  width: 186px;

  ul {
    width: 185px;
  }

  li {
    height: 30px;
    line-height: $line-height-big;
  }
}

.dropdownManager {
  cursor: default;
  min-width: 249px;
  padding: $spacing-small;
  right: $spacing-small;
  top: 79px;

  >div {
    display: flex;
  }
}

.managerTitle {
  color: $color-black;
  display: block;
  line-height: $line-height-small;
  margin-bottom: $spacing-tiny;
  @include typo-base();
}

.managerText {
  color: $color-black;
  display: block;
  flex: 1;
  font-size: $font-size-base;
  line-height: $line-height-default;
  white-space: normal;
  width: 100px;
  word-break: break-word;
}

.imageManager {
  align-items: center;
  display: flex;
  margin-right: $spacing-small;
  width: 64px;

  img {
    border-radius: $border-radius-input;
    height: auto;
  }
}

.helpManager {
  display: flex;
  margin-bottom: $spacing-small;
}

.headerDropDownHelp {
  padding: $spacing-tiny 0;
  width: 135px;

  li {
    height: 30px;
    line-height: $line-height-big;
    padding-left: $spacing-large;

    a {
      display: block;
    }

    @include desktopHover {
      color: $link-color-hover;
      position: relative;
      @include zIndex(44);
    }
  }

  &_loyalty {
    li {
      @include desktopHoverFocusActive {
        color: $color-pink;
      }
    }
  }
}

.dropdownHelp {
  cursor: default;
  padding: $spacing-small $spacing-default $spacing-small;
  right: (-$spacing-large);
  top: 79px;
  width: 260px;

  >div {
    display: flex;
    flex-direction: column;
  }
}

.dropdownSubtitle {
  color: $color-gray-300;
  display: block;
  font-weight: $font-semibold;
  margin: $spacing-tiny 0 $spacing-small;
  width: 100%;
}

.helpManagersWrapper {
  display: flex;
  flex-direction: column;
}

.dropdownCabinet {
  min-height: 65px;
  padding: $spacing-small $spacing-default $spacing-default;
  width: 567px;
  @include zIndex(19);
}

.invoiceWrapper {
  display: flex;
  flex-direction: column;
}

.newsTitle {
  color: $color-black;
  line-height: $line-height-default;
  @include typo-base();

  @include desktopHover {
    color: $link-color-hover;
  }

  &_loyalty {
    @include desktopHoverFocusActive {
      color: $color-pink;
    }
  }
}

.newsDate {
  color: $color-gray-300;
  font-size: $font-size-base;
}

.newsDropdownItem {
  margin-bottom: $spacing-default;

  &last-child {
    margin-bottom: 0;
  }
}

.headerDropDownCart {
  background-color: $color-white;
  border: 1px solid $color-gray-200;
  border-radius: $border-radius-input;
  box-shadow: 0 4px 4px rgba(192, 192, 192, 0.5);
  right: $spacing-tiny;
  top: 78px;
  width: 368px;
}

.basketLink {
  background-color: $color-yellow;
  border-radius: $border-radius-button;
  color: $color-black;
  display: block;
  font-size: $font-size-base;
  height: 30px;
  line-height: $line-height-big;
  text-align: center;
  width: 90px;

  @include desktopHover {
    background-color: $color-yellow-hover;
  }

  &_loyalty {
    background-color: $color-purple;
    color: $color-white;

    @include desktopHover {
      background-color: $color-purple-200;
      color: $color-white;
    }
  }
}

.iconFavorites {
  color: $color-orange;
  display: flex;
  margin: 0 $spacing-tiny;
  width: 12px;
}

.iconCompare {
  color: $color-blue-200;
  display: flex;
  margin: 0 $spacing-tiny;
  width: 15px;
}

.gift {
  height: 105px;
  position: relative;
  width: 100%;
}

.giftImage {
  background-color: $color-gray-200;
  background-image: url('/images/header/iphone.png');
  background-position: 2px -10px;
  background-repeat: no-repeat;
  background-size: cover;
  border: 3px solid $color-gray-200;
  border-radius: 5px;
  box-shadow: 0 1px 5px 0 $color-black;
  height: 95px;
  left: 0;
  position: absolute;
  right: 0;
  width: 343px;
}

.giftBox {
  background-image: url('/images/header/giftbox.png');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  height: 95px;
  left: 0;
  outline: 1px solid transparent;
  position: absolute;
  right: 0;
  transform-origin: 0 95px;
  transition: transform 0.5s ease-out;
  width: 343px;
  will-change: transform;

  &::before {
    background-image: url('/images/header/shadow.png');
    border-radius: 5px;
    content: none;
    display: block;
    height: 85px;
    opacity: 0.53;
    position: absolute;
    width: 345px;
  }
}

.headerDropDownTargetHasGift {
  width: 373px;

  @include desktopHover {
    .giftBox {
      transform: rotate(-5deg);
    }
  }
}

.miniIcon {
  left: (-$spacing-big);
  position: absolute;
  top: $spacing-tiny;
}

.emptyBasketMessage {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
}

.isEmpty {
  height: 40px;
  padding-left: $spacing-small;
}

.dropDownCartEmpty {
  height: 131px;
  width: 277px;
}

.emptyBasketText {
  align-items: center;
  border-bottom: 1px solid $color-gray-200;
  display: flex;
  height: 80px;
  justify-content: center;
  width: 100%;
}

.headerContractor {
  cursor: default;
}

.headerTop {
  background-color: $header-top-bg;
  position: relative;
  @include zIndex(15);
}

.cartLink {
  width: 100%;
}

.disabled {
  cursor: default;
}

.getLoyaltyAccess {
  cursor: default;
  max-width: 271px;
  padding: 0 50px 0 65px;
  text-align: left;

  >div {
    text-align: left;
  }
}

.getLoyaltyAccessLink {
  color: $color-pink;

  @include desktopHover {
    color: $color-purple;
  }
}

.headerDropDownWithoutTarget {
  height: 175px;
  width: 375px;
}

.conditionLink {
  color: $secondcolor;
  display: inline;
}

.headerTempUser {
  background: $color-red-100;
  color: $color-red;
  font-size: $font-size-base;
  height: 31px;
  line-height: $line-height-big;
  position: relative;
  @include zIndex(31);
}

.headerJoinToLoyalty {
  background: linear-gradient(to right, $color-orange 0%, $color-pink 100%);
  color: $color-white;
  height: 65px;
  padding-top: $spacing-default;
  position: relative;
  text-align: center;
  @include zIndex(38);
  @include typo-h2();

  .getLoyaltyAccessLink {
    color: $color-white;
    text-decoration: underline;

    @include desktopHover {
      color: $color-white;
    }
  }
}

.menuRegistrationSmall {
  width: 100px;

  .headerMenuRegistration {
    min-width: 100px;
  }
}

.interactions {
  width: auto;
}

.cartFixed {
  align-items: center;
  display: flex;
}

.statusContent {
  display: flex;
  gap: $spacing-large;
  min-width: 292px;
  padding: $spacing-default;
}