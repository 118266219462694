@import '../../theme/vars.scss'; 

.productOne {
  font-size: $font-size-base;

  &Compact {
    margin-top: $spacing-tiny;

    .cardWrapper {
      margin-top: 0;
    }
  }

  &Loyalty {
    .productOneTable {
      border-radius: $border-radius-block;
    }
  }
}

.productsOneWrapImage {
  align-items: center;
  border: 2px solid $color-gray-200;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;


  @include mq(md) {
    max-height: 288px;
  }

  &Main {
    height: 496px;
    padding: $spacing-small;
    width: 496px;

    @include mq(lg) {
      height: 100%;
      width: 100%;
    }
  }

  &QuickView {
    border: unset;
  }
}

.productGalleryModal {
  display: inline-flex;
  height: 100%;
  margin-top: 0;
  width: 100%;
}

.groupProperty {
  color: $color-black;
  display: flex;
  flex-wrap: wrap;
  font-size: $font-size-base;
  line-height: $line-height-default;
  padding-left: $spacing-small;

  @include mq(lg) {
    padding-left: 0;
  }
}

.property {
  color: $color-gray-300;
  margin-right: $spacing-small;

  &Block {
    display: flex;
    flex-direction: column;
  }

  &Title {
    color: $color-gray-300;
    padding: 0 0 $spacing-small;
  }

  &Brand {
    display: flex;
    flex-direction: column;

    &Logo {
      max-height: 91px;
      width: 91px;
    }

    &Link {
      color: $link-to-props;
      height: 24px;
      line-height: $line-height-default;
      margin-top: $spacing-tiny;

      @include desktopHover {
        color: $link-color-hover;
      }
    }

    &Name {
      font-weight: $font-bold;
      margin-left: $spacing-tiny;
    }
  }
}

.artGroup {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $spacing-big;

  &QuickView {
    margin-bottom: $spacing-small;
    margin-left: $spacing-tiny;
  }
}

.productOneTable {
  display: flex;
  margin-bottom: $spacing-large;

  &QuickView {
    height: 600px;
    padding: 0;
    position: inherit;
  }

  @include mq(lg) {
    flex-direction: column;
  }
}

.imageWrapper {
  width: 555px;

  &QuickView {
    width: 600px;
  }

  &Loyalty {
    width: 410px;
  }

  @include mq(lg) {
    width: 100%;
  }
}

.image {
  height: auto;
  width: 100%;

  > div {
    position: inherit;
  }

  > img {
      align-items: center;
      display: flex;
      height: 100% !important;
      justify-content: center;
      max-height: 100%;
      max-width: 100%;
      min-height: 100%;
      min-width: 100%;
      width: 100% !important;

    @include mq(md) {
      max-height: 284px;
    }
  }
}

.productImages {
  display: flex;
  flex-direction: row-reverse;

  @include mq(lg) {
    flex-direction: column;
    position: relative;
  }

  &Grayscale {
    filter: grayscale(100%);
    position: relative;
    @include zIndex(44);
  }

  &Column {
    flex-direction: column;
  }
}

.infoTd {
  margin-left: $spacing-small;
  width: 325px;

  @include mq(lg) {
    margin-left: 0;
    width: 100%;
  }

  &Loyalty {
    width: 257px;
  }
}

.actionsBlock {
  margin-left: $spacing-small;
  width: 287px;
  @include mq(lg) {
    margin-left: 0;
    width: 100%;
  }

  &Fixed {
    position: fixed;
    top: 50px;
    @include zIndex(8);
  }

  &Loyalty {
    width: 260px;

    @include mq(lg) {
      width: 100%;
    }
  }

  &QuickView {
    display: flex;
    flex-direction: column;
    margin-left: $spacing-big;
    padding-top: 0;
    width: 440px;

    .actions {
      width: 253px;
    }
  }
}

.scrollToProperty {
  align-items: center;
  color: $color-blue;
  cursor: pointer;
  display: flex;
  line-height: $line-height-default;
  padding-top: $spacing-default;

  @include desktopHover {
    color: $link-color-hover;
  }
}

.cardWrapper {
  display: block;
}

.inBasket {
  color: $color-black;
  font-size: $font-size-base;

  line-height: $line-height-default;
  margin-left: $spacing-default;
}

.codeWrapper {
  display: flex;
  flex-wrap: wrap;
}

.orderBlock {
  align-items: center;
  background-color: $color-gray-100;
  display: flex;
  flex-direction: column;
  padding: $spacing-small $spacing-default;
  position: relative;
}

.prices {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 253px;

  @include mq(lg) {
    width: 100%;
  }
}

.actions {
  align-items: center;
  display: flex;
  width: 253px;

  @include mq(lg) {
    width: 100%;
  }
}

.modal {
  height: 400px;

  &Video {
    height: 100%;
    width: 100%;
  }
}

.labels {
  left: $spacing-small;
  position: absolute;
  top: $spacing-small;
  @include zIndex(26);

  &QuickView {
    left: 0;
    top: 0;
  }

  &_auth {
    left: $spacing-large;
  }
}

.honestSign {
  bottom: 0;
  position: absolute;
}

.blockInfo {
  display: flex;
  flex-direction: column;

  &Top {
    display: flex;
    height: fit-content;
    min-height: 284px;

    @include mq(lg) {
      flex-direction: column;
    }
  }
}

.modifiers {
  margin-left: $spacing-small;
  margin-top: $spacing-tiny;
  width: 100%;
}

.modifier {
  width: 100%;

  &Items {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $spacing-small;
    margin-top: $spacing-tiny;
    width: 100%;
  }

  &Item {
    align-items: flex-start;
    background: $color-white;
    border: 1px solid $color-gray-200;
    border-radius: $border-radius-input;
    color: $color-gray-300;
    cursor: pointer;
    margin-bottom: $spacing-tiny;
    margin-right: $spacing-tiny;
    padding: $spacing-tiny $spacing-small;

    &Current {
      border: 1px solid $color-blue;
      color: $color-black;
    }

    &Inactive {
      border: 1px solid $color-gray-100;
      color: $color-gray-200;
    }
  }

  &More {
    background-color: $color-blue-100;
    border: unset;
    color: $color-blue;
  }
}

.tooltip {
  padding: $spacing-default;
  width: 220px;

  &Image {
    height: 120px;
    width: 120px;
  }

  &Price {
    padding-top: $spacing-small;
  }

  &Inactive {
    padding-top: $spacing-small;
    text-align: center;
    width: 100%;
  }
}

.linkScroll {
  color: $color-blue-200;
  font-size: $font-size-base;

  @include desktopHover {
    color: $link-color-hover;
    cursor: pointer;
  }
}
