@import '../../theme/vars.scss'; 

.lazyLoad {
  align-items: center;
  display: flex;
  justify-content: center;
}

.image {
  background-position: left;
  background-repeat: no-repeat;
  content: '';
  display: inline-block;
}

.contain {
  align-items: center;
  display: flex;
  height: 250px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.cover {
  height: 200%;
  left: -50%;
  position: absolute;
  top: -50%;
  width: 200%;
}

.img {
  &NoImage {
    background: url('/images/noImage/no-image.png') 100% 100% no-repeat;
    background-size: cover;
  }

  &Contain {
    height: auto;
    width: 100%;
  }

  &Cover {
    bottom: 0;
    left: 0;
    margin: auto;
    min-height: 50%;
    min-width: 50%;
    overflow-x: hidden;
    position: absolute;
    right: 0;
    top: 0;
  }

  &FullWidth {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
}

