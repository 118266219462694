@import '../../theme/vars.scss'; 

.scrollbar {
  overflow: hidden;
  position: relative;
  user-select: none;
}

.inner {
  display: block;
  overflow: auto;
  position: relative;
  user-select: text;
}

.scrollYWrapper {
  bottom: $spacing-small;
  position: absolute;
  right: 0;
  top: $spacing-small;
  width: 12px;
}

.scrollY {
  background-color: $color-gray-300;
  border-radius: 40px;
  position: absolute;
  right: $spacing-tiny;
  top: 0;
  width: 6px;
}

.scrollXWrapper {
  bottom: 0;
  height: 12px;
  left: $spacing-small;
  position: absolute;
  right: $spacing-small;
}

.scrollX {
  background-color: $color-gray-300;
  border-radius: 40px;
  bottom: $spacing-tiny;
  height: $spacing-tiny;
  left: 0;
  position: absolute;
}
