@import '../../theme/vars.scss'; 

.banners {
  width: auto;
}

.bannerImageWrapper {
  bottom: 0;
  max-width: calc((98% - 1200px) / 2);
  overflow: hidden;
  position: absolute;
  top: 0;

  &_left {
    direction: rtl;
    right: calc(51% + 600px);
  }

  &_right {
    left: calc(51% + 600px);
  }
}

.centerBannerAncestor {
  width: 200%;

  &_left {
    margin-left: 100%;
  }

  &_right {
    margin-left: -100%;
  }
}

.centerBanner {
  background-position-x: 50%;
  background-repeat: no-repeat;

  &_left {
    margin-right: -100%;
  }
}

.centerBannerImage {
  display: block;
  opacity: 0;
}

