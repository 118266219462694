@import '../../theme/vars.scss'; 

.Points {
  align-items: center;
  color: $color-purple;
  display: inline-flex;
  font-weight: $font-semibold;
  height: 30px;
  line-height: height;

  &__size {
    &_13 {
      font-size: $font-size-base;
    }

    &_16 {
      font-size: $font-size-h2;
      line-height: $line-height-default;
    }

    &_18 {
      font-size: $font-size-h1;
      line-height: $line-height-default;
    }
  }

  &_mode {
    &_price {
      color: $colorpointsvalueprice;
    }

    &_basket {
      color: $color-pink;
    }
  }

  &_capitalize {
    text-transform: capitalize;

    .PointsTitle {
      text-transform: capitalize;
    }
  }

  &_coloredTitle {
    .PointsTitle {
      color: inherit;
    }
  }
}

.PointsTitle {
  color: $color-black;
  display: inline-block;

  &__size {
    &_13 {
      font-size: $font-size-base;
    }

    &_16 {
      font-size: $font-size-h2;
      line-height: $line-height-default;
    }

    &_18 {
      font-size: $font-size-h1;
      line-height: $line-height-default;
    }
  }
}

.PointsValue {
  align-items: center;
  border: 2px solid;
  border-radius: 15px;
  display: inline-flex;
  height: 30px;
  justify-content: center;
  margin-right: $spacing-tiny;
  padding: 0 $spacing-small;

  .Points_mode_basket & {
    border-color: $color-pink;
  }
}
