@import '../../theme/vars.scss'; 

.truncateText {
  height: 100%;
  line-height: inherit;
  overflow: hidden;
  position: relative;

  @include mq(md) {
    line-height: $line-height-default;
    max-height: 3.2em;
    text-overflow: ellipsis
  }

  span {
    position: relative;
    @include zIndex(44);
  }

  &::after {
    bottom: 0;
    color: transparent;
    content: 'A';
    display: block;
    position: absolute;
    right: 0;
    text-decoration: none;
    width: 30px;
    @include zIndex(43);
  }

  &_color {
    &_white::after {
      background: linear-gradient(to right,
      transparent 13%,
      rgba($color-white, 0.7) 37%,
      rgba($color-white, 0.71) 38%,
      $color-white 64%);
    }

    &_alabaster::after {
      background: linear-gradient(to right,
      transparent 13%,
      rgba(#f7f7f7, 0.7) 37%,
      rgba(#f7f7f7, 0.71) 38%,
      $color-gray-100 64%);
    }

    &_errorBg::after {
      background: linear-gradient(to right,
      transparent 13%,
      rgba(#feebeb, 0.7) 37%,
      rgba(#feebeb, 0.71) 38%,
      $color-red-100 64%);
    }
  }
}
