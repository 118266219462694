@import '../../theme/vars.scss'; 

.redhelper {
  height: 186px;
  overflow: visible;
  position: fixed;
  right: (-$spacing-big);
  top: calc(50vh - 150px);
  width: 65px;
  @include zIndex(7);
}

.image {
  &Helper {
    height: 186px;
    width: 65px;

    @include desktopHover {
      cursor: pointer;
    }
  }

  &Bubble {
    bottom: $spacing-small;
    height: 27px;
    left: $spacing-small;
    position: absolute;
    width: 27px;
    @include zIndex(44);
  }
}

