@import '../../theme/vars.scss'; 

.storageModal {
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  &:not(&_hasFile) {
    .headerButton & .downloadButton {
      display: none;
    }
  }
}

.header {
  align-items: center;
  display: flex;
  margin-bottom: $spacing-default;

  @include mq(lg) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.headerButton {
  align-items: center;
  border: 1px solid $color-gray-200;
  border-radius: 16px;
  color: $maincolor;
  display: flex;
  font-size: $font-size-base;

  height: 28px;
  margin-left: $spacing-small;
  padding: 0 $spacing-small;

  @include desktopHover {
    background-color: $color-blue-100;
    color: $maincolor;
  }
}

.icon {
  filter: $filter-image-blue;
  height: 10px;
  margin-right: $spacing-tiny;
  width: 10px;
}

.content {
  font-size: $font-size-base;
  height: 0;
  line-height: $line-height-default;
  padding-bottom: 58.25%;
  position: relative;
  width: 100%;



  strong b {
    font-weight: $font-semibold;
  }

  em i {
    font-style: italic;
  }

  img {
    display: block;
    max-width: 100%;
  }

  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width:  100%;

    @include mq(lg) {
      height: 320px;
      width: 100%;
    }
  }
}

.downloadButton {
  align-items: center;
  background-color: $maincolor;
  border-radius: $border-radius-button;
  color: $color-white;
  display: inline-flex;
  font-size: $font-size-base;
  height: 50px;
  margin-top: $spacing-large;
  padding: 0 70px;

  .icon {
    filter: invert(100%);
  }

  @include desktopHover {
    background-color: $color-blue-hover;
    color: $color-white;
  }
}
