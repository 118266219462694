@import '../../theme/vars.scss'; 

.tooltip {
  cursor: pointer;
  font-size: $font-size-base;
  position: relative;

  &:not(&Node)::before {
    content: attr(data-tooltip);
    padding: $spacing-small;
  }

  &:not(&Node)::before,
  &Content {
    background-color: $color-white;
    border: 1px solid $color-gray-200;
    border-radius: 5px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
    left: 0;
    position: absolute;
    top: 110%;
    transition: visibility 0s linear 0.3s;
    visibility: hidden;
    @include zIndex(3);
  }

  &:not(&Node):hover {
    &::before {
      visibility: visible;
    }
  }

  &Node:hover &Content {
    visibility: visible;
  }
}

.container {
  background: #fff;
  border-radius: 6px 6px 0 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  height: fit-content;
  max-height: 70vh;
  overflow: auto;
  padding: $spacing-default;
  position: fixed;
  width: 100%;
}
