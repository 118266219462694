@import '../../theme/vars.scss'; 

.callBack {
  align-items: center;
  bottom: $spacing-small;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  overflow: visible;
  position: fixed;
  right: $spacing-small;
  width: 48px;
  @include zIndex(7);

  @include mq(lg) {
    height: 38px;
    width: 38px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: $spacing-default;
}

.fieldset {
  background-color: $color-gray-100;
  border: 0;
  display: flex;
  flex-direction: column;
  gap: $spacing-small;
  padding: $spacing-default;
}
