@import '../../theme/vars.scss'; 

.layerOpacity {
  background-color: rgba(255, 255, 255, 0.6);
  bottom: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  white-space: nowrap;
  @include zIndex(9);

  @include mq(lg) {
    background-color: rgba(102, 102, 102, 0.6)
  }
}

