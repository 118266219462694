@import '../../theme/vars.scss'; 

.capPreloader {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__item {
    background-color: #f7f7f7;
    margin: $spacing-small 0;
  }
}
