.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {margin-left: -8px;
  position: absolute;}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {border: 8px solid transparent;
  box-sizing: content-box;
  height: 0;
  position: absolute;
  width: 1px;}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {border-bottom-color: #aeaeae;
  border-width: 8px;
  content: '';
  left: -8px;
  z-index: -10;}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {margin-top: -8px;
  top: 0;}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {border-bottom-color: #f0f0f0;
  border-top: unset;}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {border-bottom-color: #aeaeae;
  top: -1px;}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {bottom: 0;
  margin-bottom: -8px;}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {border-bottom: unset;
  border-top-color: #fff;}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {border-top-color: #aeaeae;
  bottom: -1px;}.react-datepicker-wrapper {display: inline-block;}

.react-datepicker {background-color: #fff;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  color: #000;
  display: inline-block;
  font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif;
  font-size: 0.8rem;
  position: relative;}.react-datepicker--time-only .react-datepicker__triangle {left: 35px;}.react-datepicker--time-only .react-datepicker__time-container {border-left: 0;}.react-datepicker--time-only .react-datepicker__time {border-radius: 0.3rem;}.react-datepicker--time-only .react-datepicker__time-box {border-radius: 0.3rem;}

.react-datepicker__triangle {left: 50px;
  position: absolute;}.react-datepicker-popper {z-index: 10;}.react-datepicker-popper[data-placement^='bottom'] {margin-top: 10px;}.react-datepicker-popper[data-placement^='top'] {margin-bottom: 10px;}.react-datepicker-popper[data-placement^='right'] {margin-left: 8px;}

.react-datepicker-popper[data-placement^='right'] .react-datepicker__triangle {left: auto;
  right: 42px;}.react-datepicker-popper[data-placement^='left'] {margin-right: 8px;}

.react-datepicker-popper[data-placement^='left'] .react-datepicker__triangle {left: 42px;
  right: auto;}

.react-datepicker__header {background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding-top: 8px;
  position: relative;
  text-align: center;}

.react-datepicker__header--time {padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {display: inline-block;
  margin: 0 2px;}

.react-datepicker__current-month,
.react-datepicker-time__header {color: #000;
  font-size: 0.944rem;
  font-weight: bold;
  margin-top: 0;}

.react-datepicker-time__header {overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;}

.react-datepicker__navigation {background: none;
  border: 0.45rem solid transparent;
  cursor: pointer;
  height: 10px;
  line-height: 1.7rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-align: center;
  text-indent: -999em;
  top: 10px;
  width: 0;
  width: 10px;
  z-index: 10;}

.react-datepicker__navigation--previous {border-right-color: #ccc;
  left: 10px;}.react-datepicker__navigation--previous:hover {border-right-color: #b3b3b3;}

.react-datepicker__navigation--previous--disabled,
.react-datepicker__navigation--previous--disabled:hover {border-right-color: #e6e6e6;
  cursor: default;}

.react-datepicker__navigation--next {border-left-color: #ccc;
  right: 10px;}.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {right: 80px;}.react-datepicker__navigation--next:hover {border-left-color: #b3b3b3;}

.react-datepicker__navigation--next--disabled,
.react-datepicker__navigation--next--disabled:hover {border-left-color: #e6e6e6;
  cursor: default;}

.react-datepicker__navigation--years {display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 0;}

.react-datepicker__navigation--years-previous {border-top-color: #ccc;
  top: 4px;}.react-datepicker__navigation--years-previous:hover {border-top-color: #b3b3b3;}

.react-datepicker__navigation--years-upcoming {border-bottom-color: #ccc;
  top: -4px;}.react-datepicker__navigation--years-upcoming:hover {border-bottom-color: #b3b3b3;}.react-datepicker__month-container {float: left;}

.react-datepicker__month {margin: 0.4rem;
  text-align: center;}

.react-datepicker__time-container {border-left: 1px solid #aeaeae;
  float: right;
  width: 70px;}

.react-datepicker__time-container--with-today-button {border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline;
  position: absolute;
  right: -72px;
  top: 0;}

.react-datepicker__time-container .react-datepicker__time {background: white;
  position: relative;}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {margin: 0 auto;
  overflow-x: hidden;
  text-align: center;
  width: 70px;}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {box-sizing: content-box;
  height: calc(195px + (1.7rem / 2));
  list-style: none;
  margin: 0;
  overflow-y: scroll;
  padding-left: 0;
  padding-right: 0;
  width: 100%;}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {height: 30px;
  padding: 5px 10px;}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {background-color: #f0f0f0;
  cursor: pointer;}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {background-color: #216ba5;
  color: white;
  font-weight: bold;}.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {background-color: #216ba5;}.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {color: #ccc;}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {background-color: transparent;
  cursor: default;}

.react-datepicker__week-number {color: #ccc;
  display: inline-block;
  line-height: 1.7rem;
  margin: 0.166rem;
  text-align: center;
  width: 1.7rem;}.react-datepicker__week-number.react-datepicker__week-number--clickable {cursor: pointer;}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {background-color: #f0f0f0;
  border-radius: 0.3rem;}.react-datepicker__day-names,
.react-datepicker__week {white-space: nowrap;}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {color: #000;
  display: inline-block;
  line-height: 1.7rem;
  margin: 0.166rem;
  text-align: center;
  width: 1.7rem;}.react-datepicker__day {cursor: pointer;}

.react-datepicker__day:hover {background-color: #f0f0f0;
  border-radius: 0.3rem;}.react-datepicker__day--today {font-weight: bold;}

.react-datepicker__day--highlighted {background-color: #3dcc4a;
  border-radius: 0.3rem;
  color: #fff;}.react-datepicker__day--highlighted:hover {background-color: #32be3f;}.react-datepicker__day--highlighted-custom-1 {color: magenta;}.react-datepicker__day--highlighted-custom-2 {color: green;}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {background-color: #216ba5;
  border-radius: 0.3rem;
  color: #fff;}.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover {background-color: #1d5d90;}

.react-datepicker__day--keyboard-selected {background-color: #2a87d0;
  border-radius: 0.3rem;
  color: #fff;}.react-datepicker__day--keyboard-selected:hover {background-color: #1d5d90;}.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {background-color: rgba(33, 107, 165, 0.5);}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {background-color: #f0f0f0;
  color: #000;}

.react-datepicker__day--disabled, .react-datepicker__month--disabled {color: #ccc;
  cursor: default;}.react-datepicker__day--disabled:hover {background-color: transparent;}

.react-datepicker__input-container {display: inline-block;
  position: relative;}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {border: 1px solid transparent;
  border-radius: 0.3rem;}.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {cursor: pointer;}.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {border-top-color: #b3b3b3;}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {border-top-color: #ccc;
  border-width: 0.45rem;
  float: right;
  margin-left: 20px;
  position: relative;
  top: 8px;}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {background-color: #f0f0f0;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  left: 25%;
  position: absolute;
  text-align: center;
  top: 30px;
  width: 50%;
  z-index: 10;}.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {cursor: pointer;}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {height: 150px;
  overflow-y: scroll;}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {display: block;
  line-height: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;}.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {background-color: #ccc;}.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {border-bottom-color: #b3b3b3;}.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {border-top-color: #b3b3b3;}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {left: 15px;
  position: absolute;}

.react-datepicker__close-icon {background-color: transparent;
  border: 0;
  cursor: pointer;
  height: 16px;
  outline: 0;
  padding: 0;
  position: absolute;
  right: 7px;
  top: 25%;
  vertical-align: middle;
  width: 16px;}

.react-datepicker__close-icon::after {background-color: #216ba5;
  border-radius: 50%;
  bottom: 0;
  box-sizing: border-box;
  color: #fff;
  content: '\00d7';
  cursor: pointer;
  font-size: 12px;
  height: 16px;
  line-height: 1;
  margin: -8px auto 0;
  padding: 2px;
  position: absolute;
  right: 0;
  text-align: center;
  width: 16px;}

.react-datepicker__today-button {background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  clear: left;
  cursor: pointer;
  font-weight: bold;
  padding: 5px 0;
  text-align: center;}

.react-datepicker__portal {align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 440;}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {line-height: 3rem;
  width: 3rem;}@media (max-width: 400px), (max-height: 550px) {.react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {line-height: 2rem;
    width: 2rem;}}.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {font-size: 1.44rem;}.react-datepicker__portal .react-datepicker__navigation {border: 0.81rem solid transparent;}.react-datepicker__portal .react-datepicker__navigation--previous {border-right-color: #ccc;}.react-datepicker__portal .react-datepicker__navigation--previous:hover {border-right-color: #b3b3b3;}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled,
.react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {border-right-color: #e6e6e6;
  cursor: default;}.react-datepicker__portal .react-datepicker__navigation--next {border-left-color: #ccc;}.react-datepicker__portal .react-datepicker__navigation--next:hover {border-left-color: #b3b3b3;}

.react-datepicker__portal .react-datepicker__navigation--next--disabled,
.react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {border-left-color: #e6e6e6;
  cursor: default;}
