@import '../../theme/vars.scss'; 

.orderButton {
  position: relative;
  user-select: none;
}

.placeholder,
.orderInputBase,
.orderInputLoyaltyTarget {
  border: 1px solid $color-gray-200;
  border-radius: $border-radius-button;
  color: $color-black;
  height: 30px;
  line-height: $line-height-default;
  overflow: hidden;
  padding: 0 $spacing-big;
  position: relative;
  text-align: center;
  width: 100%;
  @include typo-base();
}

.orderInputLoyalty {
  border-radius: $border-radius-block;
}

.placeholder {
  left: 0;
  position: absolute;
  top: 0;
}

.placeholder + .orderInputEmpty {
  background-color: rgba(255, 255, 255, 0.5);
}


.orderInput_incorrect {
  color: $color-red;
}

.orderInputLoyaltyTarget {
  border: 1px solid $color-gray-200;
  border-radius: $border-radius-block;
  color: $color-black;
  font-size: $font-size-h1;
  height: 32px;
  line-height: $line-height-default;
  padding: 0 $spacing-big;
  text-align: center;
  width: 100%;
}

.buttonLeftBase,
.buttonLeftLoyaltyTarget {
  bottom: 0;
  cursor: pointer;
  left: 0;
  line-height: $line-height-default;
  position: absolute;
  text-align: center;
  top: 0;
  width: 28px;
  @include typo-base();
}

.buttonRightBase,
.buttonRightLoyaltyTarget {
  bottom: 0;
  cursor: pointer;
  line-height: $line-height-default;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 28px;
  @include typo-base();
}

.buttonLeftLoyaltyTarget,
.buttonRightLoyaltyTarget {
  color: $color-red;
  font-size: $font-size-h1;
  line-height: $line-height-default;

  &:not([disabled]) {
    &::after {
      border-color: $color-red;
    }

    @include desktopHover {
      color: $color-red;
    }
  }

  .buttonLeftHover,
  .buttonRightHover {
    height: 21px;
    width: 21px;
  }
}

.buttonLeftBase,
.buttonRightBase {
  &:not([disabled]):hover {
    color: $link-color-hover;
  }

  &Loyalty:not([disabled]):hover {
    color: $color-purple;
  }
}

.buttonLeftBase:not([disabled]):hover .buttonLeftHover,
.buttonRightBase:not([disabled]):hover .buttonRightHover,
.buttonLeftLoyaltyTarget:not([disabled]):hover .buttonLeftHover,
.buttonRightLoyaltyTarget:not([disabled]):hover .buttonRightHover {
  display: block;
}

.buttonLeftHover,
.buttonRightHover {
  border: 1px solid $link-color-hover;
  border-radius: $border-radius-circle;
  display: none;
  height: 18px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 18px;

  &Loyalty {
    border: 1px solid $color-purple;
  }
}

.orderInputBasket {
  composes: orderInputBase;

  &[disabled] {
    background: $color-white;
    color: $color-gray-200;
  }
}

.buttonLeftBasket {
  composes: buttonLeftBase;
  font-size: $font-size-base;

  &Disabled {
    border-radius: 4px 0 0 4px;
    color: $color-gray-200;
    cursor: auto;
    pointer-events: none;
  }
}

.buttonRightBasket {
  composes: buttonRightBase;
  font-size: $font-size-base;

  &Disabled {
    border-radius: 0 4px 4px 0;
    composes: buttonLeftBasketDisabled;
  }
}

.orderButtonDropDown {
  background: $color-white;
  border: 1px solid $color-gray-200;
  border-radius: 2px;
  box-shadow: 0 2px 3px $color-gray-300;
  max-width: 232px;
  position: fixed;
  @include zIndex(14);
}

.orderButtonItem {
  align-items: center;
  background: none;
  border: unset;
  cursor: pointer;
  display: flex;
  font-size: $font-size-base;
  height: 33px;
  justify-content: center;
  line-height: $line-height-default;
  text-align: center;
  width: 100%;

  @include desktopHover {
    background-color: $color-gray-100;
  }

  &:nth-child(even) {
    background: $color-gray-100;
  }
}

.orderButtonWrapper {
  position: relative;
}

.buttonLeft {
  &Disabled {
    border-radius: 4px 0 0 4px;
    color: $color-gray-200;
    cursor: auto;
  }
}

.buttonRight {
  &Disabled {
    border-radius: 0 4px 4px 0;
    composes: buttonLeftDisabled;
  }
}

.deltaBtn {
  background: none;
  border: unset;
  display: block;
  margin: 0;
  padding: 0;
}
