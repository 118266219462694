@import '../../theme/vars.scss'; 

.cart {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;

  &.disabledCard {
    .icon {
      color: $color-gray-200;
    }
  }

  @include desktopHover {
    .icon {
      filter: $filter-image-purple;
    }
  }
}

.icon {
  color: $secondcolor;
  display: inline-block;
  filter: $filter-image-pink;
  position: relative;
  vertical-align: top;
  width: 36px;

  &Wrapper {
    display: flex;
    position: relative;
  }
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 61px;
  justify-content: space-between;
  position: relative;
}

.groups {
  margin-left: $spacing-default;
}

.wrapPrice {
  color: $color-black;
  display: block;
  line-height: $line-height-default;
  position: relative;
  text-align: center;
  @include typo-base();
}

.itemsCount {
  margin-top: $spacing-small;
}

.basketCount {
  background-color: $color-yellow;
  border-radius: 100px;
  bottom: (-$spacing-small);
  color: $color-black;
  display: block;
  left: 0;
  margin: 0 auto;
  padding-left: $spacing-tiny;
  padding-right: $spacing-tiny;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
  width: 25px;
}

.iconGray {
  color: $color-gray-200;
}
