@import '../../theme/vars.scss'; 

.contractor {
  &__item {
    display: flex;
    justify-content: space-between;
    padding: $spacing-tiny $spacing-default;
    width: 100%;

    @include mq(lg) {
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start;
    }

    &__even {
      background-color: $color-gray-100;
    }

  }

  &__action {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    &__inactive {
      color: $color-blue;

      @include desktopHover {
        cursor: pointer;
      }
    }

    &__active {
      color: $color-black;
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
  }
}

.contractors {
  font-size: $font-size-base;
  width: 100%;

  &__current {
    font-size: $font-size-base;
  }

  &__item {
    display: flex;
    padding: $spacing-small;

    @include mq(lg) {
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start;
    }

    &_title {
      display: none;
      @include mq(lg) {
        color: $color-gray-300;
        display: block;
      }
    }

    &_value {
      @include mq(lg) {
        margin-bottom: $spacing-small;
      }
    }

    &__even {
      background-color: $color-gray-100;
    }

    &__odd {
      background-color: $color-white;
    }
  }

  &__header {
    display: flex;
    padding: $spacing-small;

    @include mq(lg) {
      display: none;
    }

    &__item {
      color: $color-gray-300;
    }

    &__title {
      width: 307px;
    }

    &__login {
      width: 230px;
    }

    &__mail {
      width: 235px;
    }

    &__kod {
      width: 115px;
    }
  }

  &__data {
    &__title {
      font-weight: $font-semibold;
      width: 297px;
    }

    &__info {
      border-bottom: 1px solid $color-gray-200;
      display: flex;
      padding: $spacing-small 0;

      @include mq(lg) {
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
      }

      &:last-child {
        border: unset;
        padding-bottom: 0;
      }

      &:first-child {
        padding-top: 0;
      }
    }

    &__description {
      width: 626px;

      @include mq(lg) {
        width: 100%;
      }
    }

    &__login {
      width: 220px;
    }

    &__mail {
      width: 225px;
    }

    &__kod {
      width: 105px;
    }

    &__change {
      color: $color-blue;
      width: 50px;

      @include desktopHover {
        cursor: pointer;
      }
    }

    &__item {
      margin-right: $spacing-small;
      overflow: auto;
    }
  }

  &__notfound {
    text-align: center;
  }
}

.input {
  position: relative;
  width: 100%;

  @include mq(lg) {
    background-color: $color-blue;
    border-radius: $border-radius-input;
    margin-bottom: $spacing-small;
    padding: $spacing-small;
  }

  &__submit {
    align-items: center;
    background: none;
    border: unset;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;

    @include mq(lg) {
      background-color: $color-blue;
      border-radius: $border-radius-input;

      img {
        filter: invert(1)
      }
    }
  }

  &__submitIcon {
    height: 15px;
    width: 16px;


    @include mq(lg) {
      height: 20px;
      width: 20px;
    }
  }
}

.tab {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: $spacing-default 0;

  &__title {
    color: $color-blue;
    font-size: $font-size-h2;
    padding-right: $spacing-default;

    &__active {
      color: $color-black;
    }

    @include desktopHover {
      cursor: pointer;
    }
  }

  &__back {
    @include desktopHover {
      cursor: pointer;
    }
  }
}

.tabs {
  align-items: center;
  display: flex;
}

.edo {
  color: $color-blue;
}
