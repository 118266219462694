@import '../../theme/vars.scss'; 

.tabsContent {
  display: none;
  margin-top: $spacing-small;

  &_active {
    display: block;
  }
}

