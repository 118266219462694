@import '../../theme/vars.scss'; 

.popup {
  display: flex;
}

.analogs {
  &Wrapper {
    width: 100%;
  }

  &List {
    display: flex;
    flex-direction: column;

    @include mq(lg, sm) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    @include mq(sm) {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
}

.popupContainer {
  display: flex;
  flex-direction: column;
}

.productList {
  margin-top: $spacing-small;
}

.partiallAnalogsList {
  margin-top: $spacing-big;
}
