@import '../../theme/vars.scss'; 

.honestSign {
  display: flex;
  flex-direction: column;

}

.hint {
  color: $color-gray-300;

  a {
    text-decoration: underline;
  }
}
