@import '../../theme/vars.scss'; 

.labels {
  display: flex;
  flex-wrap: wrap;
  font-size: $font-size-base;
  width: 100%;
}

.label {
  color: $color-white;
  display: flex;
  flex-grow: 0;
  padding-bottom: $spacing-tiny;
  padding-right: $spacing-tiny;
  position: relative;
  width: auto;

  &Expanded {
    cursor: pointer;
  }

  &bookmark {
    display: flex;

    .name {
      padding: $spacing-tiny;
    }

    .icon {
      height: 16px;
      width: 16px;
    }
  }
}

.name {
  padding: $spacing-tiny;

  &Reduce {
    background-color: $color-red;
  }
}

.toggle {
  align-items: center;
  border-left: 1px solid $color-white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: $spacing-tiny;
}

.icon {
  filter: invert(1);
}

.hint {
  background-color: $color-white;
  border: 1px solid $product-border;
  left: 0;
  padding: $spacing-tiny 0;
  position: absolute;
  top: 100%;
  transition: visibility 0s linear 0.3s;
  visibility: hidden;
  width: auto;
  @include zIndex(21);

  &Open {
    visibility: visible;
  }

  &Item {
    color: $color-black;
    display: flex;
    padding: $spacing-tiny $spacing-small;
    white-space: nowrap;

    @include desktopHover {
      background-color: $color-gray-100;
      color: $link-color-hover;
    }
  }
}
