@import '../../theme/vars.scss'; 

.progressLine {
  background-color: $color-yellow-200;
  border-radius: 15px 0 0 15px;
  height: 30px;
  width: 100%;

  &Container {
    background-color: $color-gray-200;
    border-radius: 15px;
    box-shadow: inset 0 3px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    height: 30px;
    position: relative;
    width: 100%;
  }

  &Completed {
    background-color: $color-green;
    border-radius: 15px;
  }

  &Pay {
    border-radius: 15px;
  }

  &Plus {
    height: 18px;
    width: 18px;

    &Wrapper {
      align-items: center;
      background-color: $color-white;
      border-radius: $border-radius-circle;
      display: flex;
      height: 26px;
      justify-content: center;
      left: (-$spacing-default);
      position: absolute;
      top: 2px;
      width: 26px;

      &Completed {
        display: none;
      }
    }
  }

  &Sum {
    align-items: center;
    color: $color-black;
    display: flex;
    padding: 0 $spacing-default;
    white-space: nowrap;
    @include typo-base($font-bold);

    &Completed {
      background-color: $color-green;
      border-radius: 30px;
      color: $color-white;
    }

    &Text {
      margin: 0 $spacing-tiny;
    }
  }

  &Basket {
    filter: none;
    height: 18px;
    margin-right: $spacing-tiny;
    width: 18px;

    &Completed {
      filter: invert(1);
    }
  }

  &Ruble {
    font-weight: $font-bold;
    padding-left: $spacing-tiny;
  }

  &Info {
    display: flex;
    height: 100%;
    justify-content: space-between;
    position: absolute;
    width: 100%;
  }
}

.hint {
  align-items: center;
  display: flex;
  font-size: $font-size-base;
  margin-right: $spacing-small;

  &Completed {
    color: $color-white;
  }
}
