@import '../../theme/vars.scss'; 

.banner {
  margin: 0;
  min-width: 288px;

  &Img {
    max-height: 100%;
    max-width: 288px;
  }
}
