@import '../../theme/vars.scss'; 

.rightColumnOneBase {
  width: 100%;
}

.capOneBase {
  background-color: $color-gray-100;
  display: flex;
  font-size: $font-size-base;
  height: 106px;
  justify-content: center;
  margin-bottom: $spacing-default;
  width: 100%;

  span {
    color: $color-orange;
  }

  a {
    cursor: pointer;

    @include desktopHover {
      color: $link-color-hover;
    }
  }

  &.withOutPrice {
    text-align: center;
  }
}

.capOneProductOne {
  color: $color-black;
  composes: capOneBase;
  display: flex;
  flex-direction: column;
  font-size: font-size-base;

  height: auto;
  line-height: $line-height-default;
  margin-bottom: $spacing-large;
  padding: $spacing-default 46px;

  span {
    color: $color-black;
  }

  a {
    color: $color-blue;

    @include desktopHover {
      color: $link-color-hover;
    }
  }
}

.capOneProductOneQuick {
  color: $color-black;
  composes: capOneBase;
  display: flex;
  flex-direction: column;
  font-size: font-size-base;

  height: auto;
  line-height: $line-height-default;
  margin-bottom: $spacing-small;
  padding: $spacing-default 56px;

  span {
    color: $color-black;
  }

  a {
    color: $color-blue;

    @include desktopHover {
      color: $link-color-hover;
    }
  }
}

.link {
  color: $color-blue;
  display: inline;

  @include desktopHover {
    color: $link-color-hover;
    cursor: pointer;
  }
}

.capPrice {
  padding-bottom: $spacing-small;
}

.price {
  @include typo-h1-lg();
  color: $color-black;
  line-height: $line-height-default;
  margin-bottom: 2px;
  text-align: left;
}

.currencyValue {
  vertical-align: middle;
}

.partGroup {
  color: $color-black;
  font-size: $font-size-base;
  height: 18px;
  text-align: left;
}

.partIcon {
  align-items: flex-end;
  display: inline-flex;
  flex-direction: row;
  height: 10px;
  margin-right: $spacing-small;
  width: 8px;

  span {
    background: $color-black;
    display: block;
    margin-right: 1px;
    width: 2px;
  }

  span:nth-child(1) {
    height: 9px;
  }

  span:nth-child(2) {
    height: 10px;
  }

  span:nth-child(3) {
    height: 8px;
    margin-right: 0;
  }
}

.batch {
  align-items: flex-end;
  display: inline-flex;
}

.registrationText {
  display: inline;
}

.store {
  background-color: $color-white;
  border-radius: $border-radius-input;
  display: flex;
  justify-content: flex-start;
  margin-bottom: $spacing-small;
  padding: $spacing-small;
  text-align: left;

  &__icon {
    height: 18px;
    width: 18px;
  }

  &__name {
    margin-left: $spacing-tiny;

    &_bold {
      font-weight: $font-semibold;
    }
  }
}
