@import '../../theme/vars.scss'; 

.portal {
  background: $color-white;
  bottom: 0;
  display: flex;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  @include zIndex(3);
}

.modalContainer {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  position: relative;
  width: 100%;

  @include mq(lg) {
    padding-left: $spacing-tiny;
    padding-right: $spacing-tiny;
  }
}

.mediaWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
}

.imageWrapper {
  align-items: center;
  display: flex;
  justify-content: center;

  @include desktopHover {
    cursor: zoom-in;
  }

  &ZoomOut {
    @include desktopHover {
      cursor: zoom-out;
    }
  }

  &WithoutZoom {
    @include desktopHover {
      cursor: default;
    }
  }
}

.button {
  &Close {
    align-items: center;
    background-color: $color-white;
    border-radius: $border-radius-default;
    display: flex;
    height: 100px;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
    @include zIndex(19);

    @include desktopHover {
      background-color: $color-gray-100;

      img {
        filter: $filter-image-orange;
      }
    }
  }

  &Arrow {
    align-items: center;
    background-color: $color-white;
    border-radius: $border-radius-default;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100px;
    @include zIndex(19);

    &_prev {
      left: 0;
    }

    &_next {
      right: 0;
    }

    @include desktopHover {
      background-color: $color-gray-100;

      img {
        filter: $filter-image-orange;
      }
    }
  }
}

.iconClose {
  height: 50px;
  width: 50px;
}

.iconArrow {
  height: 32px;
  width: 32px;
}

.video {
  display: block;
  min-width: 560px;

  &Wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
