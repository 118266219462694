@import '../../theme/vars.scss'; 

.invoice {
  &List {
    color: $color-black;
    font-size: $font-size-base;

    &Wrapper {
      display: block;
    }

    &Header {
      width: 100%;
    }

    &Empty {
      margin-top: $spacing-default;
    }
  }

  &Num {
    font-size: $font-size-base;
  }
}

.table {
  display: grid;

  &Cell {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    line-height: $line-height-default;
    padding: $spacing-small $spacing-tiny;

    @include desktopHover {
      color: $link-color;
    }
  }

  &Row {
    display: grid;
    grid-template-columns: 109px 111px 209px 101px 108px 160px 123px 35px;
    position: relative;

    &Wrapper {
      border-bottom: 1px solid $color-gray-200;
      display: grid;
      position: relative;

      &Highlighted {
        &:nth-child(odd) {
          background-color: $color-gray-100;
        }

        @include desktopHover {
          &::before,
          &::after {
            background-color: $color-blue-200;
            content: '';
            display: block;
            height: 1px;
            left: 0;
            position: absolute;
            top: -1px;
            width: 100%;
          }

          &::after {
            bottom: 0;
            top: auto;
          }
        }
      }

      &Small {
        @include desktopHover {
          background-color: $color-gray-100;
        }
      }
    }

    &Header {
      border-bottom: 1px solid $color-gray-200;
    }

    &Gray {
      color: $color-gray-300;

      @include desktopHover {
        color: $color-gray-300;
      }
    }

    &Small {
      grid-template-columns: 130px 185px 107px 112px;
    }
  }
}

.selectLabel {
  color: $color-gray-300;
  display: inline-block;
  font-size: $font-size-base;

  height: 20px;
  line-height: $line-height-default;
  position: relative;
  top: -2px;
  vertical-align: middle;
}

.selectWrapper {
  color: $color-black;
  display: inline-block;
  font-size: $font-size-base;

  line-height: $line-height-default;
  position: relative;
  vertical-align: middle;
  width: 100%;
}

.th {
  color: $color-gray-300;
  display: flex;
  justify-content: flex-start;
  padding: $spacing-small;
}

.alignRight {
  text-align: right;
}

.gray {
  color: $color-gray-300;
  display: block;
}

.tax {
  &Info {
    display: flex;
    line-height: $line-height-default;
  }

  &Value {
    padding-left: $spacing-tiny;
  }
}

.bold {
  font-weight: $font-semibold;
}

.selectWrapperInline {
  composes: selectWrapper;
  max-width: 225px;
}

.selectDate {
  composes: selectWrapper;
  max-width: 180px;
}

.selectLabelInline {
  composes: selectLabel;
  line-height: $line-height-big;
  padding-right: $spacing-tiny;
  text-align: left;
  width: auto;
}

.selectLabelInlineTop {
  composes: selectLabelInline;
  position: relative;
  top: (-$spacing-tiny);
}

.refresh {
  width: 70px;
}

.refreshLink {
  color: $secondcolor;
  cursor: pointer;
  font-size: $font-size-base;

  line-height: $line-height-big;
  text-decoration: underline;
}

.sum {
  font-weight: $font-semibold;
}

.tdRightAlign {
  text-align: right;
}

.tabsWrapper {
  padding-top: $spacing-small;
}

.isBookmark {
  background-color: $color-yellow;
  padding: $spacing-tiny;
}

.countProducts {
  padding-bottom: $spacing-small;
}

.thSortButton {
  align-items: center;
  background-color: transparent;
  border: unset;
  color: inherit;
  cursor: pointer;
  display: flex;
  width: 100%;

  &Active {
    color: $color-black;
  }

  &NotClicable {
    cursor: default;
  }
}

.thSortArrow {
  margin-left: $spacing-tiny;

  &Desc {
    transform: rotate(180deg);
  }
}

.label {
  border-radius: $border-radius-default;
  display: inline-block;
  line-height: $line-height-default;
  margin: $spacing-tiny;
  padding: $spacing-tiny;

  &BOOKMARK {
    background-color: $color-orange;
    color: $color-white;
  }

  &918OK {
    background: linear-gradient(to bottom, $color-orange, $color-orange-hover);
    color: $color-white;
  }

  &Store {
    background-color: #ffa300;
    color: $color-white;

    &Main {
      background-color: $color-blue;
      color: $color-white;
    }
  }
}

.newLine {
  display: block;
}

.secondName {
  padding-left: $spacing-tiny;
}

.modalError {
  align-items: center;
  display: flex;
  height: 200px;
  justify-content: center;
}

.num,
.date {
  width: auto;
}
