@import '../../theme/vars.scss'; 

.registration {
  display: flex;
  justify-content: space-between;
  min-height: 843px;

  @include mq(lg) {
    min-height: min-content;
  }

  &__form {
    display: flex;
    flex-direction: column;
    padding-right: $spacing-large;
    width: 562px;

    &_fields {
      background-color: $color-white;
    }

    @include mq(lg) {
      max-width: 316px;
      padding: 0;
      width: 100%;
    }

    @include mq(md) {
      max-width: 100%;
    }
  }

  &__title {
    line-height: $line-height-default;
    margin-bottom: $spacing-big;
    @include typo-h1();

    @include mq(lg) {
      font-size: $font-size-h2;
      margin-bottom: $spacing-default;
    }
  }

  &__field {
    background-color: $color-gray-100;
    margin-bottom: $spacing-small;
    padding: $spacing-default;
  }

  &__checkbox {
    display: flex;
    margin: $spacing-big 0 $spacing-small;
  }

  &__address {
    display: flex;
    flex-direction: column;
  }

  &__label {
    display: block;
    margin-bottom: $spacing-small;
    @include typo-base();
  }

  &__phone {
    display: flex;
    margin-bottom: $spacing-small;
    position: relative;

    &_code {
      margin-right: $spacing-tiny;
      width: 67px;

      &_input {
        left: 1px;
        margin-right: 0;
        position: absolute;
        top: 1px;
        width: auto;
        @include zIndex(44);
      }

      &_field {
        align-items: center;
        background-color: $color-white;
        border-radius: 4px 0 0 4px;
        display: flex;
        height: 28px;
        line-height: $line-height-default;
      }
    }

    &_number {
      flex-grow: 1;

      &_input {
        position: relative;

        :global {
          .src-UI-Input--input {
            padding-left: 72px;
          }
        }
      }
    }
  }
}

.company{
  &__info {
    display: flex;
    flex-direction: column;

    @include desktopHover {
      .companyTitle {
        color: $color-orange;
      }
    }
  }

  &__title {
    font-weight: $font-semibold;
  }

  &__inn {
    color: $color-gray-300;
    padding-right: $spacing-tiny;
  }
}

.paragraph {
  padding-bottom: $spacing-default;
}

.hidden {
  display: none;
}

.select {
  position: relative;

  &__wrapper {
    height: 30px;
    position: relative;
  }

  &__block {
    background: $color-white;
    border: 1px solid $color-gray-200;
    border-radius: $border-radius-button;
    display: flex;
    height: 30px;
    justify-content: space-between;
    line-height: $line-height-small;

    &_title {
      display: flex;
      line-height: $line-height-big;
    }

    &_image {
      align-items: center;
      background-color: $color-gray-100;
      border-radius: 4px 0 0 4px;
      display: flex;
      justify-content: center;
      margin-right: $spacing-small;
      padding: $spacing-small;
    }
  }

  &__option {
    background: $color-white;
    border: 1px solid $color-gray-200;
    display: flex;
    flex-direction: column;
    margin-top: $spacing-tiny;
    padding: $spacing-tiny 0;
    position: absolute;
    top: 100%;
    visibility: hidden;
    width: 100%;
    @include zIndex(42);

    &_active {
      visibility: visible;
    }

    &_item {
      align-items: center;
      display: flex;
      padding: $spacing-tiny $spacing-small;

      @include desktopHover {
        background: $color-gray-100;
        color: $color-orange;
        cursor: pointer;
      }

      &_selected {
        background: $color-gray-100;
        color: $color-gray-300;
        pointer-events: none;
      }
    }
  }
}

.icon {
  &__flag {
    display: flex;

    &_option {
      margin-right: $spacing-small;
    }
  }

  &__select {
    margin: $spacing-small $spacing-small 0 0;
    width: 11px;

    &_up {
      transform: rotate(180deg);
    }
  }
}

.textarea {
  border-radius: $border-radius-input;
  padding: $spacing-tiny;
}

.alignCenter {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.link {
  color: $color-blue;
  cursor: pointer;
  text-decoration: underline;
}

.message {
  &__wrapper{
    align-items: center;
    display: flex;
    flex-grow: 1;
  }

  &__icon {
    align-items: center;
    display: flex;
    filter: invert(100%);
    width: 12px;

    &_error {
      filter: none;
      height: 25px;
      left: -1px;
      position: relative;
      top: -2px;
      width: 23px;
    }

    &_wrap {
      align-items: center;
      background-color: $color-green;
      border-radius: $border-radius-circle;
      display: flex;
      height: 21px;
      justify-content: center;
      margin-bottom: $spacing-big;
      width: 21px;

      &_error {
        background-color: transparent;
      }
    }
  }
}

.popup {
  animation: appear 0.2s ease-in;
  animation-direction: normal;
  background-color: rgba(0, 0, 0, 0.9);
  bottom: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  @include zIndex(30);

  &.withoutOverlay {
    background-color: transparent;

    .imageWrapper {
      box-shadow: 0 2px 6px 1px rgba(117, 117, 117, 1);
    }
  }
}

.link {
  color: $color-blue;

  @include desktopHover {
    color: $link-color-hover;
    cursor: pointer;
  }
}

.autocomplete{
  &__wrapper {
    position: relative;

    &_readOnly {
      pointer-events: none;
    }
  }

  &__clear {
    position: absolute;
    right: 2px;
    top: 0;
  }
}
