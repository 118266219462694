@import '../../theme/vars.scss'; 

.content {
  display: block;

  &__main {
    border-bottom: 1px solid $color-gray-200;
    margin: $spacing-default $spacing-default 0;
    padding-bottom: $spacing-small;
  }

  &__info {
    display: flex;
    line-height: $line-height-default;
  }

  &__icon {
    align-items: flex-start;
    display: flex;
    filter: $filter-image-orange;
    margin-right: $spacing-tiny;
  }

  &__text {
    display: flex;
    flex-direction: column;
  }

  &__points {
    &Fired {
      background-color: $color-red-100;
      margin: 0 $spacing-tiny;
      white-space: nowrap;
    }

    &Current {
      background-color: $color-gray-200;
      margin: 0 $spacing-tiny;
      white-space: nowrap;
    }

    &ExpirationDate {
      display: block;
    }
  }
}

.target {
  padding: $spacing-default;

  &__title {
    display: block;
    font-size: $font-size-base;
    margin-bottom: $spacing-tiny;
  }

  &__name {
    max-width: 175px;
    padding-right: $spacing-small;
  }

  &__item {
    display: flex;
    height: 65px;
    overflow: hidden;
  }

  &__image {
    height: 65px;
    width: 65px;
  }
}

.points {
  color: $color-pink;
  display: block;
  line-height: $line-height-default;
  margin-top: $spacing-big;
  @include typo-base();

  &_loyalty {
    color: $color-pink;
  }
}

.getGiftBtn {
  background-color: $color-yellow;
  border: unset;
  border-radius: $border-radius-button;
  font-size: $font-size-base;
  height: 30px;
  margin-top: $spacing-small;
  width: 100%;

  @include desktopHover {
    background-color: $color-yellow-hover;
  }
}
