.rcs-custom-scroll {
  min-height: 0;
  min-width: 0;
}

.rcs-custom-scroll .rcs-outer-container {
  overflow: hidden;
  position: relative;
}

.rcs-custom-scroll .rcs-outer-container:hover .rcs-custom-scrollbar {
  opacity: 1;
  transition-duration: 0.2s;
}

.rcs-custom-scroll .rcs-inner-container {
  overflow-x: hidden;
  overflow-y: scroll;
}

.rcs-custom-scroll .rcs-inner-container::after {
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.05) 60%,
    transparent 100%
  );
  content: '';
  height: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: height 0.1s ease-in;
  will-change: height;
}

.rcs-custom-scroll .rcs-inner-container.rcs-content-scrolled::after {
  height: 5px;
  transition: height 0.15s ease-out;
}

.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-inner-container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rcs-custom-scroll .rcs-custom-scrollbar {
  box-sizing: border-box;
  height: 100%;
  opacity: 0;
  padding: 6px 0;
  pointer-events: none;
  position: absolute;
  right: 3px;
  transition: opacity 0.4s ease-out;
  width: 6px;
  will-change: opacity;
  z-index: 10;
}

.rcs-custom-scroll .rcs-custom-scrollbar.rcs-custom-scrollbar-rtl {
  left: 3px;
  right: auto;
}

.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-custom-scrollbar {
  opacity: 1;
}

.rcs-custom-scroll .rcs-custom-scroll-handle {
  position: absolute;
  top: 0;
  width: 100%;
}

.rcs-custom-scroll .rcs-inner-handle {
  background-color: rgba(131, 131, 131, 0.7);
  border-radius: 3px;
  height: calc(100% - 12px);
  margin-top: 6px;
}
