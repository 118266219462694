@import '../../theme/vars.scss'; 

%bgparams {
  background-size: 100%;
  height: 60px;
  margin-left: -12px;
  text-align: center;
}

.preloader {
  animation: appear 0.2s ease-in;
  animation-direction: normal;
  background-color: rgba(255, 255, 255, 0.9);
  bottom: 0;
  left: 0;
  opacity: 0;
  overflow: auto;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease 0.1s;
  @include zIndex(16);

  &_active {
    opacity: 1;
    pointer-events: all;
  }

  &_type {
    &_loading {
      position: absolute;
    }
  }

  &__wrap {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    &_loading {
      flex-direction: row;
    }
  }

  &_loading {
    background: url('/images/preloader/loader.gif') no-repeat center center;
    width: 60px;
    @extend %bgparams;
  }

  &__text {
    &_basket {
      color: $color-black;
      font-size: $font-size-base;
      margin-top: $spacing-default;
    }
  }
}
