@import '../../theme/vars.scss'; 

.icon {
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: visible;
  transform: scale(1);

  &.disabled {
    @include desktopHover {
      cursor: default;
    }
  }
}

.toggler {
  color: $color-gray-300;
}

.togglerActive {
  color: $color-orange;
}

.tiny {
  height: 7px;
  width: 7px;
}

.small {
  height: 11px;
  width: 11px;
}

.default {
  height: 17px;
  width: 17px;
}

.middle {
  height: 20px;
  width: 20px;
}

.big {
  height: 23px;
  width: 23px;
}

.auto {
  height: inherit;
  width: inherit;
}
