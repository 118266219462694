@import '../../theme/vars.scss'; 

.headerDropDown {
  background-color: $color-white;
  border: 1px solid $color-gray-200;
  border-radius: $border-radius-input;
  box-shadow: 0 4px 4px rgba(192, 192, 192, 0.5);
  font-weight: normal;
  position: absolute;
  text-align: left;
  top: 80px;
  @include zIndex(11);
}

.headerDropDownCart {
  background-color: $color-white;
  border: 1px solid $color-gray-200;
  border-radius: $border-radius-input;
  box-shadow: 0 4px 4px rgba(192, 192, 192, 0.5);
  min-width: 377px;
  right: $spacing-tiny;
  top: 66px;
}

.miniBasket {
  cursor: default;
  padding-bottom: $spacing-small;
  padding-top: $spacing-default;
  width: 100%;
}

.miniBasketItems {
  display: table;
  padding: 0 $spacing-default;
}

.miniBasketItem {
  display: table-row;
}

.minBasketImage {
  display: table-cell;
  padding-right: $spacing-tiny;
  vertical-align: top;
  width: 45px;

  &_wrapper {
    display: block;
    width: 38px;
  }
}

.miniBasketName {
  display: table-cell;
  font-size: $font-size-base;
  line-height: $line-height-small;
  padding-bottom: $spacing-default;
  padding-right: $spacing-default;
  width: 189px;

  @include desktopHover {
    color: $link-color-hover;
  }
}

.miniBasketNameLink {
  display: inline-block;
  height: 30px;
  width: 165px;
}

.miniBasketCount {
  display: table-cell;
  font-weight: $font-normal;
  min-width: 50px;
  padding-right: $spacing-small;
  text-align: right;
  vertical-align: top;
  white-space: nowrap;
}

.miniBasketPrice {
  display: table-cell;
  flex: 1;
  font-weight: $font-semibold;
  text-align: right;
  vertical-align: top;
}

.miniBasketActions {
  border-top: 1px solid $color-gray-200;
  display: flex;
  justify-content: space-between;
  padding: $spacing-small $spacing-default 0 $spacing-small;
  white-space: nowrap;
}

.basketLink {
  background-color: $button-primary-bg;
  border-radius: $border-radius-button;
  color: $color-white;
  display: block;
  height: 30px;
  line-height: $line-height-big;
  text-align: center;
  width: 90px;
  @include typo-base();

  @include desktopHover {
    background-color: $button-primary-bg-hover;
    color: $color-white;
  }
}

.iconFavorites {
  display: flex;
  filter: $filter-image-orange;
  height: 18px;
  margin: 0 $spacing-tiny 0 0;
  width: 18px;
}

.iconCompare {
  display: flex;
  filter: $filter-image-orange;
  height: 18px;
  margin: 0 $spacing-tiny;
  width: 18px;
}

.miniBasketFavorites {
  display: flex;
}

.miniBasketCartIcon {
  color: $mini-basket-cart-icon;
  height: 28px;
  margin-right: $spacing-default;
  width: 30px;
}

.moreMiniBasketItems {
  color: $maincolor;
  line-height: $line-height-default;
  padding-left: $spacing-default;

  &Link {
    @include desktopHoverFocusActive {
      color: $maincolorhover;
    }
  }

  &_loyalty .miniBasketCartIcon {
    margin-left: $spacing-tiny;
    margin-right: $spacing-small;
    transform: translateY(-1px);
    width: 19px;
  }
}

.miniBasketLinkBasket {
  align-items: center;
  display: flex;
  height: 40px;
}

.miniBasketEmpty {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 0;
}

.emptyBasketMessage {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
}

.isEmpty {
  height: 40px;
  padding-left: $spacing-default;
}

.dropDownCartEmpty {
  height: 131px;
  width: 277px;
}

.emptyBasketText {
  align-items: center;
  border-bottom: 1px solid $color-gray-200;
  display: flex;
  height: 80px;
  justify-content: center;
  width: 100%;
}

.fixedBasket {
  position: absolute;
  right: 0;
  top: 50px;
}

.loadingBasket {
  height: 70px;
}

.infoPoints {
  align-items: center;
  border-bottom: 1px solid $color-gray-200;
  color: $color-purple;
  display: flex;
  margin: $spacing-small;
  padding-top: $spacing-tiny;
}

.points {
  margin-left: $spacing-tiny;
}

.isPoints {
  color: $color-purple;
}
