@import '../../theme/vars.scss'; 

.error {
  background-color: $infoerrorbackground;
  border: 1px solid $color-red;
  border-radius: $border-radius-input;
  color: $input-error-color;
  font-size: $font-size-base;
  margin-top: $spacing-tiny;
  padding: $spacing-tiny $spacing-small;
}
