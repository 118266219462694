@import '../../theme/vars.scss'; 

.checkbox {
  &__label {
    align-items: center;
    display: flex;
    gap: $spacing-tiny;
    line-height: $line-height-default;
    position: relative;
    @include typo-base($font-normal);

    @include desktopHover {
      color: $color-blue-200;
      cursor: pointer;
    }
  }

  &__field {
    clip-path: inset(0 0 0 0);
    height: 1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 1px;
  }

  &_inactive {
    opacity: 0.5;
  }

  &_disabled
  .checkbox__label {
    color: $color-gray-300;

    @include desktopHover {
      color: $color-gray-300;
      cursor: default;
    }
  }
}
