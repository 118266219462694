@import '../../theme/vars.scss'; 

.menu {
  position: relative;
}

.item {
  font-size: $font-size-base;
  line-height: $line-height-default;
  list-style-type: none;
  position: relative;

  &Inactive {
    display: none;
  }
}

.icon {
  height: 18px;
  margin-right: $spacing-tiny;
  width: 18px;
}

.link {
  align-items: center;
  color: $color-black;
  display: flex;
  justify-content: flex-start;
  padding: $spacing-small 0 $spacing-small $spacing-small;

  @include desktopHover {
    background-color: $color-gray-100;
    color: $link-color-hover;
    cursor: pointer;

    img {
      filter: $filter-image-orange;
    }
  }

  &Loyalty {
    @include desktopHover {
      color: $color-pink;

      img {
        filter: $filter-image-pink;
      }
    }
  }

  &Active {
    background-color: $color-blue-100;
    font-weight: $font-semibold;
  }

  &Inactive {
    color: $color-black;
    display: block;
    margin-bottom: 0;
    opacity: 0.3;
    text-decoration: none;

    @include desktopHover {
      background-color: $color-white;
      cursor: default;
    }
  }
}

.signout {
  background: transparent;
  border: 0;
  text-align: left;
  width: 100%;
}

.hide {
  display: none;
}

.headerContractor {
  cursor: default;
  overflow: hidden;
  padding-left: $spacing-small;
  padding-right: $spacing-small;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.signOut {
  text-decoration: none;

  &_highlighted {
    background: $color-gray-100;
  }
}
