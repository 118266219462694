@import "./breakpoints";
@import "./typography";

// только для десктопа @include mq(_, lg) {...}
// только для планшета @include mq(lg, md) {...}
// для планшета и меньше @include mq(lg) {...}
// для мобилки @include mq(md) {...}
@mixin mq($maxWidth, $minWidth: false) {
  @if map-has-key($breakpoints, $maxWidth) {
    $maxW: map-get($breakpoints, $maxWidth);

    @if ($minWidth != false) and map-has-key($breakpoints, $minWidth) {
      $minW: map-get($breakpoints, $minWidth);

      @media only screen and (max-width: $maxW - 1px) and (min-width: $minW) {
        @content;
      }
    }

    @else {
      @media only screen and (max-width: $maxW - 1px) {
        @content;
      }
    }
  }

  @else {
    @if map-has-key($breakpoints, $minWidth) {
      @media only screen and (min-width: map-get($breakpoints, $minWidth)) {
        @content;
      }
    }
  }
}

// Используем hover на элементах только для desktop
// @include desktopHover {...}
@mixin desktopHover {
  @include mq(_, lg) {
    &:hover {
      @content;
    }
  }
}

// Используем focus на элементах только для desktop
// @include desktopFocus {...}
@mixin desktopFocus {
  @include mq(_, lg) {
    &:focus {
      @content;
    }
  }
}


// Используем hover, focus и active на элементах только для desktop
// @include desktopHoverFocusActive {...}
@mixin desktopHoverFocusActive {
  @include mq(_, lg) {
    &:hover,
    &:focus,
    &:active {
      @content;
    }
  }
}

@mixin typo-h1-lg {
  font-size: $font-size-24px;
  font-weight: $font-semibold;
}

@mixin typo-h1($font-weight: $font-semibold) {
  font-size: $font-size-h1;
  font-weight: $font-weight;
}

@mixin typo-h2($font-weight: $font-semibold) {
  font-size: $font-size-h2;
  font-weight: $font-weight;
}

@mixin typo-base($font-weight: $font-semibold) {
  font-size: $font-size-base;
  font-weight: $font-weight;
}

// добавляет горизонтальный Blur для контейнера
@mixin fadeEffect {
  &__fadeEffect {
    &::after {
      background: linear-gradient(to left, $color-transparent, $color-white);
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 5%;
      @include zIndex(9);
    }

    &::after {
      background: linear-gradient(to right, $color-transparent, $color-white);
      left: auto;
      right: 0;
    }
  }
}
