@import '../../theme/vars.scss'; 

.options {
  display: flex;
  flex-direction: column;
  left: 0;
  margin: $spacing-small;
  position: absolute;
  top: 0;
  @include zIndex(26);

  &Horizontal {
    flex-direction: row;
  }
}

.option {
  align-items: center;
  background-color: $color-blue-100;
  border-radius: $border-radius-button;
  display: flex;
  height: 18px;
  justify-content: center;
  margin-bottom: $spacing-tiny;
  width: 18px;
}

.icon {
  cursor: pointer;
  transition: all 0.3s ease-out;
  width: 12px;

  &Favorite {
    filter: $filter-image-gray;

    @include desktopHover {
      filter:  opacity(70%) $filter-image-red;
    }

    &Active {
      filter: $filter-image-red;
    }
  }

  &Compare {
    filter: $filter-image-gray;

    @include desktopHover {
      filter: opacity(70%) $filter-image-blue;
    }

    &Active {
      filter: $filter-image-blue;
    }
  }

  &Basket {
    cursor: default;
    filter: $filter-image-gray;

    &Active {
      cursor: pointer;
      filter: $filter-image-red;

      @include desktopHover {
        filter: opacity(70%) $filter-image-red;
      }
    }
  }
}
