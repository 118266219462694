@import '../../theme/vars.scss'; 

.rating {
  display: grid;
  position: relative;
  width: fit-content;

  &Line {
    display: flex;
    gap: $spacing-small;

    &Current {
      overflow: hidden;
      position: absolute;
      top: 0;
      width: 0;
    }

    &Star {
      filter: $filter-image-gray;

      &Current {
        filter: $filter-image-yellow;
      }

      &Interactive {
        cursor: pointer;
      }
    }
  }
}
