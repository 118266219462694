@import '../../theme/vars.scss'; 

.OrderStatus {
  display: inline;

  &_PRINYAT &_OBRABOTKA {
    color: $color-orange;
  }

  &_VPUTI {
    color: $color-blue;
  }

  &_DOSTAVLEN &_NABOR {
    color: $color-green;
  }
}

.actionButtons {
  display: flex;
  flex-direction: column;
}

.button {
  background-color: transparent;
  border: unset;
  color: $color-black;
  justify-content: flex-start;
  line-height: $line-height-default;

  @include desktopHover {
    background-color: transparent;
  }

  &_confirm {
    color: $color-blue;

    @include desktopHover {
      color: $color-orange;
    }
  }

  &_delete {
    color: $color-red;

    @include desktopHover {
      color: $color-orange;
    }
  }
}
