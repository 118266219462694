@import '../../theme/vars.scss'; 

.container {
  min-height: 680px;
}

.title {
  color: $color-black;
  display: inline-block;
  line-height: $line-height-default;
  max-width: 100%;
  overflow: hidden;
  padding-bottom: $spacing-tiny;
  padding-left: 1px;
  padding-top: $spacing-default;
  text-overflow: ellipsis;
  white-space: nowrap;
  @include typo-h1();

  @include desktopHover {
    color: $link-color-hover;
  }
}
