@import '../../theme/vars.scss'; 

.container {
  max-width: 890px;
}

.tabs {
  display: block;
  margin-top: $spacing-big;

  li {
    font-size: $font-size-base;
  }

  &_quickView {
    margin-top: $spacing-small;
  }
}

.linkButton {
  display: flex;
  font-size: $font-size-base;
  padding: $spacing-small;
  white-space: nowrap;

  @include desktopHover {
    background-color: $color-gray-100;
    color: $link-color-hover;
    cursor: pointer;
  }

  &First {
    background-color: $color-gray-200;
    pointer-events: none;

    @include desktopHover {
      color: $color-black;
    }
  }

  &Count {
    background-color: $color-blue;
    color: $color-white;
    font-size: $font-size-base;
    margin-right: $spacing-small;
    padding: $spacing-tiny $spacing-small;
  }
}

.propertyTable {
  width: 470px;

  @include mq(lg) {
    width: 100%;
  }

  &Quick {
    margin-top: (-$spacing-tiny);
  }

  & + & {
    margin-left: 70px;
    @include mq(lg) {
      margin-left: 0;
      margin-top: $spacing-large;
    }
  }

  &Params + &Doc {
    margin-top: $spacing-large;
  }
}

.description {
  &Text {
    display: inline-block;
    font-size: $font-size-base;
    margin-bottom: $spacing-default;

    &_quickView {
      margin-bottom: 0;
      padding-left: 0;
    }
  }

  &Properties {
    display: flex;
    width: 100%;

    @include mq(lg) {
      flex-direction: column;
      margin-bottom: 64px;
    }
  }
}

.analogs {
  margin: 0;

  &SliderWrapper {
    margin: 0 (-$spacing-tiny) $spacing-default;
    @include mq(md) {
      @include fadeEffect;
    }
  }
}

.moreInfoLink {
  align-items: center;
  display: flex;
  height: 18px;
  margin-top: $spacing-default;

  @include desktopHover {
    color: $link-color-hover;

    .iconScrollToProperty {
      filter: $filter-image-orange;
    }
  }
}

.iconScrollToProperty {
  filter: $filter-image-blue;
  height: 9px;
  margin: 1px 0 0 $spacing-small;
  width: 6px;
}

iframe {
  height: 400px;
  width: 100%;
}

.sliderWrapper {
  margin: 0 (-$spacing-tiny) 45px;
}

.tabSlider {
  border-bottom: 1px solid $color-gray-200;
  display: flex;
  margin-top: $spacing-big;
  width: 100%;

  :global {
    .slick-arrow {
      top: 50%;
    }

    .slick-prev {
      left: auto;
      right: $spacing-large;
    }
  }
}

.barcode {
  color: $color-blue;
  font-size: $font-size-base;
  margin-top: $spacing-tiny;

  @include desktopHover {
    color: $color-orange;
    cursor: pointer;
  }
}

.mistake {
  align-items: center;
  background-color: $color-blue-100;
  border-radius: 24px;
  display: flex;
  gap: $spacing-tiny;
  margin-top: $spacing-small;
  padding: $spacing-tiny $spacing-small;
}

:has(.mistake) .descriptionText::selection {
  background: rgba(72, 101, 255, .3);
}
