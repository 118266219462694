@import '../../theme/vars.scss'; 

.wrapper {
  background: $color-gray-100;
  bottom: 0;
  color: $color-black;
  height: 500px;
  left: 0;
  position: relative;
  right: 0;
  text-align: center;
  top: 0;
  @include zIndex(27);
}

.header {
  padding-top: 50px;

  h1 {
    font-size: 36px;
    height: 49px;
    line-height: 49px;
  }

  p {
    font-size: $font-size-base;
    height: 17px;
    line-height: $line-height-default;
    margin-top: $spacing-default;
  }
}

.browsers {
  margin-left: (-$spacing-large);
  margin-top: 104px;
  text-align: center;
}

.browser {
  display: inline-block;
  margin-right: $spacing-big;

  &:last-child {
    margin-right: (-$spacing-big);
  }
}

.logo {
  background-image: url('/images/outdatedBrowser/browsersLogo.png');
  background-repeat: no-repeat;
  display: block;
  height: 95px;
  margin-left: $spacing-default;
  width: 65px;
}

.button {
  background: $color-white;
  border-radius: $border-radius-circle;
  box-shadow: 0 2px 2px $color-gray-200;
  display: block;
  height: 95px;
  width: 95px;

  @include desktopHoverFocusActive {
    .logo {
      background-image: url('/images/outdatedBrowser/browsersLogoActive.png');
    }

    .chrome {
      background-position: 4px 15px;
    }

    .firefox {
      background-position: -63px 17px;
    }

    .ie {
      background-position: -132px 15px;
    }

    .safari {
      background-position: -195px 16px;
    }

    .opera {
      background-position: -262px 16px;
    }

    .yandex {
      background-position: -327px 16px;
    }
  }
}

.title {
  color: $color-gray-300;
  font-size: $font-size-base;
  line-height: $line-height-default;
  margin-top: $spacing-default;
}

.copyright {
  bottom: $spacing-default;
  font-size: $font-size-base;
  height: 17px;
  left: 0;
  position: absolute;
  right: 0;
}

.chrome {
  background-position: 5px 18px;
}

.firefox {
  background-position: -61px 20px;
}

.ie {
  background-position: -131px 17px;
}

.safari {
  background-position: -194px 19px;
}

.opera {
  background-position: -261px 18px;
}

.yandex {
  background-position: -325px 18px;
}
