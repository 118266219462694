@import '../../theme/vars.scss'; 

.link {
  align-items: center;
  display: flex;
  text-decoration: none;

  @include desktopHoverFocusActive {
    text-decoration: none;
  }
}

.linkToCatalog {
  align-items: center;
  background-color: $maincolor;
  border-radius: $border-radius-input;
  color: $color-white;
  display: flex;
  font-weight: $font-semibold;
  height: 42px;
  justify-content: flex-start;
  margin: 0;
  padding-left: $spacing-default;
  transition: background-color 0.3s ease;
  white-space: nowrap;
  width: 230px;

  @include desktopHover {
    background-color: $catalog-link-hover;
    color: $color-white;
    cursor: pointer;
  }

  .catalogLinkTitle {
    padding-bottom: 1px;
  }

  &Bookmark {
    background-color: $bookmarkmaincolor;

    @include desktopHover {
      background-color: $bookmarkmaincolorhover;
    }
  }

  &Loyalty {
    background-color: $color-purple;
    border-radius: $border-radius-block;

    @include desktopHover {
      background-color: $color-purple-200;
    }
  }

  @include desktopHoverFocusActive {
    color: $color-white;
  }
}

.catalogIcon {
  display: flex;
  filter: invert(100%);
  height: 10px;
  margin-right: $spacing-big;
  width: 14px;

  &_bookmark {
    height: 17px;
    width: 17px;
  }

  &_loyalty {
    height: 10px;
    width: 10px;
  }
}

.catalogLinkWrapper {
  @include zIndex(12);

  &Absolute {
    position: absolute;
  }

  &Fixed {
    align-items: center;
    display: flex;
    height: 46px;
    justify-content: center;
    margin: 0;
    top: 0;
    @include zIndex(10);
  }
}
