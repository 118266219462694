@import '../../theme/vars.scss'; 

.content {
  color: inherit;
  font-size: $font-size-base;
  line-height: $line-height-default;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $color-black;
    font-weight: $font-semibold;
    line-height: $line-height-default;
    margin-top: $spacing-small;
  }

  h1 {
    font-size: $font-size-24px;
  }

  h2 {
    font-size: $font-size-h1;
  }

  h3 {
    font-size: $font-size-h2;
  }

  h4 {
    font-size: $font-size-h3;
  }

  h5 {
    font-size: $font-size-base;
  }

  h6 {
    font-size: $font-size-base;
  }

  ul ol {
    color: $color-black;
    font-size: $font-size-base;

    line-height: $line-height-default;
    list-style: none;
    margin-bottom: $spacing-default;
    margin-top: 0;
    padding-left: 0;
  }

  li > ul,
  li > ol {
    margin-bottom: 0;
  }

  ul {
    li::before {
      content: '\2022';
      display: inline-block;
      padding-left: 2px;
      padding-right: 7px;
    }
  }

  ol {
    counter-reset: counter;

    /* stylelint-disable-next-line */
    & > li::before {
      content: counter(counter) '.';
      counter-increment: counter;
      display: inline-block;
      padding-left: 2px;
      padding-right: 7px;
    }
  }

  table {
    border-collapse: collapse;
    color: $color-black;
    font-size: $font-size-base;

    line-height: $line-height-default;
    margin: $spacing-default 0;
    table-layout: fixed;
  }

  td {
    background-color: $color-white;
    border: 1px solid $color-gray-300;
    box-sizing: border-box;
    max-width: 258px;
    padding: $spacing-small;
    vertical-align: top;
  }

  i {
    font-style: italic;
  }

  b,
  strong {
    font-weight: bold;
  }

  p {
    color: $color-black;
    font-size: $font-size-base;
    font-style: normal;
    line-height: $line-height-default;
    margin: $spacing-small;
  }

  img {
    border-radius: $border-radius-button;
    display: inline-block;
    height: auto;
    margin-bottom: $spacing-default;
    margin-right: $spacing-small;
    margin-top: $spacing-big;
    max-width: 100%;
    vertical-align: top;
  }

  a {
    color: $maincolor;

    @include desktopHover {
      color: $link-color-hover;
      cursor: pointer;
      text-decoration: none;
    }
  }

  :global {
    .strong {
      font-weight: $font-semibold;

      &_first {
        margin-top: $spacing-small;
      }

      &_dop {
        margin-bottom: $spacing-default;
        margin-top: $spacing-default;
      }
    }

    .text_highlighted {
      color: $color-red;
    }

    .text_last {
      margin-top: $spacing-default;
    }

    .icon {
      align-items: center;
      border-radius: $border-radius-circle;
      display: inline-flex;
      flex-direction: column;
      height: 24px;
      justify-content: center;
      margin-bottom: (-$spacing-tiny);
      margin-right: $spacing-small;
      margin-top: -1px;
      vertical-align: top;
      width: 19px;
    }

    .icon__vip {
      background-color: $color-purple;
      color: $color-white;
      width: 24px;
    }

    .icon__platinum {
      background-color: $color-gray-300;
      color: $color-white;
      width: 24px;
    }

    .icon__gold {
      background-color: $color-yellow;
      color: $color-black;
      width: 24px;
    }

    .icon__silver {
      background-color: $color-gray-200;
      color: $color-black;
    }

    .icon__bronze {
      background-color: $color-red;
      color: $color-white;
      width: 24px;
    }

    .title {
      margin-bottom: $spacing-big;
    }

    .article {
      border: 1px solid $color-gray-200;
      border-radius: $border-radius-block;
      padding-bottom: $spacing-small;
      padding-left: $spacing-default;
      padding-right: $spacing-default;
    }

    .article-conditions {
      border-radius: $border-radius-button;
      margin-bottom: $spacing-default;
      padding-bottom: $spacing-large;

      a {
        color: $color-pink;
        text-decoration: none;

        @include desktopHover {
          color: $color-purple;
          text-decoration: none;
        }
      }

      p {
        line-height: $line-height-default;
        margin-bottom: 0;
        margin-top: 0;
      }

      h5 {
        margin-top: $spacing-default;
      }
    }

    .note {
      background-color: $color-gray-100;
      border: 2px dotted $color-gray-200;
      border-radius: $border-radius-button;
      color: $color-black;
      font-size: $font-size-base;
      font-style: italic;
      line-height: $line-height-default;
      margin-bottom: $spacing-small;
      margin-top: $spacing-small;
      padding: $spacing-small;
    }

    .note.alert {
      background-color: $color-red-100;
      border: 2px dotted $color-red-100;
    }

    .note.success {
      background-color: $color-green-100;
      border: 2px dotted $color-green-hover;
    }

    .table-2-col {
      width: 523px;

      td:first-child {
        width: 267px;
      }
    }

    .table-3-col {
      margin-bottom: 0;
      width: 780px;

      td {
        padding-bottom: $spacing-small;
        padding-top: $spacing-small;
      }

      td:first-child {
        width: 267px;
      }

      tr:nth-child(7) {
        td:nth-child(2) {
          text-align: center;
        }
      }
    }

    .table-4-col {
      margin-bottom: 0;
      width: 1037px;

      td {
        padding-bottom: $spacing-small;
        padding-top: $spacing-small;
      }

      td:first-child {
        width: 267px;
      }
    }

    .table-round {
      margin-top: $spacing-default;
      width: 24px;

      &_inner {
        border-radius: $border-radius-block;
        box-shadow: 0 0 0 1px $color-gray-300;
        display: inline-flex;
        overflow: hidden;

        .table-2-col .table-3-col .table-4-col {
          border-style: hidden;
          margin: 0;
          overflow: hidden;
        }
      }
    }

    .thumbnail-big {
      height: 268px;
      width: 268px;
    }

    .thumbnail-medium {
      height: 150px;
      width: 150px;
    }

    .thumbnail-small {
      height: 70px;
      width: 70px;
    }
  }
}
