@import '../../theme/vars.scss'; 

.price {
  @include typo-h1-lg();
  align-items: flex-start;
  color: $color-black;
  display: flex;
  flex-direction: column;
  margin-top: $spacing-tiny;

  &Block {
    display: block;
    margin-bottom: $spacing-tiny;
  }

  &Real {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &Count {
      color: $color-gray-300;
      font-size: $font-size-base;
    }
  }

  &Red {
    color: $color-red;
  }

  &Purple {
    color: $color-purple;
  }
}

.infoPrice {
  margin-bottom: $spacing-tiny;
  position: relative;

  &Popup {
    left: $spacing-big;
    opacity: 0;
    padding-right: $spacing-tiny;
    position: absolute;
    top: $spacing-small;
    transition: opacity 0.3s 0.2s, visibility 0.3s 0.2s;
    visibility: hidden;
    width: 190px;
  }

  &Value {
    display: inline;
  }
}

.infoPrice{
  @include desktopHoverFocusActive {
    .infoPricePopup {
      opacity: 1;
      visibility: visible;
    }
  }
}

.textDescription {
  color: $color-red;
  cursor: pointer;
  font-size: $font-size-base;
}

.oldPrice {
  font-size: $font-size-base;
  padding-bottom: $spacing-tiny;

  &Value {
    color: $color-black;
    display: inline-block;
    font-size: $font-size-h2;
    position: relative;

    &::after {
      background-color: $color-black;
      content: '';
      height: 1px;
      left: 0;
      position: absolute;
      right: 0;
      top: 50%;
    }
  }

  &Text {
    display: block;

    line-height: $line-height-default;
  }
}

.loyaltyCoeff {
  align-items: center;
  display: flex;
}

.partGroup {
  align-items: center;
  color: $color-black;
  display: flex;
  @include typo-base();
}

.delivery {
  color: $color-green;

  white-space: nowrap;
}

.analogsInfo {
  font-size: $font-size-base;
  padding: $spacing-small 0 0;
  width: 100%;
}

.linkShowAnalogs {
  color: $color-blue-200;

  @include desktopHover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.marketingBlock {
  display: flex;
}

.multiplicity {
  align-items: center;
  background-color: $color-white;
  border: 1px solid $color-gray-200;
  border-radius: $border-radius-input;
  display: flex;
  height: 38px;
  margin-bottom: $spacing-tiny;
  margin-right: $spacing-small;
  margin-top: $spacing-tiny;
  overflow: hidden;
  padding: $spacing-small;
  width: 100%;

  &Value {
    color: $color-black;
    line-height: $line-height-default;
    padding-left: $spacing-tiny;
    @include typo-base();
  }

  &IconContainer {
    @include desktopHoverFocusActive {
      cursor: pointer;
    }
  }
}

.priceRanges {
  align-items: center;
  background-color: $color-white;
  border: 1px solid $color-white;
  border-radius: $border-radius-input;
  margin-bottom: $spacing-small;
  padding: $spacing-small 0;
  width: 100%;

  @include desktopHoverFocusActive {
    cursor: default;
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: $spacing-tiny $spacing-small;

    &_active {
      background-color: $color-gray-100;
    }
  }

  &__count, &__price {
    font-size: $font-size-base;
  }

  &__count {
    &_active {
      color: $color-orange;
    }
  }
}
