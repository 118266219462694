@import '../../theme/vars.scss'; 

$width-button: 323px;

.container {
  display: grid;
  gap: $spacing-small;
  margin: $spacing-default 0;

  @include mq(lg) {
    gap: $spacing-default;
  }

  @include mq(md) {
    margin: $spacing-big 0;
  }
}

.noticeBody {
  align-items: center;
  border: unset;
  border-radius: $border-radius-default;
  display: flex;
  gap: $spacing-small;
  justify-content: space-between;
  margin: 0;
  width: 100%;

  @include mq(lg) {
    flex-wrap: wrap;
    height: unset;
  }

  &__description {
    align-items: center;
    border-radius: $border-radius-input;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: $spacing-default;
    width: 100%;

    @include mq(md) {
      align-items: flex-start;
      flex-direction: column;
      row-gap: $spacing-small;
    }
  }

  &__type {
    &_error {
      background-color: $color-red-100;
    }

    &_warning {
      background-color: $color-yellow-100;
    }

    &_success {
      background-color: $color-green-100;
    }

    &_notice {
      background-color: $color-gray-200;
    }

    &_info {
      background-color: $color-blue-100;
    }

    &_system {
      background-color: $color-gray-200;
    }

    &_loyalty {
      background-color: $color-purple-100;
      border-color: $color-purple-100;
    }
  }

  &__logo {
    align-items: center;
    border-radius: $border-radius-circle;
    display: flex;
    justify-content: center;
    min-height: 22px;
    min-width: 22px;

    &_error {
      background-color: $color-red;
    }

    &_warning {
      background-color: $color-yellow;
    }

    &_success {
      background-color: $color-green;
    }

    &_notice {
      background-color: $color-gray-300;
    }

    &_info {
      background-color: $color-blue;
    }

    &_system {
      background-color: $color-black;
    }

    &_loyalty {
      background-color: $color-purple;
    }
  }

  &__icon {
    filter: invert(1);
    height: 14px;
    width: 14px;

    &_warning {
      filter: invert(0);
    }

    &_loyalty {
      filter: invert(1);
    }
  }

  &__text {
    margin: 0 10px;

    @include mq(md) {
      margin: 0;
    }
  }

  &__buttons {
    max-width: $width-button;
    min-width: $width-button;
    width: $width-button;

    @include mq(lg) {
      max-width: 590px;
      min-width: 288px;
      width: 100%;
    }
  }
}
