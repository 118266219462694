@import '../../theme/vars.scss'; 

.h1__spacing {
  padding: $spacing-small 0;
}

.h2__spacing {
  padding: 0 0 $spacing-default;
}

.h3__spacing {
  padding: 0 0 $spacing-small;
}

.title {
  color: $color-black;
  @include typo-h1();

  &__size {
    &_13 {
      font-size: $font-size-base;
    }

    &_16 {
      font-size: $font-size-h2;
      line-height: $line-height-default;
    }

    &_18 {
      font-size: $font-size-h1;
      line-height: $line-height-default;
    }
  }

  &__spacing {
    &_none {
      padding: 0;
    }

    &_big {
      padding: $spacing-small 0 $spacing-big;
    }
  }
}
