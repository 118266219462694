@import '../../theme/vars.scss'; 

.headerDiscount {
  display: flex;
  width: auto;
}

.menuItem {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 61px;
  justify-content: space-between;
  width: auto;

  &Text {
    color: $color-black;

    &Active {
      color: $header-text-hover-color;
    }
  }

  @include desktopHover {
    cursor: pointer;

    .menuItemText {
      color: $header-text-hover-color;
    }
  }
}

.targetIcon {
  margin: 0 auto;
  position: relative;
}

.completedTargetIcon {
  align-items: center;
  background-color: $color-red-200;
  border-radius: $border-radius-circle;
  color: $color-white;
  display: flex;
  font-weight: $font-semibold;
  height: 38px;
  justify-content: center;
  width: 38px;

  &Active {
    background-color: $color-orange;
  }
}

.counter {
  background-color: $color-yellow-200;
  border-radius: 25px;
  color: $color-black;
  font-weight: $font-bold;
  left: $spacing-big;
  padding: 0 $spacing-tiny;
  position: absolute;
  text-align: center;
  top: -5px;
  width: auto;
  @include zIndex(44);

  &Completed {
    background-color: $color-green;
    color: $color-white;
  }
}
