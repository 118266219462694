@import '../../theme/vars.scss'; 

.input {
  background-color: $color-white;
  border: 1px solid $color-gray-200;
  border-radius: $border-radius-input;
  box-sizing: border-box;
  font-size: $font-size-base;
  height: 30px;
  line-height: $line-height-small;
  outline: none;
  padding: $input-padding $input-padding*2;
  width: 100%;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

  &[type="number"] {
      -moz-appearance: textfield;
  }

  &:hover:not(.input_error):not(.input_success) {
    border-color: $color-gray-300;
  }

  &:disabled {
    border-color: $color-gray-100;
    color: $color-gray-300;
  }

  &::placeholder {
    color: $color-gray-400;
  }

  &_error {
    background-color: $color-red-100;
    border-color: $input-error-color;
    color: $input-error-color;
  }

  &_success {
    border-color: $input-success-color;
  }
}
