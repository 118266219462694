@import '../../theme/vars.scss'; 

.alert {
  &Item {
    font-size: $font-size-base;
    padding: $spacing-default;
  }
}

.notice {
  &Checkout {
    border-radius: $border-radius-default;
    display: flex;
    font-size: $font-size-base;
    min-height: 50px;
    padding-bottom: $spacing-tiny;
    padding-top: $spacing-tiny;

    &basket,
    &loyaltyBasket {
      line-height: $line-height-default;
      width: 935px;

      @include mq(lg) {
        width: 100%;
      }
    }

    &Order {
      width: 100%;
    }
  }

  &Body {
    align-items: stretch;
    border: unset;
    border-radius: $border-radius-default;
    display: flex;
    justify-content: space-between;
    margin: $spacing-tiny 0;
    min-height: 50px;
    width: 100%;
  }

  &Description {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 0 $spacing-small;
  }

  &ERROR {
    background-color: $color-red-100;
  }

  &WARNING {
    background-color: $color-yellow-100;
  }

  &SUCCESS {
    background-color: $color-green-100;
  }

  &NOTICE {
    background-color: $color-gray-100;
  }

  &INFO {
    background-color: $color-blue-100;
  }

  &SYSTEM {
    background-color: $color-white;
    border: 2px solid $color-gray-300;
  }

  &LOYALTY {
    background-color: $color-purple-100;
    border-color: $color-purple-100;
  }

  &Text {
    font-size: $font-size-base;
    padding-left: $spacing-default;
    width: calc(100% - 25px);

    &Checkout {
      font-size: $font-size-base;
      padding-left: $spacing-default;
    }

    strong {
      font-weight: $font-semibold;
    }

    &_withButton {
      width: 820px;
    }
  }

  &Logo {
    align-items: center;
    border-radius: $border-radius-circle;
    display: flex;
    height: 23px;
    justify-content: center;
    min-width: 23px;

    &ERROR {
      background-color: $color-red;
    }

    &WARNING {
      background-color: $color-yellow;
    }

    &SUCCESS {
      background-color: $color-green;
    }

    &NOTICE {
      background-color: $color-gray-300;
    }

    &INFO {
      background-color: $color-blue;
    }

    &SYSTEM {
      background-color: $color-black;
    }
  }

  &Icon {
    filter: invert(1);

    &WARNING {
      filter: invert(0);
    }

    &LOYALTY {
      filter: invert(0);
    }
  }

  &Button {
    align-items: center;
    border: unset;
    border-radius: $border-radius-default;
    color: $color-white;
    display: flex;
    justify-content: center;
    width: 323px;
    @include typo-base();

    @include desktopHover {
      cursor: pointer;
    }

    &ERROR {
      background-color: $color-blue-200;

      &_delete {
        background-color: $color-red;
      }
    }

    &WARNING {
      background-color: $color-yellow;
      color: $color-black;
    }

    &SUCCESS {
      background-color: $color-green;
    }

    &NOTICE {
      background-color: $color-blue;
    }

    &INFO {
      background-color: $color-blue;
    }

    &SYSTEM {
      background-color: $color-black;
    }

    &LOYALTY {
      background-color: $color-blue;
    }
  }
}

.top {
  width: 100%;
}

.table {
  font-size: $font-size-base;

  &Head {
    border-bottom: 1px solid $color-gray-200;
    font-weight: $font-semibold;

    @include mq(lg) {
      display: none;
    }
  }

  &Body {
    border-bottom: 1px solid $color-gray-200;
  }

  &Row {
    display: grid;
    grid-template-columns: 10% 40% 50%;
    padding: $spacing-small 0;

    @include mq(lg) {
      display: flex;
      flex-direction: column;
      padding-bottom: $spacing-default;
    }
  }

  &__column {
    @include mq(lg) {
      padding-bottom: $spacing-default;
    }
  }
}

.messageError {
  color: $color-red;
}

.confirm {
  &Content {
    &Hidden {
      opacity: 0;
    }
  }

  &Title {
    padding-bottom: $spacing-large;
    text-align: center;
  }

  &Text {
    font-size: $font-size-base;
    padding-bottom: $spacing-large;
    text-align: center;

    a {
      color: $maincolor;
    }

    b {
      font-weight: $font-semibold;
    }
  }

  &Button {
    margin: 0 $spacing-small;
    padding: $spacing-small;

    &Yes {
      background-color: $color-green;
      color: $color-white;
    }

    &No {
      background-color: $color-white;
      color: $maincolor;
    }
  }

  &Control {
    display: grid;
    gap: $spacing-default;
    grid-template-columns: 3fr 3fr;

  }
}


.column {
  &__name {
    display: none;

    @include mq(lg){
      color: $color-gray-300;
      display: block;
    }
  }

  &__value {
    @include mq(lg){
      padding-bottom: $spacing-default;
    }
  }
}
