$zIndex: (
  // формат (вдруг пригодится)
  // уровень: значение, // старое значение z-index
  1: 440, // 100000,
  2: 430, // 22100,
  3: 420, // 22000,
  4: 410, // 21100,
  5: 400, // 21000,
  6: 390, // 20999,
  7: 380, // 20900,
  8: 370, // 20000,
  9: 360, // 18500,
  10: 350, // 18000,
  11: 340, // 17600,
  12: 330, // 17500,
  13: 320, // 17000,
  14: 310, // 16000,
  15: 300, // 16500,
  16: 290, // 15000,
  17: 280, // 12400,
  18: 270, // 12300,
  19: 260, // 12200,
  20: 250, // 12100,
  21: 240, // 12000,
  22: 230, // 11000,
  23: 220, // 10500,
  24: 210, // 10400,
  25: 200, // 10100,
  26: 190, // 10000,
  27: 180, // 9999,
  28: 170, // 9900,
  29: 160, // 2000,
  30: 150, // 1000,
  31: 140, // 600,
  32: 130, // 200,
  33: 120, // 100,
  34: 110, // 50,
  35: 100, // 22,
  36: 90, // 21,
  37: 80, // 20,
  38: 70, // 10,
  39: 60, // 9,
  40: 50, // 8,
  41: 40, // 5,
  42: 30, // 3,
  43: 20, // 2,
  44: 10, // 1,
  45: 0, // 0,
  46: -10 // -1
);


@mixin zIndex($key) {
  z-index: map-get($zIndex, $key);
}
