@import '../../theme/vars.scss'; 

.text {
  color: $color-black;
  display: inline-block;
  line-height: $line-height-default;
  margin: 0;
  pointer-events: inherit;
  text-align: inherit;
  user-select: inherit;
  white-space: inherit;
  @include typo-base($font-normal);

  &__block {
    display: block;
  }

  &__color {
    &_inherit {
      color: inherit;
    }

    &_gray {
      color: $color-gray-300;
    }

    &_error {
      color: $color-red;
    }

    &_white {
      color: $color-white;
    }

    &_purple {
      color: $color-purple;
    }

    &_red {
      color: $color-red;
    }

    &_green {
      color: $color-green;
    }

    &_orange {
      color: $color-orange;
    }

    &_blue {
      color: $color-blue;
    }

    &_pink {
      color: $color-pink;
    }
  }

  &__underline {
    text-decoration: underline;
  }

  &__lineThrough {
    text-decoration: line-through;
  }

  &__semibold {
    font-weight: $font-semibold;
  }

  &__bold {
    font-weight: $font-bold;
  }
}
