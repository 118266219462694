@import '../../theme/vars.scss'; 

.space {
  height: $tab-bar-height;
}

.container {
  background-color: $color-white;
  bottom: 0;
  box-shadow: 0 -1px 14px rgba(0, 0, 0, 0.13);
  display: flex;
  height: $tab-bar-height;
  position: fixed;
  width: 100%;
  @include zIndex(6);
}

.tabBar {
  display: none;

  @include mq(lg) {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: $tab-bar-height;
    justify-content: space-around;
    list-style: none;
    margin: 0 auto;
    max-width: 590px;
    width: 100%;
  }

  &__item {
    align-items: center;
    background: none;
    border: unset;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    justify-content: center;
    padding: $spacing-small;

    &_active {
      color: $color-blue;
    }

    &_catalogLoyalty {
      background-color: $color-purple;
      border-radius: $border-radius-block;
      color: $color-white;
      flex-direction: row;
      gap: $spacing-tiny;
      padding: $spacing-small;
      @include typo-base();
    }

    &Icon {
      filter: $filter-image-gray-300;

      &_active {
        filter: $filter-image-blue;
      }

      &_catalogRc {
        filter: none;
      }

      &_catalogLoyalty {
        filter: $filter-image-white;
      }
    }
  }
}

.content {
  background-color: $color-white;
  bottom: 0;
  height: calc(100vh - $tab-bar-height);
  left: 0;
  overflow: auto;
  padding: $spacing-default $spacing-default 0;
  position: fixed;
  top: 0;
  width: 100%;
  @include zIndex(8);
}

%bottom-border {
  border-bottom: 1px solid $color-gray-200;
  padding-bottom: $spacing-default;
}

.settings {
  &__content {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
    font-size: $font-size-base;
    gap: $spacing-big;
    @extend %bottom-border;

    @include mq(md) {
      justify-content: space-between;
    }
  }

  &__catalog {
    margin-top: $spacing-big;
    @extend %bottom-border;
  }

  &__about {
    margin-top: $spacing-default;
    @extend %bottom-border;
  }

  &__faq {
    margin-top: $spacing-default;
    @extend %bottom-border;
  }

  &__list {
    display: grid;
    gap: $spacing-small;
  }

  &__link {
    height: 18px;
  }

  &__head {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__icon_rotate {
    rotate: 180deg;
  }

  &__contacts {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-small;
    justify-content: space-between;
    margin-top: $spacing-default;
  }
}

.loyalty {
  &Options {
    display: flex;
    flex-direction: column;
    gap: $spacing-large;
    height: 100%;
    justify-content: flex-end;
    padding-bottom: $spacing-large;

    &__list {
      display: flex;
      flex-direction: column;
      gap: $spacing-default;
    }

    &__info {
      background-color: $color-gray-100;
      border-radius: $border-radius-input;
      display: flex;
      flex-direction: column;
      gap: $spacing-small;
      padding: $spacing-default;
    }
  }

  &Option {
    align-items: center;
    display: flex;
    gap: $spacing-small;

    &__icon {
      &_flame {
        filter: $filter-image-red;
      }
    }

    &__iconWrapper {
      align-items: center;
      background-color: $color-yellow;
      border-radius: 50%;
      display: flex;
      height: 27px;
      justify-content: center;
      width: 27px;
    }

    &__textBlock{
      display: flex;
      flex-direction: column;
    }
  }

  &Catalog {
    border: 1px solid $color-gray-200;
    border-radius: $border-radius-block;

    &__item {
      border-bottom: 1px solid $color-gray-200;
      padding: $spacing-default;

      &:last-of-type {
        border: unset;
      }
    }
  }
}

