@import '../../theme/vars.scss'; 

.copy {
  position: relative;
}

.hint {
  background-color: rgba(5, 9, 20, 0.7);
  border-radius: 8px;
  color: $color-white;
  font-size: $font-size-base;
  left: 0;
  padding: $spacing-small $spacing-large;
  position: absolute;
  top: $spacing-default;
  white-space: nowrap;
  @include zIndex(4);

  @include mq(md) {
    bottom: calc($tab-bar-height + $spacing-big);
    min-width: 100vw;
    position: fixed;
    text-align: center;
    top: auto;
  }
}

.error {
  background-color: $color-red;
}
