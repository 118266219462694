@import '../../theme/vars.scss'; 

.popup {
  animation: appear 0.2s ease-in;
  animation-direction: normal;
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  @include zIndex(8);

  &.withoutOverlay {
    background-color: transparent;

    .imageWrapper {
      box-shadow: 0 2px 6px 1px rgba(117, 117, 117, 1);
    }
  }
}

.popupCloseIcon {
  cursor: pointer;
  position: absolute;
  right: $spacing-small;
  top: $spacing-small;
}

.previewWrapper {
  align-items: center;
  justify-content: center;
}

.previewImage {
  height: auto;
  max-height: 560px;
  max-width: 560px;
  width: auto;
}

.imageWrapper {
  align-items: center;
  background: $color-white;
  cursor: zoom-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
