@import '../../theme/vars.scss'; 

.socket {
  align-items: center;
  background-color: $color-red-100;
  border-radius: 0 10px 10px 0;
  display: none;
  justify-content: space-between;
  left: 0;
  position: fixed;
  top: 50vh;
  width: 170px;

  &Active {
    background-color: $color-green-100;
  }
}

.button {
  background-color: rgba(0, 0, 0, 0.2);
  border: unset;
  border-radius: $border-radius-block;
  color: $color-black;
  font-size: $font-size-base;
  margin: $spacing-tiny $spacing-small;
  padding: $spacing-tiny $spacing-large;
  text-align: center;

  @include desktopHover {
    color: $color-black;
    cursor: pointer;
  }
}

.event {
  border-bottom: 1px solid $color-black;
  font-size: $font-size-base;
  padding: $spacing-small 0;
}
