@import '../../theme/vars.scss'; 

.root {
  color: $color-black;
  cursor: pointer;
  font-size: $font-size-base;
  position: relative;
  width: 100%;

  &__content {
    align-items: center;
    display: flex;
    gap: $spacing-tiny;
    justify-content: space-between;
    outline: none;

    &_color_transparent {
      font-weight: $font-semibold;
      justify-content: flex-start;

      @include desktopHoverFocusActive {
        color: $color-orange;
      }
    }

    &_color_white {
      background-color: $color-white;
      border: 1px solid $color-gray-200;
      border-radius: $border-radius-input;
      padding: 6px 12px;

      @include desktopHoverFocusActive {
        border-color: $color-gray-300;
      }
    }

    &_color_blue {
      background-color: $color-blue;
      border-radius: $border-radius-input;
      color: $color-white;
      padding: 6px 12px;
      text-transform: uppercase;

      @include desktopHoverFocusActive {
        background-color: $color-blue-200;
      }
    }

    .root &_disabled {
      background-color: $color-white;
      border-color: $color-gray-100;
      color: $color-gray-200;
      cursor: not-allowed;

      @include desktopHoverFocusActive {
        background-color: $color-white;
        border-color: $color-gray-100;
        color: $color-gray-200;
        cursor: not-allowed;
      }
    }
  }

  &__text {
    min-width: 0;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
  }

  &__icon {
    transition: transform 0.3s;

    &_open {
      transform: rotate(180deg);
    }
  }

  &:has(.root__content_color_blue) {
    max-width: 150px;
    min-width: 130px;

    @include mq(md) {
      max-width: 100%;
    }
  }

  &:has(.root__content_color_blue) &__list {
    max-width: 150px;
    min-width: 130px;
  }

  &:has(.root__content_color_blue) &__icon {
    filter: invert(1);
  }

  &:has(.root__content_color_transparent:hover,
    .root__content_color_transparent:focus) &__icon {
    filter: $filter-image-orange;
  }

  &:has(.root__content_disabled,
    .root__content_disabled:hover,
    .root__content_disabled:focus) &__icon {
    filter: $filter-image-white;
  }

  &__list {
    background-color: $color-white;
    border: 1px solid $color-gray-200;
    border-radius: $border-radius-input;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    list-style: none;
    margin: $spacing-tiny 0;
    max-height: 270px;
    max-width: 310px;
    min-width: 190px;
    overflow-y: auto;
    padding: 6px 0;
    position: absolute;
    scroll-behavior: smooth;
    @include zIndex(16);

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-gray-300;
      border-radius: 3px;
    }
  }
}


.list__item {
  padding: 6px 12px;

  @include desktopHoverFocusActive {
    background-color: $color-gray-100;
    color: $color-orange;
  }

  &_focused {
    background-color: $color-gray-100;
    color: $color-orange;
  }

  &_selected {
    color: $color-gray-300;
  }
}

.wrapper {
  background: rgba(0, 0, 0, .6);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  @include zIndex(5);
}

.mobileList {
  background: $color-white;
  border-radius: 6px 6px 0 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  font-size: $font-size-base;
  height: fit-content;
  max-height: 70vh;
  overflow: auto;
  padding: $spacing-default;
  position: fixed;
  width: 100%;

  &__item {
    border-bottom: 1px solid $color-gray-200;
    padding: $spacing-small 0;

    &_selected {
      background-color: $color-gray-100;
      color: $color-orange;
    }
  }
}
