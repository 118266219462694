@import '../../theme/vars.scss'; 

.logo {
  align-items: center;
  background-color: $logo-bg-color;
  border: $logo-border;
  border-radius: $border-radius-input;
  display: inline-flex;
  height: 136px;
  justify-content: center;
  margin-bottom: $spacing-small;
  width: 230px;

  @include mq(lg, md) {
    height: 120px;
    margin-bottom: 0;
    width: 207px;
  }

  @include mq(md) {
    height: 50px;
    margin-bottom: 0;
    width: 86px;
  }

  &Icon {
    color: $logo-icon-color;
    height: $logo-icon-height;
    width: $logo-icon-width;

    @include mq(lg, md) {
      width: 114px;
    }

    @include mq(md) {
      width: 56px;
    }

    &Loyalty {
      color: transparent;
      height: 58px;
      width: 173px;

      @include mq(lg, md) {
        width: 130px;
      }

      @include mq(md) {
        width: 56px;
      }
    }
  }

  &Loyalty {
    background-color: transparent;
    border: 4px solid $color-purple;
    border-radius: $border-radius-block;
  }
}
