@import '../../theme/vars.scss'; 

.filter {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-default;
  margin-top: $spacing-big;

  &Item {
    align-items: center;
    display: flex;
    gap: $spacing-tiny;
  }
}

.select {
  color: $color-black;
  display: inline-block;
  line-height: $line-height-default;
  width: auto;

  &Name {
    display: inline-block;
    font-size: $font-size-base;
    margin-right: $spacing-tiny;
  }
}

.review {
  display: flex;
  justify-content: space-between;
  margin-top: $spacing-default;

  &List {
    display: grid;
    gap: $spacing-default;

    &Item {
      background-color: $color-gray-100;
      border-radius: $border-radius-button;
      padding: $spacing-default;
      width: 817px;

      &Head {
        align-items: center;
        border-bottom: 1px solid $color-gray-200;
        display: flex;
        justify-content: space-between;
        padding-bottom: $spacing-default;

        &Info {
          align-items: center;
          display: flex;
          gap: $spacing-default;
          justify-content: space-between;
          margin-left: auto;
        }
      }

      &Body {
        display: flex;
        flex-direction: column;
        gap: $spacing-default;
        margin-top: $spacing-default;

        &Content {
          background-color: $color-white;
          border-radius: $border-radius-button;
          display: grid;
          gap: $spacing-small;
          padding: $spacing-small;
          width: 553px;

          &:nth-of-type(even) {
            align-self: flex-end;
            width: 528px;
          }
        }
      }
    }
  }
}

.reviewListItemBodyContentText {
  border-bottom: 1px solid $color-gray-200;
  display: grid;
  gap: $spacing-small;
  padding-bottom: $spacing-small;

  &:last-of-type {
    border: unset;
  }

  &Response {
    font-size: $font-size-base;
  }

  &Title {
    align-items: center;
    border-bottom: 1px solid $color-gray-100;
    display: flex;
    justify-content: space-between;
    padding-bottom: $spacing-small;

    &Date {
      display: flex;
      gap: $spacing-tiny;
    }
  }
}

.button {
  align-self: flex-end;
  background-color: $color-blue;
  border: unset;
  border-radius: $border-radius-block;
  color: $color-white;
  font-size: $font-size-base;
  justify-self: end;
  margin: $spacing-small 0;
  padding: $spacing-tiny $spacing-small;
}

.statistics {
  width: 289px;

  &Block {
    background-color: $color-gray-100;
    display: grid;
    padding-bottom: $spacing-tiny;

    &Head {
      border-bottom: 1px solid $color-gray-200;
      display: flex;
      justify-content: space-between;
      padding: $spacing-default;
    }

    &Container {
      display: grid;
      gap: $spacing-tiny;
      margin: $spacing-default;

      &Item {
        align-items: center;
        border-bottom: 1px solid $color-gray-200;
        display: grid;
        gap: $spacing-small;
        grid-template-columns: 3fr 137px 1fr;
        padding-bottom: $spacing-tiny;

        &:last-of-type {
          border: unset;
        }

        &Progress {
          background-color: $color-gray-200;
          border-radius: 18px;
          height: 4px;
          width: 137px;
        }
      }
    }
  }
}
