@import '../../theme/vars.scss'; 

.container {
  background-color: $color-white;
  border-radius: $border-radius-button;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  padding-left: $spacing-small;
}

.tableHead {
  color: $color-gray-300;
}

.headerCell {
  align-items: center;
  display: inline-flex;
  height: 30px;

  &:first-child {
    width: 110px;
  }

  &:nth-child(2) {
    width: 200px;
  }

  &:nth-child(3) {
    width: 65px;
  }
}

.innerCellWrapper {
  overflow: hidden;
  position: relative;
}

.cell {
  border-top: 1px solid $color-gray-100;
  height: 24px;
  padding-right: $spacing-tiny;
  white-space: nowrap;

  &:first-child {
    .innerCellWrapper {
      width: 110px;
    }
  }

  &:nth-child(2) {
    .innerCellWrapper {
      width: 200px;
    }
  }

  &:nth-child(3) {
    .innerCellWrapper {
      width: 65px;
    }
  }
}

.alpha {
  background: linear-gradient(to right,
  transparent 0%,
  transparent 80%,
  $color-white 94%,
  $color-white 100%);
  height: 24px;
  position: absolute;
  right: -1px;
  top: 0;
  width: 100%;
}

.tableBody {
  color: $color-black;
  display: block;
  line-height: $line-height-default;
  max-height: 24px * 7;
  overflow-y: auto;
  width: 100%;
}

.tableRow {
  display: block;
}

.table .tableHeadRow {
  overflow: auto;
}
