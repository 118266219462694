@import '../../theme/vars.scss'; 

.autocomplete {
  display: block;
  position: relative;
  text-align: left;
  width: 100%;
}

.list {
  background: $color-white;
  border: 1px solid $color-gray-200;
  border-radius: $border-radius-input;
  left: 0;
  position: absolute;
  right: 0;
  top: 100%;
  @include zIndex(38);
}

.item {
  border-bottom: 1px solid $color-gray-200;
  cursor: pointer;
  display: block;
  font-size: $font-size-base;
  padding: $spacing-tiny $spacing-small;

  &:last-child {
    border-bottom: unset;
  }

  &_selected {
    background: $color-gray-200;
  }

  @include desktopHover {
    background: $color-gray-200;
  }
}
