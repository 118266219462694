@import '../../theme/vars.scss'; 

.menu {
  align-items: center;
  background-color: $color-gray-100;
  border-radius: $border-radius-block;
  display: flex;
  font-size: $font-size-base;
  height: 44px;
  margin-left: $spacing-small;
  margin-top: $spacing-small;
  padding: 0 $spacing-default;
  width: 950px;
}

.link {
  @include desktopHover {
    color: $color-purple;
  }

  &Important {
    color: $color-pink;

    @include desktopHover {
      color: $color-purple;
    }
  }

  &Active {
    color: $color-gray-200;
    text-decoration: none;
  }
}
