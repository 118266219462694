@import '../../theme/vars.scss'; 

.cabinet {
  color: $color-black;
  margin: 0;
  padding: 0;
  width: 100%;

  &__contractor {
    margin-bottom: $spacing-default;
    width: 100%;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include mq(lg, md) {
      width: 50%;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;

    @include mq(lg, md) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

.cabinetBlock {
  background-color: $color-white;
  border-radius: $border-radius-default;
  color: $color-black;
  margin: 0 0 $spacing-small;
  overflow: hidden;
  padding: 0;
  width: 100%;

}

.bottomMenu {
  border-top: 1px solid $color-gray-200;
  display: flex;
  flex-direction: column;
  padding-top: $spacing-small;


  &Item {
    display: flex;
    width: 100%;

    &Counter {
      padding-left: $spacing-tiny;
    }
  }

  &Link {
    background-color: $color-blue-100;
    border-radius: $border-radius-block;
    display: flex;
    line-height: $line-height-default;
    margin: $spacing-tiny 0;
    padding: $spacing-small;
    width: 100%;
  }

  &Icon {
    height: 18px;
    margin-right: $spacing-tiny;
    width: 18px;

    &Blue {
      filter: $filter-image-blue;
    }

    &Red {
      filter: $filter-image-red;
    }
  }
}
