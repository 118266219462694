@import '../../theme/vars.scss'; 

.wrapper {
  position: relative;
}

.download {
  position: relative;

  &Icon {
    display: flex;
    filter: $filter-image-blue;
    height: 12px;
    margin-right: $spacing-tiny;
    position: relative;
    top: 2px;
    vertical-align: baseline;
    width: 14px;

    &_round {
      margin-right: 0;
      top: 0;
    }
  }

  &Link {
    align-items: center;
    background-color: transparent;
    border: unset;
    color: $color-blue-200;
    display: flex;

    @include desktopHover {
      color: $link-color-hover;

      .downloadIcon {
        filter: $filter-image-white;
      }
    }

    &_round {
      background-color: $color-white;
      border-radius: $border-radius-circle;
      height: 27px;
      justify-content: center;
      width: 27px;

      @include desktopHover {
        background-color: $color-blue;
      }
    }
  }

  &_mod_OrderOne {
    .dropdown {
      left: (-$spacing-small);
    }
  }

  &_mod_OrderTable {
    .dropdown {
      right: 0;
    }
  }
}

.dropdown {
  background-color: $color-white;
  border-radius: $border-radius-button;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-size: $font-size-base;
  padding: $spacing-tiny 0;
  position: absolute;
  width: 215px;
  @include zIndex(38);

  @include mq(md) {
    bottom: 0;
    position: fixed;
    width: 100%;
  }

  &Item {
    align-items: center;
    display: flex;
    height: 28px;

    &:not(&_disabled) {
      cursor: pointer;

      @include desktopHover {
        background-color: $color-gray-100;
        color: $link-color-hover;

        .dropdownIcon {
          filter: $filter-image-orange;
        }
      }
    }

    &_disabled {
      background-color: $color-gray-100;
      color: $color-gray-300;
      cursor: default;

      .dropdownIcon {
        filter: $filter-image-gray;
      }
    }
  }

  &Icon {
    height: 12px;
    margin-left: $spacing-small;
    margin-right: $spacing-tiny;
    width: 10px;
  }
}
