@import '../../theme/vars.scss'; 

$direction: ltr !default;
$border-radius: 2px !default;
$cols: 12 !default;
$cols-remove-odd: true !default;
$gutter: 10px !default;
$spacer: 10px !default;
$avatar-border-radius: 50% !default;
$animation-duration: 0.8s !default;

.item {
  &,
  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  background-color: $color-white;
  border-radius: $border-radius;
  direction: $direction;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $gutter;
  overflow: hidden;
  padding: $gutter ($gutter * 0.5) ($gutter - $spacer) ($gutter * 0.5);
  position: relative;

  &::before {
    animation: placeholderAnimation $animation-duration linear infinite;
    background: linear-gradient(to right,
    rgba($color-white, 0) 46%,
    rgba($color-white, 0.35) 50%,
    rgba($color-white, 0) 54%)
      50% 50%;
    bottom: 0;
    content: ' ';
    left: 50%;
    margin-left: -250%;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 500%;
    @include zIndex(44);
  }

  > * {
    display: flex;
    flex: 1 1 auto;
    flex-flow: column;
    margin-bottom: $spacer;
    padding-left: ($gutter * 0.5);
    padding-right: ($gutter * 0.5);
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-top: -($spacer * 0.5);

  div {
    background-color: $color-gray-100;
    height: 10px;
    margin-top: ($spacer * 0.5);
  }

  .big,
  &.big div {
    height: 20px;
  }

  .empty {
    background-color: rgba($color-white, 0);
  }
}

.col {
  direction: $direction;

  > * {
    + .row {
      margin-top: 0;
    }

    + * {
      margin-top: ($spacer * 0.5);
    }
  }
}

.avatar {
  background-color: $color-gray-100;
  border-radius: $avatar-border-radius;
  min-width: 60px;
  overflow: hidden;
  position: relative;
  width: 100%;

  &::before {
    content: ' ';
    display: block;
    padding-top: 100%;
  }
}

.picture {
  background-color: $color-gray-100;
  height: 120px;
  width: 100%;
}

@keyframes placeholderAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }

  100% {
    transform: translate3d(30%, 0, 0);
  }
}
