@import '../../theme/vars.scss'; 

.mainColumn {
  background-color: $color-white;
  color: $color-black;
  font-size: $font-size-base;
  height: 100%;
  max-height: 100%;
  overflow: visible;
  padding: $spacing-small 0 0;
  pointer-events: all;
  width: 288px;

  &Mobile {
    padding: 0;
    width: 100%;
  }
}

.list,
.favoriteList {
  list-style: none;
  padding: 0;
  width: 100%;
}

.favoriteList {
  margin-bottom: $spacing-small;
}

.item {
  background-color: $color-white;
  cursor: pointer;
  line-height: $line-height-default;
  transition: 100ms;
}

.title {
  color: $color-black;
  display: flex;
  font-weight: $font-normal;
  letter-spacing: 0.3px;
  line-height: $line-height-default;
  padding: $spacing-small 0 $spacing-small $spacing-default;
  position: relative;

  &Name {
    width: 214px;
  }

  &_active {
    background: $color-gray-100;
    border-radius: inherit;

    @include desktopHover {
      border-radius: inherit;
      color: $link-menu-catalog-color-hover;
    }
  }

  &Image {
    filter: $filter-image-blue;
    max-height: 24px;
    max-width: 24px;

    &Wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-right: $spacing-small;
      width: 24px;
    }
  }
}

.triangle {
  position: absolute;
  right: (-$spacing-small);
  top: 1px;
  transform: rotate(90deg);
}

.closeButton {
  align-items: center;
  background-color: $maincolor;
  color: $color-white;
  display: flex;
  line-height: $line-height-default;
  margin: 0 0 $spacing-small;
  padding: $spacing-default;
  width: 270px;

  @include desktopHover {
    color: $color-white;
    cursor: pointer;
  }

  &_bookmark {
    background-color: $color-orange;
  }

  &_icon {
    filter: invert(100%);
    height: 18px;
    margin-right: $spacing-default;
    width: 18px;
  }

  &_text {
    line-height: $line-height-default;
    @include typo-base($font-bold);
  }
}

.menuBlock {
  display: flex;
  margin-bottom: $spacing-tiny;
}

.menuText {
  align-items: center;
  color: $color-black;
  display: flex;
  font-size: $font-size-base;
  line-height: $line-height-default;
  text-decoration: none;

  @include desktopHover {
    color: $link-color-hover;
    text-decoration: none;
  }

  &Bookmark {
    display: flex;
    line-height: $line-height-default;
  }
}

.menuIcon {
  align-items: center;
  color: $left-column-title-icon-color;
  display: flex;
  height: 24px;
  justify-content: flex-start;
  margin-right: $spacing-small;
  width: 24px;

  &_bookmark {
    filter: invert(100%);
  }

  &_brand {
    height: 24px;
    width: 24px;
  }
}

.brandsBlock {
  background-color: $color-blue-100;
  display: flex;
  line-height: $line-height-default;
  padding: $spacing-small $spacing-default;
  width: 100%;
}

.bookmarkBlockTitle {
  background-color: $color-orange;
  color: $color-white;
  display: flex;
  font-size: $font-size-base;
  line-height: $line-height-default;
  padding: $spacing-small $spacing-default;
  position: relative;
  width: 100%;

  @include desktopHover {
    background-color: $color-orange-hover;
    color: $color-white;
    cursor: pointer;
  }
}

.catalog {
  &Block {
    border-bottom: unset;
    display: flex;
    flex-direction: column;
    height: 50px;
    justify-content: center;
    padding: 0 $spacing-default;
    width: 100%;
  }

  &Text {
    color: $color-black;
    line-height: $line-height-big;
    @include typo-base($font-normal);

    @include desktopHover {
      text-decoration: none;
    }
  }
}

.scrolled {
  display: flex;
  height: calc(100vh - 80px);
}

.customScroll {
  width: 100%;
}
