@import './colors';



$maincolor: $color-blue;
$maincolorhover: $color-blue-200;
$secondcolor: $color-orange;
$submitcolor: $color-orange;
$switchon: $color-green;
$switchoff: $color-orange;
$infoerrorbackground: $color-red-100;
$infoerroriconbackground: $color-red;
$colorpointsvalue: $color-orange;
$colorpointsvalueprice: $color-orange;
$bookmarkmaincolor: $color-orange;
$bookmarkmaincolorhover: $color-orange-hover;
$bookmarkmaincolorfooter: linear-gradient(180deg, $color-orange 0%, $color-orange 100%);
$link-color: $color-black;
$link-color-hover: $color-orange;
$link-menu-catalog-color-hover: $color-blue-200;
$link-reset-all: $color-orange;
$button-primary-bg: $color-yellow-200;
$button-primary-bg-hover: $color-yellow-hover;
$button-secondary-bg: $color-blue-200;
$button-secondary-bg-hover: $color-blue-hover;
$button-secondary-color: $color-white;
$button-wait-bg: $color-blue;
$button-wait-bg-hover: $color-blue-hover;
$button-disabled-bg: $color-gray-200;
$button-disabled-color: $color-white;
$button-success-color: $color-green;
$button-success-hover-color: $color-green-hover;
$submit-color: $color-yellow;
$submit-hover-color: $color-yellow-hover;
$submit-text-color: $color-black;
$order-comment-background-color: $color-blue-100;
$popup-padding: 8px;
$icon-favorite-color: $color-orange;
$icon-compare-color: $color-orange;
$input-padding: 6px;
$input-error-color: $color-red;
$input-success-color: $color-green;
$input-error-arrow-display: block;
$logo-margin-bottom: 43px;
$logo-height: 134px;
$logo-border: unset;
$logo-bg-color: $color-gray-100;
$logo-icon-width: 150px;
$logo-icon-height: auto;
$logo-icon-color: $color-transparent;
$catalog-link-hover: $color-blue-200;
$mini-basket-cart-icon: $color-blue-200;
$range-progress-bg: $color-yellow;
$range-arrow-bg: $color-blue;
$range-arrow-bg-hover: $color-blue-hover;
$filters-link-color: $color-blue;
$filters-link-color-hover: $color-orange;
$header-search-line-icon-color: $color-orange;
$marks-menu-position: absolute;
$marks-menu-bg: $color-gray-100;
$marks-menu-border: $color-transparent;
$menu-catalog-block-top-height: 32px;
$menu-catalog-icon-color: $color-black;
$menu-catalog-item-active-bg: $color-gray-100;
$menu-catalog-item-active-color: $color-blue;
$product-border: $color-gray-200;
$product-border-hover: $color-blue-200;
$product-card-bottom-buttons-height: 28px;
$product-code-bg: $color-transparent;
$product-code-border: $color-yellow;
$product-code-color: $color-black;
$product-vendor-code-border: $color-blue;
$product-vendor-code-color: $color-black;
$product-actions-tabs-margin: 6px;
$product-actions-push-padding-top: 12px;
$product-actions-push-padding-bottom: 0;
$product-preview-picture-active: $color-yellow;
$product-preview-picture-hover: $color-blue-100;
$search-suggest-query: $color-orange;
$slider-banners-right-border: $color-transparent;
$slider-button-hover-bg: $color-gray-200;
$slider-button-hover-color: $color-black;
$slider-control-progress-bg: $color-gray-300;
$slider-control-thumb-bg: $color-gray-300;
$slider-control-thumb-bg-hover: $color-gray-200;
$header-top-bg: $color-gray-100;
$header-top-contractor-bg: $color-blue;
$header-top-contractor-color: $color-white;
$header-basket-bg-color: $color-orange;
$header-basket-count-left: 50%;
$header-basket-count-bg: $color-yellow-200;
$header-basket-count-color: $color-black;
$header-basket-count-br: 25px;
$header-menu-item-color: $color-transparent;
$header-icon-color: $color-black;
$header-text-hover-color: $color-orange;
$header-icon-hover-background: $color-transparent;
$user-panel-menu-color: $color-orange;
$footer-separator-color: $color-blue-200;
$brands-slider-item-border: $color-gray-200;
$brands-slider-item-border-hover: $color-blue-200;
$brands-toggler-on-bg: $color-green;
$brands-toggler-off-bg: $color-green-hover;
$pagination-item-color: $color-black;
$pagination-item-color-hover: $color-black;
$pagination-item-active-border: $color-yellow;
$pagination-button-bg: $color-gray-100;
$pagination-button-bg-hover: $color-blue-100;
$pagination-button-color: $color-blue;
$pagination-button-color-hover: $color-white;
$pagination-button-active-color: $color-white;
$tabs-border: $color-gray-200;
$tabs-item-color: $color-black;
$tabs-item-active-color: $color-black;
$tabs-item-active-bg: $color-gray-200;
$tabs-item-popup-bg: $color-yellow-200;
$tabs-count-default-bg: $color-orange;
$tabs-count-default-color: $color-white;
$tabs-count-wait-list-bg: $color-green;
$tabs-count-wait-list-color: $color-white;
$tabs-count-default-active-bg: $color-orange;
$tabs-count-default-active-color: $color-white;
$tabs-count-wait-list-active-bg: $color-green;
$tabs-count-wait-list-active-color: $color-white;
$basket-filter-bg: $color-transparent;
$basket-filter-border: $color-blue;
$basket-filter-link-color: $color-black;
$left-column-menu-bg: $color-transparent;
$left-column-menu-border: $color-gray-200;
$left-column-menu-padding-y: 0;
$left-column-menu-item-padding-y: 8px;
$left-column-menu-item-color: $color-black;
$left-column-menu-item-icon-color: $color-orange;
$left-column-menu-item-active-bg: $color-gray-100;
$left-column-menu-item-active-color: $color-black;
$left-column-menu-item-active-icon-color: $color-orange;
$left-column-title-icon-color: $color-black;
$bottom-panel-button-bg-hover: $color-blue-hover;
$bottom-panel-button-color-hover: $color-yellow;
$bottom-panel-button-to-basket-color: $color-black;
$bottom-panel-button-to-basket-bg: $color-yellow-200;
$bottom-panel-button-to-basket-bg-hover: $color-yellow-hover;
$bottom-panel-disabled-color: $color-gray-300;
$bottom-panel-result-button-bg: $color-orange;
$bottom-panel-result-button-color: $color-white;
$bottom-panel-result-button-bg-hover: $color-orange-hover;
$bottom-panel-result-button-color-hover: $color-white;
$order-submit: $maincolor;
$link-to-props: $color-blue-200;
$search-suggest-highlight: $color-blue-100;
$tab-bar-height: 46px;

