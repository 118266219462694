@import '../../theme/vars.scss'; 

.wrapper {
  @include zIndex(5);
  background: rgba(0, 0, 0, .6);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
}