@import '../../theme/vars.scss'; 

.wrapper {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1190px;
  width: 100%;

  @include mq(lg) {
    max-width: 590px;
  }

  @include mq(md) {
    max-width: 622px;
    padding: 0 $spacing-default;
  }
}
