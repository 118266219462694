@import '../../theme/vars.scss'; 

.cap {
  background-color: $color-gray-100;
  display: flex;
  flex-direction: column;
  font-size: $font-size-base;
  justify-content: center;
  text-align: center;
  width: 100%;

  .text {
    display: inline-block;
  }

  .link {
    background-color: transparent;
    border: unset;
    color: $color-blue;
    cursor: pointer;
    display: inline;
    margin: 0 0.25em;
    text-decoration: none;

    @include desktopHover {
      color: $link-color-hover;
    }
  }

  .registerLink {
    margin-left: 0;
  }
}

.hint {
  margin-left: $spacing-tiny;
}

.capProductCard {
  font-size: $font-size-base;
  padding: $spacing-small $spacing-tiny $spacing-default;

  .delimiter {
    height: 1px;
    margin: $spacing-big (-$spacing-tiny) $spacing-tiny;
    width: 220px;
  }

  .partGroup {
    margin-left: $spacing-tiny;
  }

  .partIcon {
    margin-right: $spacing-tiny;
  }

  &.withOutPrice {
    @include mq(lg) {
      padding: $spacing-tiny;
    }
  }
}

.capProductList {
  height: 155px;
  padding-left: $spacing-default;
  padding-right: $spacing-default;

  .text {
    text-align: left;
  }

  .price {
    font-size: $font-size-24px;
  }

  .partGroup {
    margin: $spacing-tiny 0;
  }

  .partIcon {
    margin-right: $spacing-small;
  }

  &.withOutPrice {
    @include mq(lg) {
      padding: $spacing-tiny;
    }
  }

  &.withOutPrice .text {
    text-align: center;
  }
}

.capProductTable {
  align-items: center;
  flex-direction: row;
  height: 100%;
  padding: $spacing-small $spacing-large;

  .capPrice {
    width: 310px;
  }

  .text {
    text-align: left;
  }

  .partGroup {
    margin-left: 1px;
  }

  .price {
    font-size: $font-size-24px;
    margin-bottom: $spacing-tiny;
    padding: 0;
  }

  .delimiter {
    flex-shrink: 0;
    height: 100%;
    margin: 0 $spacing-default;
    width: 1px;
  }

  &.withOutPrice .text {
    text-align: center;
  }

  .partIcon {
    margin-right: $spacing-small;
  }
}

.rightColumnBase {
  padding-left: $spacing-default;
  width: 330px;
}

.rightColumnFullWidth {
  composes: rightColumnBase;
  padding-left: 0;
  padding-top: $spacing-small;
}

.rightColumnFullWidthCard {
  composes: rightColumnBase;
  padding-left: 0;
  padding-top: $spacing-small;
}

.capBase {
  background-color: $color-gray-100;
  box-shadow: 0 1px 2px $color-gray-300;
  font-size: $font-size-base;
  margin-bottom: $spacing-default;
  margin-left: -3px;
  min-height: 140px;
  position: relative;
  text-align: center;
  top: -5px;
  width: 100%;
}

.capFullWidth {
  border-radius: 3px;
  composes: capBase;
  margin-bottom: $spacing-small;
  min-height: 99px;
  top: 0;
}

.capFullWidthCard {
  border-radius: 3px;
  composes: capBase;
  height: 132px;
  margin-bottom: $spacing-small;
  margin-left: 0;
  min-height: 99px;
  top: 0;
}

.capTextBase {
  align-items: center;
  bottom: 0;
  display: flex;
  height: 55px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 80%;

  a {
    text-decoration: underline;

    @include desktopHover {
      text-decoration: none;
    }
  }
}

.capTextFullWidth {
  composes: capTextBase;
  display: block;
  height: auto;
  padding: $spacing-large $spacing-default;
  position: static;
  width: auto;
}

.capTextFullWidthCard {
  composes: capTextFullWidth;
  display: block;
  height: auto;
  padding: $spacing-large $spacing-default;
  position: static;
  width: auto;
}

.link {
  background-color: transparent;
  border: unset;
  cursor: pointer;
  display: inline;
  margin: 0 0.25em;
  text-decoration: underline;

  @include desktopHover {
    text-decoration: none;
  }
}

.capTextProductTable {
  composes: capTextBase;
}

.price {
  color: $color-black;
  line-height: $line-height-default;
  text-align: left;
  @include typo-h2();
}

.currencyValue {
  vertical-align: middle;
}

.partGroup {
  color: $color-black;
  font-size: $font-size-base;
  height: 18px;
  text-align: left;
}

.partIcon {
  align-items: flex-end;
  display: inline-flex;
  flex-direction: row;
  height: 10px;
  width: 8px;

  span {
    background: $color-black;
    display: block;
    margin-right: 1px;
    width: 2px;
  }

  span:nth-child(1) {
    height: 9px;
  }

  span:nth-child(2) {
    height: 10px;
  }

  span:nth-child(3) {
    height: 8px;
    margin-right: 0;
  }
}

.batch {
  align-items: flex-end;
  display: inline-flex;
}

.delimiter {
  background: $color-gray-200;
  border: unset;
}

.registerText {
  display: inline;
}

.store {
  background-color: $color-white;
  border-radius: $border-radius-input;
  display: flex;
  margin: $spacing-small 0;
  padding: $spacing-small;

  &__icon {
    height: 18px;
    width: 18px;
  }

  &__name {
    margin-left: $spacing-tiny;

    &_bold {
      font-weight: $font-semibold;
    }
  }
}
