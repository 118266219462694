@import '../../theme/vars.scss'; 

.productList {
  align-items: flex-start;
  border: 1px solid $product-border;
  display: flex;
  margin-bottom: $spacing-small;
  min-height: 246px;
  padding: $spacing-tiny;
  position: relative;

  @include desktopHover {
    border-color: $product-border-hover;
  }

  &_hasStockNotice {
    .propertyTable {
      height: 206px;
    }
  }
}

.product {
  &Label {
    display: flex;
    min-height: 24px;
  }
}

.previewPicture {
  align-items: center;
  justify-content: center;

  &_zoom {
    cursor: zoom-in;
  }

  &_grayscale {
    filter: grayscale(100%);
  }
}

.popupPicture_grayscale {
  filter: grayscale(100%);
}

.listImage {
  height: auto;
  max-height: 100%;
  max-width: 100%;
  width: auto;
}

.imageContainer {
  align-items: center;
  display: flex;
  height: 220px;
  justify-content: center;
  width: 218px;
}

.brandTitle {
  color: $color-blue;
}

.checkbox {
  left: -3px;
  position: relative;
  width: 18px;
}

.propertyBlock {
  display: flex;
  flex-direction: column;
  width: 420px;
}

.nameBlock {
  margin-bottom: $spacing-small;
  margin-right: $spacing-tiny;
  width: 414px;

  .name {
    color: $color-black;
    cursor: pointer;
    display: inline-block;
    line-height: $line-height-default;
    margin-bottom: 3px;
    margin-top: $spacing-tiny;
    max-height: 54px;
    overflow: hidden;
    text-decoration: none;
    @include typo-base();

    @include desktopHover {
      color: $link-color-hover;
    }
  }

  .group {
    color: $color-gray-300;
    display: block;
    font-size: $font-size-base;
    line-height: $line-height-default;
    max-width: 414px;
    padding-top: 1px;

    a{
      @include desktopHoverFocusActive {
        text-decoration: none;
      }
    }

    span {
      margin-right: $spacing-small;

      &:last-child {
        margin-left: 0;
        margin-right: 0;
      }
    }

    a {
      color: $maincolor;
      text-decoration: none;

      @include desktopHover {
        color: $link-color-hover;
      }
    }

    .series {
      margin-left: $spacing-default;
    }

    .link {
      cursor: default;
      text-decoration: none;
    }
  }
}

.currencyValue {
  vertical-align: middle;
}

.propertyTable {
  margin-right: $spacing-tiny;
  position: relative;
  width: 414px;
}

.rightColumn {
  background: $color-gray-100;
  box-sizing: border-box;
  padding: $spacing-tiny $spacing-default;
  position: relative;
  width: 290px;
}

.image {
  margin-right: $spacing-tiny;
  position: relative;
  width: 218px;

  &_grayscale {
    filter: grayscale(100%);
  }

  @include desktopHover {
    .imageHover {
      display: flex;
    }
  }
}

.imageHover {
  align-items: center;
  background: $color-white;
  border-radius: 34px;
  bottom: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  color: $color-blue;
  cursor: pointer;
  display: none;
  font-size: $font-size-base;
  height: 30px;
  justify-content: center;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 170px;
  @include zIndex(44);

  &Icon {
    filter: $filter-image-blue;
    margin-right: 2px;
    width: 19px;
  }

  @include desktopHover {
    color: $color-orange;

    .imageHoverIcon {
      filter: $filter-image-orange;
    }
  }
}

.info {
  display: flex;
  justify-content: space-between;
}

.icon {
  height: 18px;
  width: 18px;

  @include desktopHover {
    color: $link-color-hover;
  }
}

.brand {
  text-decoration: underline;

  @include desktopHover {
    text-decoration: none;
  }

  &Wrapper {
    white-space: nowrap;
  }
}

.linkTag_a {
  text-decoration: underline;

  @include desktopHover {
    text-decoration: none;
  }
}

.linkTag_span {
  text-decoration: none;
}

.prices {
  margin-bottom: $spacing-tiny;
}

.orderBlock {
  padding-bottom: $spacing-tiny;
}

.moreOptions {
  left: (-$spacing-small);
  position: relative;
  top: $spacing-default
}
