@import '../../theme/vars.scss'; 

.block {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: $spacing-default;
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
  gap: $spacing-small;

  &__item {
    background-color: $color-gray-100;
    border-radius: $border-radius-input;
    display: flex;
    flex-direction: column;
    gap: $spacing-default;
    padding: $spacing-default;

    &:nth-of-type(2) {
      margin: $spacing-small 0 $spacing-default;
    }

    &:last-of-type {
      margin-bottom: $spacing-small;
    }

    &Product {
      align-items: flex-start;
      display: flex;
      gap: $spacing-small;
    }
  }
}

.radio {
  align-items: center;
  display: flex;
  gap: $spacing-default;
}
