@import '../../theme/vars.scss'; 

.productTable {
  border-top: 1px solid $product-border;
  display: flex;
  min-height: 110px;
  padding: $spacing-tiny 0;
  position: relative;

  &:last-child {
    border-bottom: 1px solid $color-gray-200;
    margin-bottom: $spacing-default;
  }

  @include desktopHover {
    border-color: $product-border-hover;
  }

  &_notSelectable {
    .nameColumn {
      max-width: 307px;
    }
  }

  &_loyalty {
    padding-left: $spacing-big;

    @include mq(lg) {
      align-items: flex-start;
      flex-direction: column;
      padding: $spacing-small 0;
    }
  }

  &_modalAnalogs {
    background-color: $color-green-100;
  }
}

.previewPicture {
  align-items: center;
  justify-content: center;

  &_zoom {
    cursor: zoom-in;
  }

  &_grayscale {
    filter: grayscale(100%);
  }
}

.popupPicture_grayscale {
  filter: grayscale(100%);
}

.tableImage {
  height: auto;
  max-height: 100%;
  max-width: 100%;
  width: auto;
}

.checkbox {
  position: absolute;

  input {
    height: 20px;
    width: 20px;
  }
}

.checkboxCell {
  width: 32px;
}

.nameBlock {
  .name {
    color: $color-black;
    cursor: pointer;
    line-height: $line-height-default;
    max-height: 90px;
    overflow: hidden;
    text-decoration: none;
    @include typo-base();
  }

  .name {
    @include desktopHoverFocusActive {
      color: $link-color-hover;
    }
  }

  .brandBlock {
    font-size: 0;
    margin-top: 5px;
    white-space: nowrap;
  }

  .brand {
    color: $color-gray-300;
    font-size: $font-size-base;
  }
}

.brandTitle {
  color: $color-blue;
}

.price {
  @include typo-h1-lg();
  color: $color-black;
  line-height: $line-height-default;
}

.checkboxColumn {
  width: 22px;
}

.imageColumn {
  height: 120px;
  margin-right: $spacing-small;
  position: relative;
  width: 120px;

  &:not(.imageColumn_loyalty):hover .imageHover {
    display: flex;
  }

  &_small {
    padding-left: 0;
    width: 122px;
  }
}

.imageHover {
  align-items: center;
  background: $color-white;
  border-radius: 44px;
  bottom: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  color: $maincolor;
  cursor: pointer;
  display: none;
  font-size: $font-size-base;
  height: 30px;
  justify-content: center;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 108px;
  @include zIndex(44);

  &Icon {
    filter: $filter-image-blue;
    margin-right: 2px;
    width: 19px;
  }

  @include desktopHover {
    color: $color-orange;

    .imageHoverIcon {
      filter: $filter-image-orange;
    }
  }
}

.imageHoverIcon {
  filter: $filter-image-blue;
  margin-right: 2px;
  width: 19px;
}

.nameColumn {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  margin-bottom: -2px;
  margin-right: 6px;
  margin-top: -2px;
  max-width: 246px;

  .basketTable & {
    max-width: 250px;
    min-width: 0;

    &_loyalty {
      max-width: 280px;
    }

    &_loyalty_basket {
      max-width: 330px;
      padding-right: $spacing-default;

      @include mq(lg) {
        margin: 0;
        max-width: 100%;
        padding: $spacing-small 0;
      }
    }
  }
}

.priceColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  min-height: 120px;
  width: 546px;

  &_small {
    width: 200px;
  }

  &_loyalty_basket {
    justify-content: center;
    width: 214px;

    @include mq(lg) {
      justify-content: flex-start;
      margin: 0;
      min-height: min-content;
      width: 100%;
    }
  }

  .orderButtonWrapper {
    display: inline-block;
    margin: $spacing-tiny $spacing-small $spacing-tiny 0;
    vertical-align: top;
  }

  .groupWrapper {
    display: inline-block;
    margin: $spacing-tiny 0 0;
    vertical-align: top;
  }
}

.artGroup {
  color: $color-gray-300;
  flex: 0 0 auto;
  font-size: $font-size-base;

  padding-top: $spacing-tiny;

  .code {
    margin-bottom: $spacing-tiny;
  }

  .art {
    margin-bottom: $spacing-tiny;
  }
}

.partGroup {
  color: $color-black;
  font-size: $font-size-base;

  line-height: $line-height-default;
  margin-bottom: $spacing-tiny;

  &_static {
    position: static;
  }
}

.part {
  font-size: $font-size-base;
  line-height: $line-height-default;
}

.partIcon {
  background: $color-black;
  display: inline-block;
  height: 10px;
  margin-left: $spacing-tiny;
  margin-right: $spacing-small;
  position: relative;
  width: 2px;

  &::before,
  &::after {
    background: $color-black;
    bottom: 0;
    content: '';
    display: block;
    position: absolute;
    width: 2px;
  }

  &::before {
    left: 3px;
    top: 2px;
  }

  &::after {
    right: 3px;
    top: 1px;
  }
}

.upCost {
  color: $color-black;
  font-size: $font-size-base;

  line-height: $line-height-default;
}

.upDate {
  color: $color-orange;
  font-size: $font-size-base;

  line-height: $line-height-default;
  margin-left: $spacing-small;
}

.rest {
  margin-top: $spacing-tiny;

  &_single {
    margin-bottom: 0;
    margin-top: $spacing-tiny;
  }
}

.buttonWrapper {
  margin-top: $spacing-tiny;
}

.cap {
  align-items: center;
  background-color: $color-gray-100;
  border-radius: 3px;
  box-shadow: 0 1px 2px $color-gray-200;
  display: flex;
  height: 90px;
  justify-content: center;
  width: 289px;
}

.capText {
  color: $color-black;
  font-size: $font-size-base;

  height: 51px;
  line-height: $line-height-default;
  text-align: center;
  width: 222px;

  a {
    text-decoration: underline;

    @include desktopHoverFocusActive {
      text-decoration: none;
   }
}
}

.icon {
  color: $color-black;
  height: 18px;
  width: 18px;

  @include desktopHover {
    color: $link-color-hover;
  }
}

.close {
  background-color: $color-white;
  cursor: pointer;
  height: 18px;
  position: absolute;
  right: $spacing-default;
  top: $spacing-default;
  width: 18px;
  @include zIndex(25);
}

.partText {
  display: inline-block;
}

.basketAmount {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 200px;

  &_loyalty_basket {
    padding-bottom: $spacing-tiny;
    width: 243px;

    @include mq(lg) {
      justify-content: flex-start;
      margin: 0;
      min-height: min-content;
      padding: $spacing-small 0;
      width: 100%;
    }
  }
}

.totalColumn {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: $spacing-small;
  white-space: nowrap;

  &_loyalty_basket {
    width: 237px;

    @include mq(lg) {
      justify-content: flex-start;
      margin: 0;
      min-height: min-content;
      padding: 0;
      width: 100%;
    }
  }
}

.totalPoints {
  color: $color-black;
  display: inline-block;
  vertical-align: middle;
  @include typo-h1();
}

.iconClose {
  color: $color-black;
  margin-right: $spacing-tiny;
  margin-top: 1px;
  width: 12px;
}

.button {
  background: none;
  border: 0;
  color: $color-gray-300;
  font-size: $font-size-base;
  outline: 0;
  padding: 0;
  text-decoration: underline;
}

.orderButtonWrapper {
  &_loyalty {
    margin-bottom: $spacing-tiny;
  }
}

.basketTable {
  &:last-child {
    border-bottom: 1px solid $color-gray-200;
  }

  @include desktopHover {
    border-color: $product-border-hover;
  }

  &_error {
    background-color: $color-red-100;
    border-color: $color-red-100;
  }

  &_success {
    background-color: $color-green-100;
    border-color: $color-green-100;
  }

  &_warning {
    background-color: $color-yellow-100;
    border-color: $color-yellow-100;
  }

  &_notice {
    background-color: $color-gray-100;
    border-color: $color-gray-100;
  }

  &_info {
    background-color: $color-blue-100;
    border-color: $color-blue-100;
  }

  &_loyalty {
    background-color: $color-purple-100;
    border-color: $color-purple-100;
  }
}

.orderBlock {
  display: flex;
  justify-content: space-between;
  min-height: 120px;
}

.prices {
  align-items: flex-start;
  background-color: $color-gray-100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $spacing-default;
  width: 250px;
}

.actions {
  background-color: $color-gray-100;
  box-sizing: border-box;
  padding: $spacing-small $spacing-default;
  width: 290px;
}

.analogsInfo {
  font-size: $font-size-base;
  padding: 0 $spacing-small $spacing-default;
  width: 100%;
}

.linkShowAnalogs {
  color: $color-blue-200;

  @include desktopHover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.labels {
  padding-top: $spacing-default;
}
