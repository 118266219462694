@import '../../theme/vars.scss'; 

.iconWrapper {
  display: flex;
}

.managersDropDown {
  opacity: 1;
  visibility: visible;
}

.managers {
  &List {
    @include zIndex(4);
    border-radius: $border-radius-input;
    display: flex;
    flex-direction: column;
    gap: $spacing-small;
    padding: $spacing-small;
    width: fit-content;

    @include mq(md) {
      background-color: $color-white;
      bottom: 0;
      position: fixed;
      width: 100%;
    }
  }

  &Item {
    align-items: center;
    border-bottom: 1px solid $color-gray-200;
    display: flex;
    gap: $spacing-small;
    min-width: 288px;
    padding-bottom: $spacing-small;
    width: 100%;

    &:last-child {
      border-bottom: 0;
    }
  }

  &Info {
    display: flex;
    flex-direction: column;
    gap: $spacing-tiny;

    &Text {
      display: flex;
      flex-direction: column;
    }

    &Contacts {
      display: flex;
      flex-direction: column;
    }

    &Link {
      color: $color-blue-200;
      font-size: $font-size-base;
      text-decoration: none;
      white-space: nowrap;

      &:hover {
        color: $color-orange-hover;
      }
    }
  }
}