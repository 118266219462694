@import '../../theme/vars.scss'; 

.cart {
  align-items: flex-start;
  display: flex;
  height: 66px;
  justify-content: center;
  margin-right: 1px;
  position: relative;

  @include desktopHover {
    .icon {
      filter: $filter-image-orange;
    }

    .wrapPrice {
      color: $header-text-hover-color;
    }
  }

  &_disabled {
    .wrapPrice {
      color: $color-gray-300;
    }
  }

  @include mq(lg) {
    height: auto;
  }
}

.icon {
  display: inline-block;
  filter: $filter-image-blue;
  height: 28px;
  margin-bottom: $spacing-small;
  margin-top: 2px;
  vertical-align: top;
  width: 39px;

  @media (max-width: 1280px) {
    margin-left: $spacing-small;
    margin-right: $spacing-small;
  }

  @include mq(lg) {
    margin-bottom: 0;
    margin-top: 0;
  }

  @include mq(md) {
    width: 34px;
    @include zIndex(44);
  }
}

.iconGray {
  filter: $filter-image-gray;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 61px;
  justify-content: space-between;

  @include mq(lg) {
    flex-direction: row;
    height: auto;
  }
}

.groups {
  margin-left: $spacing-default;
}

.wrapPrice {
  color: $color-black;
  display: block;
  line-height: $line-height-default;
  position: relative;
  text-align: center;
  top: 0;
  @include typo-base();

  @include mq(lg) {
    display: flex;
  }
}

.itemsCount {
  margin-top: $spacing-small;
}

.basketCount {
  background-color: $header-basket-count-bg;
  border-radius: $header-basket-count-br;
  color: $header-basket-count-color;
  display: block;
  font-weight: $font-semibold;
  left: $header-basket-count-left;
  margin-bottom: 0;
  min-width: 35px;
  padding: 0 $spacing-tiny;
  position: absolute;
  text-align: center;
  text-decoration: none;
  top: (-$spacing-tiny);

  @include mq(lg, md) {
    left: $header-basket-count-left * 0.5;
  }

  @include mq(md) {
    font-weight: $font-normal;
    left: $spacing-default;
    min-width: 21px;
    padding: 0 $spacing-tiny;
    top: $spacing-small;
  }
}

.wrapInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @include mq(lg, md) {
    margin-left: $spacing-large;
  }

  &_disabled {
    color: $color-gray-300;

    @include mq(lg, md) {
      margin-left: 0;
    }
  }
}

.basketTitle {
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
