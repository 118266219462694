@import '../../theme/vars.scss'; 

.content {
  display: flex;
  position: relative;

  @include mq(lg) {
    flex-direction: column;
  }

  &_bgWhite {
    background-color: $color-white;
  }

  &_isLoading {
    opacity: 0.7;
    pointer-events: none;
  }

  &_mainMenu {
    display: flex;
  }
}

.wrapperMainMenu {
  position: relative;
  width: 1200px;
  @include zIndex(12);
}
