@import '../../theme/vars.scss'; 

.fixed {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  @include zIndex(39);
}

.bottom {
  bottom: 0;
  opacity: 1;
  top: auto;
}

.hidden {
  display: none;
}

.transition {
  opacity: 0;
  transition: opacity 0.25s ease;
  visibility: hidden;
}

.transition.fixed {
  opacity: 1;
  visibility: visible;
}

.bottom_showColor {
  background-color: $color-white;
}

.fixed_hideColor {
  background-color: transparent;
}

.clickThrough {
  pointer-events: none;
}
