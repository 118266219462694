@import '../../theme/vars.scss'; 

.socials {
  display: inline-block;
}

.vk,
.fb {
  color: $color-white;
  display: inline-block;
  font-size: $font-size-base;
  margin-right: $spacing-tiny;
  min-width: 67px;
  padding: 1px $spacing-tiny;
  text-align: center;

  @include desktopHover {
    color: $color-white;
    text-decoration: underline;
  }
}

.vk {
  background-color: #587a9f;
}

.fb {
  background-color: #3a569d;
}
