$color-transparent: transparent;
$color-black: #000;
$color-blue: #4865ff;
$color-blue-100: #edf3ff;
$color-blue-200: #223fd9;
$color-blue-hover: $color-blue-200;
$color-gray-100: #f7f7f7;
$color-gray-200: #e5e5e5;
$color-gray-300: #838383;
$color-gray-400: rgba($color-black, 0.7);
$color-green: #34af31;
$color-green-hover: rgba($color-green, 0.7);
$color-green-100: #ecffec;
$color-orange: #ff6400;
$color-orange-hover: #E26B1E;
$color-pink: #ff1977;
$color-purple: #9243f1;
$color-purple-100: #ede1fd;
$color-purple-200: rgba($color-purple, 0.7);
$color-red: #ff2639;
$color-red-100: #fee;
$color-red-200: #f15751;
$color-sapphirine: #4f69f6;
$color-sapphirine-100: #889bff;
$color-yellow: #ffd100;
$color-yellow-hover: rgba($color-yellow, 0.7);
$color-yellow-100: #fdfcc9;
$color-yellow-200: #ffad00;
$color-white: #fff;

// предопределенные фильтры для image/svg
$filter-image-blue: invert(36%) sepia(43%) saturate(4042%) hue-rotate(221deg) brightness(99%) contrast(106%);
$filter-image-blue-100: brightness(0) saturate(100%) invert(17%) sepia(95%) saturate(2843%) hue-rotate(225deg) brightness(105%) contrast(90%);
$filter-image-blue-200: invert(19%) sepia(49%) saturate(5365%) hue-rotate(231deg) brightness(93%) contrast(100%);
$filter-image-gray: invert(54%) sepia(19%) saturate(19%) hue-rotate(317deg) brightness(91%) contrast(95%);
$filter-image-gray-200: invert(88%) sepia(45%) saturate(9%) hue-rotate(125deg) brightness(113%) contrast(80%);
$filter-image-gray-300: brightness(0) saturate(100%) invert(54%) sepia(0%) saturate(57%) hue-rotate(173deg) brightness(97%) contrast(79%);
$filter-image-green: invert(51%) sepia(53%) saturate(656%) hue-rotate(70deg) brightness(97%) contrast(91%);
$filter-image-orange: invert(43%) sepia(27%) saturate(6911%) hue-rotate(1deg) brightness(104%) contrast(108%);
$filter-image-red: invert(27%) sepia(77%) saturate(5916%) hue-rotate(345deg) brightness(123%) contrast(102%);
$filter-image-white: invert(100%) sepia(100%) saturate(0%) hue-rotate(332deg) brightness(103%) contrast(104%);
$filter-image-purple: invert(38%) sepia(62%) saturate(6304%) hue-rotate(254deg) brightness(97%) contrast(95%);
$filter-image-pink: invert(14%) sepia(86%) saturate(5882%) hue-rotate(325deg) brightness(101%) contrast(107%);
$filter-image-yellow: invert(69%) sepia(77%) saturate(1961%) hue-rotate(358deg) brightness(100%) contrast(106%);

