@import '../../theme/vars.scss'; 

.slider {
  margin: 0 -5px;
  max-width: 100%;
}

.brandItem {
  border: 1px solid $brands-slider-item-border;
  border-radius: $border-radius-default;
  margin: $spacing-tiny;
  overflow: hidden;

  @include desktopHover {
    border-color: $brands-slider-item-border-hover;
  }
}

.brandName {
  align-items: center;
  display: flex;
  height: 108px;
  justify-content: center;
}

.brandImage {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 108px;
  justify-content: center;
  max-width: 110px;

  @include desktopHover {
    filter: none;
  }

  @include mq(md) {
    max-width: none;
  }
}

.title {
  color: $link-color;
  @include typo-h2($font-bold);
}

.SliderWrapper {
  margin-bottom: $spacing-large;
  overflow: hidden;

  :global {
    .slick-arrow {
      top: 50%;
    }

    .slick-next {
      right: $spacing-tiny;
    }
  }

  @include mq(md) {
    margin-bottom: $spacing-big;
  }
}

.titleWrapper {
  composes: title;
  display: inline-block;
  padding-bottom: $spacing-small;

  @include mq(md) {
    margin-bottom: $spacing-default;
    padding: 0;
  }
}

.image {
  max-height: 103px;
  max-width: 103px;
}
