@import '../../theme/vars.scss'; 

$borderSize: 18px;
$pointSize: 10px;

%hide {
  clip-path: inset(0 0 0 0);
  height: 1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
}

.radio {
  font: {
    size: $font-size-base;
    weight: normal;
  }

  &:hover &__label,
  &:hover &__label::before {
    color: $color-blue-200;
    outline-color: $color-blue-200;
  }

  &__field,
  &__field:checked,
  &__field:not(:checked) {
    @extend %hide;
  }

  &__field:checked + &__label::after {
    background-color: $color-blue;
    border-radius: $border-radius-circle;
    content: '';
    height: $pointSize;
    left: $spacing-tiny;
    position: absolute;
    width: $pointSize;
  }

  &__label {
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: $spacing-tiny;
    position: relative;

    &::before {
      background-color: $color-white;
      border-radius: $border-radius-circle;
      content: '';
      height: $borderSize;
      min-width: $borderSize;
      outline: 1px solid $color-black;
      outline-offset: -1px;
    }
  }
}
