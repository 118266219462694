@import '../../theme/vars.scss'; 

.image {
  &Wrapper {
    align-items: center;
    cursor: grab;
    display: flex;
    justify-content: center;

    @include desktopHoverFocusActive {
      cursor: grabbing;
    }
  }

  &Container {
    background-size: cover;
    display: flex;
  }

  &Loader {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
