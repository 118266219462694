@import '../../theme/vars.scss'; 

.form {
  background-color: $color-gray-100;
  border: 1px solid $color-gray-200;
  border-radius: 3px;
  min-height: 400px;
  width: 100%;
  @include typo-base($font-normal);

  &_transparent {
    background: none;
    border-radius: $border-radius-default;
  }

  &__fieldset {
    border-bottom: 1px solid $color-gray-200;
    padding: $spacing-default;
  }

  &__title {
    display: block;
    padding-bottom: $spacing-default;
    @include typo-h2($font-bold);
  }
}

.formField {
  display: block;
  margin-bottom: $spacing-small;
  max-height: 400px;
  overflow: auto;

  &:last-child {
    padding-bottom: 0;
  }

  &__title {
    display: block;
    margin-bottom: $spacing-default;
    padding-bottom: $spacing-default;
    padding-right: $spacing-small;
    @include typo-base($font-bold);

    .form_horizontal & {
      display: inline-block;
    }
  }

  &_compact {
    .formField__title {
      color: $color-gray-300;
      padding-bottom: $spacing-tiny;
    }
  }
}

.formInput {
  &__fieldWrapper {
    width: 270px;

    &:not(:last-of-type) {
      margin-bottom: $spacing-default;
    }

    &_size_third {
      display: inline-block;
      margin-right: $spacing-default;
      width: 80px;
    }
  }

  &__field {
    border: 1px solid $color-gray-200;
    border-radius: $border-radius-button;
    display: block;
    font-size: $font-size-base;
    height: 30px;
    padding: 0 $spacing-small;
    width: 100%;

    .form_horizontal & {
      display: inline-block;
    }
  }
}



.formRadioBase {
  display: block;
  font-weight: normal;
  margin-bottom: $spacing-default;
  padding-left: $spacing-big;
  position: relative;

  &Field {
    display: none;

    &:checked + .formRadio__lableBase {
      .formRadio__icon::after {
        transform: scale(1) rotate(90deg);
      }
    }
  }
}

.formRadio__icon {
  border: 1px solid $color-black;
  border-radius: $border-radius-circle;
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 20px;

  &::after {
    background: $color-black;
    border-radius: $border-radius-circle;
    bottom: 0;
    content: '';
    display: block;
    height: 10px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    transform: scale(0);
    width: 10px;
  }
}

.formRadio__lableBase {
  cursor: pointer;
  line-height: $line-height-default;
  margin-bottom: $spacing-small;
}

.formRadioBasket {
  composes: formRadioBase;
  margin-bottom: 0;
  padding-left: $spacing-default;

  &Field {
    &:checked + .formRadio__lableBasket {
      .formRadio__icon::after {
        transform: scale(1) rotate(90deg);
      }
    }
  }

  .formRadio__icon {
    height: 14px;
    margin-top: 2px;
    width: 14px;

    &::after {
      height: 4px;
      width: 4px;
    }
  }
}

.formRadio__lableBasket {
  color: $color-black;
  composes: formRadio__lableBase;
  cursor: pointer;
  font-size: $font-size-base;
  line-height: $line-height-default;
}

.formRadio__wrapItemBasket {
  margin-bottom: $spacing-small;
}

.formRadio__lableTextBasket {
  vertical-align: top;
}

.formRadio__contentBasket {
  margin-bottom: $spacing-default;
  margin-left: (-$spacing-default);
  margin-top: $spacing-small;
}

.formRadio__disabled {
  opacity: 0.5;
}

.formTextarea {
  display: block;

  &__field {
    background: $color-white;
    border: 1px solid $color-gray-200;
    display: block;
    font-size: $font-size-base;
    min-height: 110px;
    min-width: 100%;
    padding: $spacing-small;
    resize: none;

    &_error {
      border-color: $color-red;
    }
  }
}

.formBtn {
  background: none;
  border: unset;
  border-radius: $border-radius-button;
  color: $color-white;
  margin: 0 $spacing-tiny;
  margin-bottom: $spacing-small;
  min-height: 30px;
  text-align: center;
  text-decoration: underline;

  &:disabled {
    background-color: $color-gray-200;
    border: 1px solid $color-gray-200;
    color: $color-gray-200;
  }

  &_size_medium {
    height: 30px;
    width: 214px;
  }

  &_size_small {
    height: 30px;
    width: 91px;
  }

  &_size_big {
    background-color: $color-orange;
    font-size: $font-size-h2;
    height: 50px;
    text-decoration: none;
    width: 271px;
  }

  &_size_full {
    text-decoration: none;
    width: 100%;
  }

  &_color_blue {
    background: $color-blue;
    font-size: $font-size-base;
  }

  &_color_green {
    background: $color-green;
  }

  &_color_transparent {
    background: none;
    border: 1px solid $color-black;
    color: $color-black;
  }

  &_color_orange {
    background: $color-orange;
  }
}

.formSelect {
  &__field {
    background-color: $color-white;
    border: 1px solid $color-gray-200;
    border-radius: $border-radius-button;
    height: 30px;
    padding: 0 $spacing-small;
    width: 195px;

    &:focus {
      outline: none;
    }
  }
}

.mod_noTitle {
  .formField__title {
    display: none;
  }
}

.formRadio {
  padding-left: $spacing-small;

  &Icon {
    border: 1px solid $color-black;
    border-radius: $border-radius-circle;
    display: inline-block;
    height: 20px;
    left: (-$spacing-tiny);
    position: relative;
    width: 20px;

    &Active {
      &::after {
        background: $color-black;
        border-radius: $border-radius-circle;
        bottom: 0;
        content: '';
        display: inline-block;
        height: 10px;
        left: $spacing-tiny;
        margin: auto;
        position: relative;
        right: 0;
        top: 0;
        width: 10px;
      }
    }
  }

  &Field {
    display: none;
  }

  &Label {
    align-items: center;
    display: flex;
    padding-right: $spacing-default;

    @include desktopHover {
      cursor: pointer;
    }
  }
}

.hidden {
  display: none;
}

.radioButton {
  align-items: center;
  display: flex;

  @include desktopHover {
    cursor: pointer;
  }
}
