@import '../../theme/vars.scss'; 

.content {
  height: 90%;
  margin: 0;
  padding-top: 0;
}

.columnLeft {
  display: block;
}

.menuCatalog {
  bottom: 0;
  font-size: 22px;
  left: 0;
  overflow-y: auto;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  @include zIndex(13);
}

.catalogOverlay {
  background: $color-white;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  @include zIndex(3);
}

.rightColumnWrapper {
  background-color: $color-gray-100;
  height: 100%;
  margin-top: $spacing-small;
  width: 100%;
}

.favoriteItem {
  align-items: center;
  border-radius: 70px;
  border-style: solid;
  border-width: 2px;
  display: flex;
  margin: 0 $spacing-tiny;
  padding: $spacing-small $spacing-default;
  width: 210px;

  @include desktopHover {
    .link {
      color: $color-white;
    }
  }

  &_icon {
    height: 24px;
    margin-right: $spacing-small;
    width: 24px;
  }

  &_title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 135px;
  }

  &Wrapper {
    background-color: $color-white;
    display: flex;
    padding: 0 $spacing-default $spacing-small;
    pointer-events: all;
    width: 100%;
    @include zIndex(43);
  }

  &:first-child {
    border-color: $color-orange;
    margin-left: 0;

    @include desktopHover {
      background-color: $color-orange;
    }

    .favoriteItem_icon {
      filter: $filter-image-orange
    }
  }

  &:nth-child(n + 2) {
    border-color: $color-purple;

    @include desktopHover {
      background-color: $color-purple;
    }

    .favoriteItem_icon {
      filter: $filter-image-purple;
    }
  }

  &:nth-child(n + 3) {
    border-color: $color-green;

    @include desktopHover {
      background-color: $color-green;
    }

    .favoriteItem_icon {
      filter: $filter-image-green;
    }
  }

  &:nth-child(n + 4) {
    border-color: $color-yellow-200;

    @include desktopHover {
      background-color: $color-yellow-200;
    }

    .favoriteItem_icon {
      filter: $filter-image-yellow;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  @include desktopHover {
    color: $color-white;

    .favoriteItem_icon {
      filter: $filter-image-white;
    }
  }
}

.link {
  align-items: center;
  display: flex;
  height: 20px;
  margin: 0;
  padding: 0;

  @include desktopHover {
    color: $color-white;
  }
}
