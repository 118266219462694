@import '../../theme/vars.scss'; 

$sizes: (
  tiny: $spacing-tiny,
  small: $spacing-small,
  default: $spacing-default,
  big: $spacing-big,
  large: $spacing-large
);

@mixin rules($position, $indent) {
  @each $size, $value in $sizes {
    &.spacing__size_#{$size} {
      @if $position == vertical {
        #{$indent}: $value 0;
      } @else if $position == horizontal {
        #{$indent}: 0 $value;
      } @else if $position == right {
        #{$indent}-right: $value;
      } @else if $position == left {
        #{$indent}-left: $value;
      } @else if $position == top {
        #{$indent}-top: $value;
      } @else if $position == bottom {
        #{$indent}-bottom: $value;
      } @else  {
        #{$indent}: $value;
      }
    }
  }
}

.spacing {
  &_stretched {
    width: 100%;
  }

  @each $indent in margin, padding {
    &__#{$indent} {
      @each $position in all, left, right, top, bottom, vertical, horizontal {
        &_#{$position} {
          @include rules($position, $indent);
        }
      }
    }
  }

}
