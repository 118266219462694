@import '../../theme/vars.scss'; 

.wrapper {
  margin-top: $spacing-tiny;
  position: relative;
}

.icon {
  padding: $spacing-tiny $spacing-small;
  position: absolute;
  right: 0;
  top: 0;

  @include desktopHover {
    cursor: pointer;

    .iconEye {
      filter: $filter-image-blue;
    }
  }

  &Eye {
    height: 18px;
    width: 18px;
  }
}
