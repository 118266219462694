@import '../../theme/vars.scss'; 

.wrapper {
  @include zIndex(9);
  bottom: $spacing-default;
  position: fixed;
  right: 0;
  width: 100%;
}

.content {
  background-color: $color-white;
  border: 1px solid $color-gray-200;
  border-radius: 6px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  gap: $spacing-small;
  justify-content: space-between;
  margin-left: auto;
  max-width: 866px;
  padding: $spacing-default;

  @include mq(lg) {
    flex-wrap: wrap;
    margin-bottom: 46px;
  }
}