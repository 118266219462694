@import '../../theme/vars.scss'; 

.button {
  border: unset;
  border-radius: $border-radius-block;
  cursor: pointer;
  font-size: $font-size-base;
  height: 30px;
  margin-right: $spacing-tiny;
  width: 100%;

  &:last-child {
    margin-right: 0;
  }

  &_large {
    height: 40px;
    width: 170px;
  }

  &_off {
    pointer-events: none;
  }
}

.toBasket {
  background-color: $color-pink;
  border: 2px solid transparent;
  color: $color-white;

  @include desktopHover {
    background-color: $color-pink;
  }

  &[disabled] {
    background-color: $color-gray-300;
  }

  &_success {
    background: $color-green;
  }
}

.toTarget {
  background-color: transparent;
  border: 2px solid $color-purple;
  color: $color-purple;

  @include desktopHover {
    background-color: $color-purple;
    color: $color-white;
    text-decoration: none;
  }

  &_active {
    border: 2px solid;
    color: $color-pink;

    @include desktopHover {
      border-color: $color-purple;
    }
  }
}

.targetIcon {
  margin-right: $spacing-tiny;
}

.ProductLoyaltyButtons {
  display: flex;
  width: 100%;

  button > span {
    position: relative;
  }
}
