@import '../../theme/vars.scss'; 

.cartMenu {
  border-radius: $border-radius-button;
  height: auto;
  max-width: 485px;
  width: auto;
}

.name {
  border: 1px solid $color-blue;
  border-radius: $border-radius-button;
  padding: $spacing-default $spacing-big;
  width: 100%;

  &Title {
    color: $color-blue;
    font-size: $font-size-base;
  }

  &Count {
    font-size: $font-size-base;
  }

  &Sum {
    font-size: $font-size-base;
  }

  &Points {
    color: $color-purple;
    font-size: $font-size-base;
  }
}

.action {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: $spacing-default $spacing-big;
}

.change {
  width: 30%;
}

.favorite {
  width: 30%;
}

.compare {
  width: 30%;
}

.hr {
  border-bottom: 1px solid $color-gray-200;
  width: 100%;
}

.link {
  background-color: $color-orange;
  color: $color-white;
}

.products {
  padding: $spacing-default $spacing-big;
}

.product {
  display: grid;
  padding: 0 $spacing-tiny;

  &Image {
    height: 38px;
    width: 38px;
  }

  &Name {
    font-size: $font-size-base;
  }

  &Count {
    font-size: $font-size-base;
  }

  &Sum {
    @include typo-base();
  }
}
