@import '../../theme/vars.scss'; 

.container {
  display: flex;
  flex-direction: column;
  font-size: $font-size-base;
}

.title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: $spacing-small;

  &Text {
    font-size: $font-size-h1;
    line-height: $line-height-default;
  }

  &Button {
    align-items: center;
    background-color: $color-blue-100;
    border-radius: $border-radius-block;
    color: $color-blue;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: $spacing-small;

    @include desktopHover {
      cursor: pointer;
    }
  }
}

.icon {
  filter: $filter-image-blue;
  margin-right: $spacing-tiny;
}

.table {
  display: flex;
  flex-direction: column;
}

.head {
  color: $color-gray-300;

  @include mq(lg) {
    display: none;
  }
}

.row {
  display: grid;
  grid-template-columns: 2fr 2fr 3fr 2fr 2fr 2fr 2fr 2fr;
  padding: $spacing-small 0;

  @include mq(lg) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @include mq(sm) {
    flex-direction: column;
  }

  &Body {
    border-top: 1px solid $color-gray-200;
  }
}

.copy {
  display: flex;

  &Icon {
    filter: $filter-image-blue;
    margin-right: $spacing-tiny;
  }

  @include desktopHover {
    color: $color-orange;
    cursor: pointer;

    img {
      filter: $filter-image-orange;
    }
  }
}

.col {
  display: flex;
  flex-direction: column;

  @include mq(lg) {
    width: 33%;
  }

  &Name {
    display: none;

    @include mq(lg) {
      color: $color-gray-300;
      display: block;
    }
  }
}
