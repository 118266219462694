@import '../../theme/vars.scss'; 

.currencyValue {
  align-items: center;
  display: flex;
  justify-content: center;
  white-space: nowrap;
}

.bold {
  font-weight: $font-semibold;
}

.sign {
  margin-left: $spacing-tiny;
}
