@import '../../theme/vars.scss'; 

.cart {
  height: 0;
  position: relative;
  right: -100%;
  width: 0;
  @include zIndex(11);
}

.wrapper {
  background-color: $color-white;
  border: 1px solid $color-gray-200;
  border-radius: $border-radius-button;
  padding: $spacing-default $spacing-big;
  position: absolute;
  right: 0;
  width: 485px;
}

.current {
  width: 100%;
}

.action {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.favorite {
  align-items: center;
  display: flex;
  font-weight: normal;
  justify-content: center;

  &Disabled {
    color: $color-gray-300;
  }
}

.link {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.basketLink {
  background-color: $color-orange;
  border-radius: $border-radius-button;
  color: $color-white;
  padding: $spacing-tiny $spacing-small;

  @include desktopHover {
    background-color: lighten($color-orange, 10%);
    color: $color-white;
    cursor: pointer;
  }
}

.hr {
  background-color: $color-gray-200;
  border: unset;
  color: $color-gray-200;
  height: 1px;
  margin: $spacing-small (-$spacing-big);
}

.icon {
  margin-right: $spacing-tiny;
}

.products {
  width: 100%;
}

.product {
  align-items: center;
  display: grid;
  font-weight: normal;
  grid-template-columns: 40px 260px 60px 80px;
  padding-bottom: $spacing-small;
  text-align: left;

  &Image {
    height: 34px;
    width: 34px;
  }
}

.isPoints {
  color: $color-purple;
}

.more {
  align-items: center;
  display: flex;

  &Icon {
    filter: $filter-image-orange;
    padding-right: $spacing-tiny;
  }

  &Text {
    color: $maincolor;

    @include desktopHover {
      color: $maincolorhover;
    }
  }

  @include desktopHover {
    cursor: pointer;
  }
}
