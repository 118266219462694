@import '../../theme/vars.scss'; 

.tabs {
  margin-bottom: 0;
  margin-top: 0;
  overflow: hidden;

  &_quickView {
    display: block;
  }

  &_loyalty {
    .tabListWrapper_cart {
      .tabItem {
        padding: 0 $spacing-default;
      }
    }
  }
}

.tabListWrapper {
  border-bottom: 1px solid $tabs-border;
  display: flex;
  justify-content: space-between;

  @include mq(lg) {
    display: block;
    overflow: hidden;
  }
}

.tabItem {
  align-items: center;
  border-top-left-radius: $border-radius-default;
  border-top-right-radius: $border-radius-default;
  color: $tabs-item-color;
  cursor: pointer;
  display: flex;
  font-size: $font-size-base;
  height: 38px;
  list-style-type: none;
  padding: 0 $spacing-small;
  white-space: nowrap;

  @include desktopHover {
    background: $color-gray-100;
    color: $link-color-hover;
    text-decoration: none;
  }
}

.tabActive {
  background-color: $tabs-item-active-bg;
  color: $tabs-item-active-color;
  cursor: default;
  pointer-events: none;

  .title {
    text-decoration: none;
  }

  .count {
    &_default,
    &_bookmark {
      background-color: $tabs-count-default-active-bg;
      color: $tabs-count-default-active-color;
    }

    &_wait-list {
      background-color: $tabs-count-wait-list-active-bg;
      color: $tabs-count-wait-list-active-color;
    }

    &_offers {
      background-color: $color-white;
      color: $color-black;
    }
  }

  &_disabled {
    .count {
      cursor: pointer;
      pointer-events: auto;
    }

    @include desktopHover {
      background-color: $tabs-item-active-bg;
      color: $tabs-item-active-color;
    }
  }
}

.tabPanel {
  padding: $spacing-default 0;
  @include typo-base();
}

.count {
  border-radius: $border-radius-default;
  color: $color-black;
  font-size: $font-size-base;
  height: 27px;
  line-height: $line-height-big;
  margin-left: $spacing-small;
  min-width: 27px;
  padding: 0 $spacing-small;
  text-align: center;

  &_primary {
    background-color: $maincolor;
    color: $color-white;
  }

  &_default,
  &_bookmark {
    background-color: $tabs-count-default-bg;
    color: $tabs-count-default-color;
  }

  &_wait-list {
    background-color: $tabs-count-wait-list-bg;
    color: $tabs-count-wait-list-color;
  }

  &_error,
  &_offers {
    background-color: $color-red;
    color: $color-white;
    font-weight: $font-semibold;
  }

  &::before {
    color: $color-black;
    line-height: $line-height-default;
    text-align: left;
  }
}

.moreInfoLink {
  .tabItem {
    padding-right: $spacing-tiny;
  }
}

.iconWrapper {
  display: inline-block;
  margin-right: $spacing-small;
  text-align: center;
  width: 14px;
}
