@media print {
  header,
  footer,
  .src-containers-Banners--banners,
  .src-components-ColumnLeft--columnLeft,
  .src-components-Redhelper--redhelper,
  .src-components-BrandSlider--SliderWrapper {
    display: none;
  }

  .src-components-ColumnMain--columnMain,
  .src-containers-PageBasket--wrapperBasketRelef {
    display: block;
    max-width: 960px;
  }
}
