@import '../../theme/vars.scss'; 

.root {
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: $font-size-base;
  justify-content: space-between;
  text-align: center;
}

.spinner {
  display: inline-block;
  margin-bottom: $spacing-default;
  margin-top: $spacing-default;
}
