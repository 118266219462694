@import '../../theme/vars.scss'; 

.code {
  align-items: center;
  color: $color-gray-300;
  display: inline-flex;
  font-size: $font-size-base;

  margin-right: $spacing-small;
  min-width: 70px;
  white-space: nowrap;

  &Value {
    background-color: $product-code-bg;
    border-radius: $border-radius-default;
    color: $product-code-color;
    display: inline-flex;
    height: 16px;
    line-height: $line-height-small;
    padding: 0 $spacing-tiny;
    white-space: nowrap;

    &Loyalty {
      border-radius: 8px;
    }

    &small,
    &big {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &small {
      max-width: 205px;
    }

    &big {
      max-width: 370px;
    }

    &code {
      border: 1px solid $product-code-border;
    }

    &vendor {
      border: 1px solid $product-vendor-code-border;
    }

    &barcode {
      border: 1px solid $color-green;
    }
  }
}
