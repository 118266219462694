@import '../../theme/vars.scss'; 

.buy {
  display: flex;
  font-size: $font-size-base;
  padding-bottom: $spacing-small;

  @include desktopHover {
    cursor: pointer;
  }
}
