@import '../../theme/vars.scss'; 

.root {
  display: flex;
  flex-direction: column;
  font-size: $font-size-base;
  gap: $spacing-small;

  &_documents {
    .buttons {
      margin-bottom: $spacing-default;
    }
  }

  &_loading {
    .message {
      margin-bottom: $spacing-large;
    }
  }

  &_error,
  &_success {
    .title_big {
      margin-bottom: $spacing-large;
    }

    .message {
      margin-bottom: $spacing-large;
    }

    .buttons {
      align-items: center;
      justify-content: center;
      margin-bottom: $spacing-large;
    }
  }

  &_error {
    .message {
      color: $color-red;
      margin-bottom: $spacing-large;
    }
  }
}

.title {
  color: $color-black;
  display: flex;
  font-size: $font-size-h2;
  line-height: $line-height-default;
  padding: $spacing-small 0;
  position: relative;

  &Prev {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &Icon {
    @include desktopHover {
      cursor: pointer;
    }
  }
}

.message {
  color: $color-black;
  text-align: center;
}

.spinner {
  display: flex;
  justify-content: center;

  &Image {
    display: block;
    margin: $spacing-default 0;
  }
}

.optionsButton {
  align-items: center;
  align-items: center;
  background-color: $color-white;
  border: 1px solid $color-blue;
  color: $color-blue-200;
  display: flex;
  display: flex;
  justify-content: center;
  justify-content: center;
  padding: $spacing-default 0;
  width: 100%;

  @include desktopHover {
    cursor: pointer;
  }

  &Title {
    padding-left: $spacing-tiny;
  }
}

.optionsTooltip {
  color: $color-black;
  display: none;
  font-size: $font-size-base;
  left: $spacing-default;
  position: absolute;
  top: $spacing-large;
  width: auto;
}

.icon {
  &Gear {
    align-items: center;
    display: flex;
    filter: $filter-image-blue;
    height: 20px;
  }

  &Prev {
    margin-right: $spacing-tiny;
  }
}

.desc {
  color: $color-black;
  margin-bottom: $spacing-small;
}

.table {
  &Item {
    align-items: center;
    background-color: $color-white;
    border-radius: $border-radius-button;
    display: flex;
    margin: $spacing-tiny $spacing-default;
    margin-bottom: $spacing-tiny;
  }

  &View {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding: $spacing-tiny;
  }

  &Edit {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: $spacing-tiny;
  }

  &Desc {
    display: flex;
    flex-direction: column;
    margin-left: $spacing-small;

    &Title {
      width: 100%;
    }

    &Format {
      color: $color-gray-300;
      font-size: 10px;
    }
  }

  &Title {
    margin-right: auto;
    padding-right: $spacing-small;
    word-break: break-word;
  }

  &Format {
    padding-top: $spacing-small;
    width: 100%;
  }

  &FormatName {
    display: block;
    text-align: right;

    &_empty {
      color: $color-gray-300;
      cursor: pointer;
    }
  }
}

.wrapper {
  background-color: $color-gray-100;
  padding-bottom: $spacing-small;
  width: 100%;
}

.subTitle {
  padding: $spacing-default;
  @include typo-base();
}
