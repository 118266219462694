.slick-slider {
  box-sizing: border-box;

  display: block;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  -ms-touch-action: pan-y;
  touch-action: pan-y;

  -webkit-touch-callout: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}

.slick-list {
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  /* z-index: 250; */
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slider-container {
  max-width: 100vw;
  width: 100%;
  overflow: hidden;
}

.slick-track {
  display: flex;
  left: 0;
  position: relative;
  top: 0;
}

.slick-track::before,
.slick-track::after {
  content: '';
  display: table;
}

.slick-track::after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: flex;
  height: auto;
  min-height: 1px;
}

.slick-slide > div {
  display: grid;
  height: 100%;
}

.slick-slide > div > div {
  display: flex !important;
  flex-direction: column;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  border: 1px solid transparent;
  display: block;
  height: auto;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-arrow {
  align-items: center;
  background-color: #f7f7f7;
  border: #f7f7f7;
  opacity: 0.5;
  cursor: pointer;
  display: flex;
  height: 35px;
  justify-content: center;
  padding-bottom: 10px;
  padding-top: 10px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  user-select: none;
  width: 35px;
  z-index: 260;
}

.slick-arrow:hover {
  opacity: 1;
}

.slick-next {
  right: 0;
  top: 36%;
}

.slick-prev {
  left: 5px;
  top: 36%;
}

.slick-disabled {
  opacity: 0.5;
}

.slick-disabled:hover {
  opacity: 0.5;
  cursor: default;
}
