@import '../../theme/vars.scss'; 

.loyaltyCoefficient {
  border: 2px solid transparent;
  border-color: $color-pink;
  border-radius: 15px;
  color: $color-pink;
  height: 24px;
  margin-left: $spacing-tiny;
  padding: 1px $spacing-tiny;
  @include typo-base();


  @include desktopHover {
    background: $color-pink;
    color: $color-white;
    cursor: pointer;
  }

  &_withText {
    padding-left: $spacing-tiny;
    padding-right: $spacing-tiny;
  }
}
