@font-face {
  font-display: auto;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/open-sans/open-sans-regular.eot');
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('/fonts/open-sans/open-sans-regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/open-sans/open-sans-regular.woff2') format('woff2'),
    url('/fonts/open-sans/open-sans-regular.woff') format('woff'),
    url('/fonts/open-sans/open-sans-regular.ttf') format('truetype'),
    url('/fonts/open-sans/open-sans-regular.svg#OpenSans') format('svg');
}
@font-face {
  font-display: auto;
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/open-sans/open-sans-italic.eot');
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
    url('/fonts/open-sans/open-sans-italic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/open-sans/open-sans-italic.woff2') format('woff2'),
    url('/fonts/open-sans/open-sans-italic.woff') format('woff'),
    url('/fonts/open-sans/open-sans-italic.ttf') format('truetype'),
    url('/fonts/open-sans/open-sans-italic.svg#OpenSans') format('svg');
}
@font-face {
  font-display: auto;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/open-sans/open-sans-600.eot');
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
    url('/fonts/open-sans/open-sans-600.eot?#iefix') format('embedded-opentype'),
    url('/fonts/open-sans/open-sans-600.woff2') format('woff2'),
    url('/fonts/open-sans/open-sans-600.woff') format('woff'),
    url('/fonts/open-sans/open-sans-600.ttf') format('truetype'),
    url('/fonts/open-sans/open-sans-600.svg#OpenSans') format('svg');
}
@font-face {
  font-display: auto;
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url('/fonts/open-sans/open-sans-600italic.eot');
  src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
    url('/fonts/open-sans/open-sans-600italic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/open-sans/open-sans-600italic.woff2') format('woff2'),
    url('/fonts/open-sans/open-sans-600italic.woff') format('woff'),
    url('/fonts/open-sans/open-sans-600italic.ttf') format('truetype'),
    url('/fonts/open-sans/open-sans-600italic.svg#OpenSans') format('svg');
}
@font-face {
  font-display: auto;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/open-sans/open-sans-700.eot');
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('/fonts/open-sans/open-sans-700.eot?#iefix') format('embedded-opentype'),
  url('/fonts/open-sans/open-sans-700.woff2') format('woff2'),
  url('/fonts/open-sans/open-sans-700.woff') format('woff'),
  url('/fonts/open-sans/open-sans-700.ttf') format('truetype'),
  url('/fonts/open-sans/open-sans-700.svg#OpenSans') format('svg');
}
@font-face {
  font-display: auto;
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url('/fonts/open-sans/open-sans-700italic.eot');
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
  url('/fonts/open-sans/open-sans-700italic.eot?#iefix') format('embedded-opentype'),
  url('/fonts/open-sans/open-sans-700italic.woff2') format('woff2'),
  url('/fonts/open-sans/open-sans-700italic.woff') format('woff'),
  url('/fonts/open-sans/open-sans-700italic.ttf') format('truetype'),
  url('/fonts/open-sans/open-sans-700italic.svg#OpenSans') format('svg');
}
