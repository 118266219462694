@import '../../theme/vars.scss'; 

$buttonArea: 44px;
$modal-paddings: (
  small: 24px 16px,
  default: 32px,
  middle: 24px,
  big: 32px,
  auto: 24px 16px,
  mobile: 14px 16px,
  tablet: 24px
);

$modal-sizes: (
  small: 322px,
  default: 562px,
  middle: 964px,
  big: 1128px,
  auto: 100%,
  mobile: 320px,
  tablet: 590px
);

.wrapper {
  animation-direction: normal;
  animation-duration: 0.2s;
  animation-name: appear;
  animation-timing-function: ease-in;
  background-color: rgba(#000, 0.6);
  bottom: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  white-space: nowrap;
  will-change: opacity;
  @include zIndex(3);

  &::before {
    content: '';
    display: inline-block;
    height: 100vh;
    vertical-align: middle;
    width: 0;
  }

  &_hidden {
    animation-direction: reverse;
  }
}

.modal {
  border-radius: $border-radius-default;
  display: inline-block;
  min-height: 166px;
  position: relative;
  text-align: left;
  vertical-align: middle;
  white-space: initial;
  width: 100%;

  &_fixed_top {
    @include mq(_, lg) {
      margin-top: 50px;
    }
  }

  &_bg {
    &_white {
      background-color: $color-white;
    }

    &_grayLight {
      background-color: $color-gray-100;
    }
  }

  @each $size,
  $value in $modal-sizes {
    &_size_#{$size} {
      max-width: $value;

      @if $size ==small {
        text-align: center;

        @include mq(md) {
          text-align: left;
        }
      }

      @else {
        text-align: left;
      }

      @if $size ==auto {
        height: 100%;
      }

      @else {
        height: auto;
      }

      @include mq(lg) {
        @if $size ==middle {
          max-width: 590px;
        }

        @else if $size ==big {
          max-width: 590px;
        }
      }

      @include mq(md) {
        @if $size ==auto {
          max-width: $value;
        }

        @else {
          background-color: $color-transparent;
          max-width: 100%;
        }
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;

    @each $size,
    $value in $modal-paddings {
      &_padding_#{$size} {
        padding: $value;

        @include mq(lg) {
          @if $size ==middle {
            padding: $spacing-big;
          }

          @else if $size ==big {
            padding: $spacing-big;
          }
        }

        @include mq(md) {
          background-color: $color-white;
          min-height: calc(100vh - $buttonArea);
          padding: $spacing-big $spacing-default;
        }
      }
    }
      
    &_bg {
      &_white {
        background-color: $color-white;
      }

      &_grayLight {
        background-color: $color-gray-100;
      }
    }
  }

  &__title {
    color: $color-black;
    line-height: $line-height-default;
    margin-bottom: $spacing-small;

    font: {
      size: $font-size-h2;
      weight: $font-bold;
    }

    &_size_small {
      font-size: $font-size-base;

      @include mq(md) {
        font-size: $font-size-h2;
      }
    }

    &_empty {
      padding: 0;
    }
  }
}

.close {
  background: $color-white;
  border-radius: $border-radius-default;
  cursor: pointer;
  margin-left: calc(100% + 5px);
  padding: $spacing-small 0;
  position: sticky;
  text-align: center;
  top: 0;
  width: 40px;

  @include mq(md) {
    background: $maincolor;
    margin-left: auto;
    position: relative;
    @include zIndex(44);
  }

  @include desktopHover {
    .icon {
      filter: $filter-image-orange;
    }
  }

  &__parent {
    position: sticky;
    right: 0;
    top: 0;

    @include mq(md) {
    align-items: center;
    background: $maincolor;
    display: flex;
    height: $buttonArea;
    justify-content: center;
    @include zIndex(5);
    }
  }

  &__container {
    position: absolute;
    right: 0;
    top: 0;

    @include mq(md) {
      position: static;
    }
  }
}

.icon {
  height: 18px;
  width: 40px;

  @include mq(md) {
    filter: invert(1);
  }
}
