@import '../../theme/vars.scss'; 

.container {
  font-size: $font-size-base;
  min-height: 230px;
  position: relative;

  &Loading {
    opacity: 0.7;
  }
}

.desc {
  font-size: $font-size-base;
  padding-bottom: $spacing-small;
  padding-top: $spacing-small;
}

.carts {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: $spacing-small;
  width: auto;
}

.loading {
  background-image: url('/images/preloader/loader.gif');
  height: 50px;
  left: 50%;
  position: absolute;
  top: 75px;
  width: 50px;
}

.cart {
  margin-right: $spacing-small;
  width: 300px;

  &:nth-child(3n) {
    margin-right: 0;
  }
}
