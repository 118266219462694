$font-size-24px: 24px;
$font-size-h1: 18px;
$font-size-h2: 16px;
$font-size-h3: 14px;
$font-size-base: 13px;
$font-normal: 400;
$font-semibold: 600;
$font-bold: 700;

$line-height-small: 1;
$line-height-default: 1.5;
$line-height-big: 2;