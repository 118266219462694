@import '../../theme/vars.scss'; 

a {
  color: inherit;
  text-decoration: none;
}

.app {
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
  min-width: 1183px;
  position: relative;

  @include mq(lg) {
    min-width: 320px;
  }
}

@supports (flex: 1) {
  .appContent {
    flex: 1;
  }
}
