@import '../../theme/vars.scss'; 

.menu {
  align-items: center;
  background-color: $marks-menu-bg;
  border: 1px solid $marks-menu-border;
  border-radius: $border-radius-input;
  bottom: 0;
  display: flex;
  font-size: $font-size-base;
  height: 42px;
  justify-content: space-between;
  margin-left: $spacing-small;
  margin-top: $spacing-small;
  padding-bottom: $spacing-tiny;
  padding-left: $spacing-default;
  padding-right: $spacing-default;
  width: auto;

  &List {
    display: flex;
  }

  &Item {
    &:not(:last-child) {
      margin-right: $spacing-default;
    }

    @include desktopHover {
      color: $link-color-hover;
    }
  }
}

.link {
  &Important {
    color: $color-red;
    font-weight: $font-semibold;
  }
}
