@import '../../theme/vars.scss'; 

$sizes: (
  small: font-size-base,
  default: $font-size-base,
  big: $font-size-h2
);

$weights: (
  normal: $font-normal,
  semibold: $font-semibold,
  bold: $font-bold
);

.link {
  flex: 1;
  text-decoration: none;

  @each $size,
  $value in $sizes {
    &_size_#{$size} {
      font-size: $value;
    }
  }

  @each $size,
  $value in $weights {
    &_weight_#{$size} {
      font-weight: $value;
    }
  }

  @include desktopHover {
    color: $link-color-hover;
    cursor: pointer;
    text-decoration: none;
  }

  &_color {
    &_default {
      color: $link-color;
    }

    &_white {
      color: $color-white;

      @include desktopHover {
        color: $color-white;
        text-decoration: underline;
      }
    }

    &_blue {
      color: $color-blue-200;
    }

    &_pink {
      color: $color-pink;

      @include desktopHover {
        color: $color-purple;
      }
    }
  }

  &_underline {
    text-decoration: underline;
  }

  &_disabled {
    color: $color-gray-100;
    pointer-events: none;

    @include desktopHover {
      cursor: default;
    }
  }

  &_active {
    color: $color-gray-300;
    text-decoration: none;
  }
}
