@import '../../theme/vars.scss'; 

.footer {
  bottom: 0;
  color: $color-white;
  left: 0;
  min-height: 180px;
  position: relative;
  right: 0;
  @include zIndex(19);
}

.container {
  background: $maincolor;
  position: relative;

  &Bookmark {
    background-image: linear-gradient(180deg, $color-orange 0%, $color-red 100%);
  }

  &Loyalty {
    background-color: $color-purple;
  }
}

.wrapper {
  display: flex;
  padding: $spacing-big 0;
  position: relative;
}

.aboutContent {
  width: 360px;
}

.menuContent {
  width: 830px;
}

.info {
  border-right: 1px solid $footer-separator-color;
  display: block;
  margin-right: $spacing-large;
  vertical-align: top;
  width: 323px;

  &Bookmark {
    border-right: 1px solid $bookmarkmaincolorhover;
  }
}

.item {
  margin-bottom: $spacing-default;
}

.lastItem {
  margin-bottom: $spacing-default;

  &:last-child {
    margin-bottom: 0;
  }
}

.title,
.phone {
  color: $color-white;
  margin-bottom: 0;
  margin-top: 0;
  @include typo-base();
}

.text {
  background: transparent;
  border: 0;
  color: $color-white;
  display: block;
  font-size: $font-size-base;
  padding-right: $spacing-default;
}

.socials {
  display: inline-block;
  margin-left: $spacing-tiny;
}

.vk .fb {
  color: $color-white;
  display: inline-block;
  font-size: $font-size-base;
  margin-right: $spacing-tiny;
  min-width: 67px;
  padding: 1px $spacing-tiny;
  text-align: center;
}

.vk {
  background-color: #587a9f;
}

.fb {
  background-color: #3a569d;
}

.social {
  height: 24px;
  line-height: $line-height-default;
  margin: $spacing-tiny 0;
  text-align: center;
  width: 106px;
  @include typo-base();

  &Loyalty {
    border-radius: $border-radius-block;
  }

  &Vk {
    background-color: #507298;
  }

  &Fb {
    background-color: #4367b2;
  }

  &Instagram {
    background-image: linear-gradient(229deg,
    #fec053 0%,
    #d9246b 51%,
    #5442d6 100%);
  }
}

.menuWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacing-default;
  vertical-align: top;

  .text .textLink {
    @include desktopHover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.menuWrapperElement {
  display: block;
  vertical-align: top;
  width: 180px;
}

.menuWrapperSocial {
  display: block;
  vertical-align: top;
  width: 106px;
}

.menu {
  color: $color-white;
  margin-top: $spacing-tiny;

  &Social {
    padding-top: $spacing-tiny;
  }

  li {
    padding-bottom: $spacing-tiny;
  }
}

.link {
  color: $color-white;
  display: inline;
  text-decoration: underline;
}

.basket {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: $spacing-large;
  padding-top: $spacing-tiny;
  width: 180px;

  &Info {
    width: 100%;
  }

  &Currence {
    padding-left: $spacing-tiny;
  }

  &Sum {
    line-height: $line-height-big;
    text-align: center;
    @include typo-base($font-bold);
  }

  &Hint {
    height: 0;
    left: $spacing-big;
    position: relative;
    text-align: center;
    top: -60px;
  }

  &Count {
    background-color: $color-yellow;
    border-radius: 100px;
    color: $color-black;
    display: inline-block;
    font-size: $font-size-base;
    padding: 0 $spacing-small;
    text-decoration: none;

    &Default {
      border-radius: $border-radius-default;
      position: relative;
      top: $spacing-small;
    }
  }

  &Cart {
    border: unset;
    color: $color-orange;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 35px;

    &Link {
      align-items: center;
      background-color: $color-white;
      border-radius: $border-radius-block;
      display: flex;
      height: 56px;
      justify-content: center;
      width: 56px;

      &_loyalty {
        border-radius: 100%;
      }
    }

    &Default {
      filter: $filter-image-orange;
    }

    &Loyalty {
      filter: $filter-image-pink;
    }
  }

  &Loyalty {
    border-radius: $border-radius-block;
  }
}
