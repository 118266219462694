@import '../../theme/vars.scss'; 

.certification {
  display: flex;
  flex-direction: column;

  &Info {
    display: block;
  }
}

.tr {
  align-items: center;
  border-bottom: 1px solid $color-gray-100;
  color: $color-black;
  display: flex;
  font-size: $font-size-base;
  line-height: $line-height-default;
  padding: $spacing-tiny 0;
}

.tdName {
  margin-left: $spacing-small;

  &Text {
    padding: 0;
  }

  &Size {
    color: $color-gray-300;
    margin-top: $spacing-tiny;
    white-space: nowrap;
  }
}

.tdDownload {
  margin-left: auto;
  padding-left: $spacing-large;
  white-space: nowrap;

  a {
    padding-left: $spacing-default;
    position: relative;
  }
}

.tdName,
.tdDownload {
  a {
    color: $maincolor;

    @include desktopHover {
      color: $link-color-hover;

      .iconDownload {
        filter: $filter-image-orange;
      }
    }
  }
}

.icon {
  &Download {
    display: inline-flex;
    filter: $filter-image-blue;
    height: 12px;
    left: $spacing-tiny;
    position: absolute;
    top: $spacing-tiny;
    width: 10px;
  }

  &Doc {
    filter: $filter-image-gray;
    height: 13px;
    width: 12px;
  }
}

