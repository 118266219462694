@import '../../theme/vars.scss'; 

.ProductActions {
  &_ProductCard {
    .ProductPush {
      padding-bottom: $product-actions-push-padding-bottom;
      padding-top: $product-actions-push-padding-top;
    }
  }
}

.ProductActions,
.ProductOrder {
  display: flex;
  min-height: 148px;
  width: 100%;
}

.showAnalogsButton {
  background-color: $color-white;
  border: 1px solid $color-gray-200;
  border-radius: $border-radius-button;
  display: flex;
  flex-direction: column;
  padding: $spacing-small;
  width: 100%;
}

.Tabs {
  margin: 0 $product-actions-tabs-margin;
  position: relative;
}

.Panel {
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  padding-top: $spacing-tiny;
  position: relative;
  top: (-$spacing-small);

  &ProductCard {
    display: flex;

    @include mq(lg) {
      background-color: $color-white;
      bottom: 46px;
      display: flex;
      left: 0;
      padding: 0;
      position: fixed;
      top: auto;
      width: 100%;
      @include zIndex(7);
    }
  }

  &Action {
    display: flex;
    flex-direction: column;

    &Product {
      display: flex;

      @include mq(lg) {
        flex-direction: row;
        margin: 0 auto;
        padding: $spacing-default 0;
        width: 590px;
      }

      @include mq(sm) {
        padding: $spacing-default;
        width: 100%;
      }
    }
  }

  &Container {
    display: flex;
    flex-direction: column;

    &Product {
      @include mq(lg) {
        display: grid;
        grid-template-columns: 3fr 1fr;
      }
    }
  }

  &More {
    display: flex;
    flex-direction: row;
    padding-top: $spacing-tiny;
  }

  &_918ok {
    width: 100%;
  }

  &_notInAssortment {
    border-color: $color-green;
  }
}

.ButtonArea {
  padding-top: $spacing-small;

  &Product {
    @include mq(lg) {
      margin-left: $spacing-small;
      padding: 0;
      width: 165px;
    }
  }
}

.UpdateButton {
  background-color: $button-primary-bg;
  border: unset;
  border-radius: $border-radius-button;
  color: $color-white;
  cursor: pointer;
  display: block;
  height: 30px;
  line-height: $line-height-big;
  text-align: center;
  transition-duration: 0.2s;
  transition-property: background-color, opacity;
  transition-timing-function: ease-in;
  width: 100%;
  @include typo-base();

  @include desktopHover {
    background-color: $button-primary-bg-hover;
  }

  &_success {
    background-color: $button-success-color;
    color: $color-white;

    @include desktopHover {
      background-color: $button-success-hover-color;
      color: $color-white;
      opacity: 1;
    }

    &::before {
      height: 30px;
      width: 0;
    }
  }

  &_showAnalogs {
    background-color: $button-wait-bg;
    color: $color-white;

    @include desktopHover {
      background-color: $button-wait-bg-hover;
    }

    &[disabled] {
      background-color: $color-gray-200;

      @include desktopHover {
        background-color: $color-gray-200;
      }
    }
  }

  &_change {
    background-color: $secondcolor;
    color: $color-white;
  }

  &_wait {
    background-color: $button-wait-bg;
    color: $color-white;

    @include desktopHover {
      background-color: $button-wait-bg-hover;
    }
  }

  &.bookmark {
    background-color: $color-white;
    border: 1px solid $bookmarkmaincolor;
    color: $bookmarkmaincolor;

    @include desktopHover {
      border: 1px solid $bookmarkmaincolorhover;
      color: $bookmarkmaincolorhover;

      .buttonIconBookmark {
        filter: $filter-image-orange;
      }
    }

    &[disabled] {
      background-color: $color-gray-200;
      border: 1px solid $color-gray-200;
      color: $color-white;
    }
  }

  &_change.bookmark {
    background-color: $bookmarkmaincolorhover;
    border: unset;
    color: $color-white;

    @include desktopHover {
      background-color: $bookmarkmaincolorhover;
      border: unset;
      color: $color-white;
    }
  }

  &_success.bookmark {
    background-color: $bookmarkmaincolor;
    border: unset;
    color: $color-white;

    @include desktopHover {
      background-color: $bookmarkmaincolorhover;
      border: unset;
      color: $color-white;
    }
  }
}

.ProductPush {
  display: flex;
  justify-content: space-between;
  padding: $spacing-small 0 0;

  .PushButton {
    &:first-child {
      width: 14px;
    }

    &:last-child {
      width: 12px;
    }
  }
}

.PushButton {
  color: $color-black;
  padding-left: 0;
  padding-right: 0;

  span:first-child {
    padding-left: 0;
  }

  span:last-child {
    padding-right: 0;
  }

  @include desktopHover {
    color: $link-color-hover;
  }

  &_active {
    color: $color-green;
  }
}

.buttonWrapper {
  align-items: center;
  border: 1px solid $color-gray-200;
  border-radius: $border-radius-button;
  cursor: pointer;
  display: flex;
  height: 30px;
  margin-right: $spacing-tiny;
  padding: 0 $spacing-small;
  position: relative;

  @include desktopHover {
    background-color: $color-gray-100;
    color: $color-orange;

    .tooltipWrapper {
      transition-delay: 0.3s;
      visibility: visible;
    }
  }
}

.tooltipWrapper {
  left: 0;
  position: absolute;
  top: 30px;
  transition: visibility;
  visibility: hidden;
  @include zIndex(44);
}

.button {
  &Icon {
    filter: invert(100%);
    height: 15px;
    width: 15px;

    &Bookmark {
      filter: $filter-image-orange;
    }
  }

  &Text {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.buy {
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  font-size: $font-size-base;
  justify-content: center;
  position: relative;
  user-select: none;
  width: 100%;

  &Fixed {
    position: fixed;
    @include zIndex(3);
  }

  &Wrapper {
    display: flex;
    width: 100%;
  }
}

.header {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.name {
  display: inline;
}

.value {
  display: inline;
}

.counts {
  display: flex;
  height: 24px;
  margin-bottom: $spacing-small;
  margin-top: $spacing-tiny;
}

.count {
  align-items: center;
  border-radius: 20px;
  display: flex;
  font-size: $font-size-base;
  height: 22px;
  margin-right: $spacing-tiny;
  padding: $spacing-tiny;

  &Value {
    padding-left: 2px;
    white-space: nowrap;
  }

  &Icon {
    border: 1px solid $color-gray-200;
    border-radius: $border-radius-circle;
    height: 7px;
    margin-right: $spacing-tiny;
    min-width: 7px;
    width: 7px;

    &_active {
      background-color: $color-white;
      border: 1px solid $color-white;
    }

    &_stock {
      border: 1px solid $color-green;

      &Active {
        background-color: $color-green;
      }
    }
  }

  &Header {
    &.countPositive {
      background-color: $color-green;
      color: $color-white;
    }

    &.countZero {
      background-color: $color-red;
      color: $color-white;
    }
  }

  &Positive {
    color: $color-green;
  }

  &Zero {
    color: $color-red;
  }

  &Available {
    background-color: $color-gray-100;
    color: $color-gray-200;
  }

  &Tooltip {
    position: relative;
  }
}

.action {
  background-color: $color-white;
  border-radius: 5px;
  padding: $spacing-small;
  @include zIndex(26);

  &Active {
    @include zIndex(2);

    @include mq(lg) {
      @include zIndex(26);
    }

    &.actionActiveRight {
      box-shadow: -3px -3px 5px $color-gray-200, 0 3px 0 $color-white;
    }

    &.actionActiveLeft {
      box-shadow: 3px -3px 5px $color-gray-200, 0 3px 0 $color-white;
    }
  }
}


.stock {
  &Title {
    display: none;

    @include mq(lg) {
      display: flex;
    }
  }

  &First {
    @include mq(lg) {
      align-self: auto;
      flex: 0 1 auto;
      order: 0;
    }
  }

  &Next {
    display: grid;
    grid-template-columns: repeat(3, 33%);
  }

  &List {
    width: 100%;

    @include mq(lg) {
      margin: 0 auto;
      max-width: 590px;
      width: 100%;
    }
  }

  &Border {
    display: table-row;
    height: 4px;

    @include mq(lg) {
      display: none;
    }
  }

  &Hr {
    border-top: 1px solid $color-gray-200;
    display: table-cell;
  }

  &Name {
    align-items: center;
    display: flex;
  }

  &Indent {
    height: auto;

    @include mq(lg){
      display: none;
    }

    &ProductCard {
      width: 380px;
    }

    &Catalog {
      width: 460px;

      @include mq(lg) {
        display: none;
      }
    }
  }

  &Icon {
    height: 18px;
    width: 18px;
  }

  &Select {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    width: 100%;

    &Name {
      font-size: $font-size-base;
      max-width: 75px;
      overflow: hidden;
      padding-left: $spacing-tiny;
      padding-right: $spacing-tiny;
      white-space: nowrap;
    }

    &Value {
      align-items: center;
      display: flex;
      @include typo-base();

      @include desktopHover {
        cursor: pointer;
      }
    }

    &Title {
      white-space: nowrap;
    }
  }

  &Header {
    display: grid;
    font-size: $font-size-base;
    grid-template-columns: 40% 20% 20% 20%;
    width: 100%;

    @include mq(lg) {
      display: none;
    }

    &Name {
      padding: $spacing-small;
      text-align: center;
    }

    &Min {
      padding: $spacing-small;
    }

    &Remain {
      padding: $spacing-small;
    }

    &Wait {
      padding: $spacing-small;
    }
  }

  &Items {
    padding-top: $spacing-tiny;
    width: 100%;
  }

  &El {
    display: table-cell;
    padding: $spacing-tiny $spacing-small;
    vertical-align: middle;
    white-space: nowrap;
  }

  &Item {
    display: grid;
    font-size: $font-size-base;
    grid-template-columns: 40% 60%;

    @include mq(lg) {
      align-content: stretch;
      align-items: stretch;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    @include desktopHover {
      cursor: pointer;
    }

    &Disabled {
      opacity: 0.8;

      @include desktopHover {
        cursor: default;
      }
    }

    &Active {
      background-color: $color-blue-100;
    }
  }
}

.icon {
  &Indent {
    padding-left: $spacing-tiny;
  }

  &Dropdown {
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
  }

  &Up {
    animation-name: rotationUp;
    transform: rotate(180deg);
  }

  &Down {
    animation-name: rotationDown;
    transform: rotate(0deg);
  }

  &Cart {
    filter: $filter-image-gray;
  }
}

.storeList {
  &catalog {
    left: auto;
  }

  &basket {
    left: auto;
  }
}

.buttonCart {
  align-items: center;
  background-color: $color-gray-100;
  border-radius: 3px;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  padding: $spacing-tiny;
  width: 100%;

  &Active {
    background-color: $color-blue;
    color: $link-color-hover;
    cursor: pointer;

    img {
      filter: invert(1);
    }
  }

  @include desktopHover {
    background-color: $color-blue;
    cursor: pointer;

    img {
      filter: invert(1);
    }
  }

  @include mq(lg) {
    @include desktopHover {
      background-color: $color-gray-100;
      cursor: pointer;

      img {
        filter: $filter-image-gray;;
      }
    }
  }
}

.content {

  max-width: 234px;
  min-width: 234px;
  position: fixed;
  width: auto;
  @include zIndex(3);

  @include mq(lg) {
    @include zIndex(5);
    background: rgba(0, 0, 0, .6);
    bottom: 0;
    left: 0;
    max-width: 100%;
    right: 0;
    top: 0;
    width: 100%;
  }
}

.actions {
  align-items: flex-start;
  background-color: $color-white;
  border-radius: $border-radius-button;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  margin-bottom: $spacing-tiny;
  margin-top: $spacing-tiny;

  @include mq(lg) {
    border-radius: $border-radius-button $border-radius-button 0 0;
    bottom: 0;
    left: 0;
    margin-bottom: 0;
    position: fixed;
    right: 0;
    width: 100%;
  }

  &Hint {
    padding: $spacing-small $spacing-default;
    @include typo-base();

    @include mq(lg) {
      padding: $spacing-small 0;
    }

  }

  &Carts {
    font-size: $font-size-base;
    width: 100%;
  }

  &Cart {
    display: inline-flex;
    padding: $spacing-tiny $spacing-small;
    width: 100%;

    @include mq(lg) {
      padding: $spacing-tiny 0;
    }

    @include desktopHover {
      background-color: $color-gray-200;
      cursor: pointer;
    }

    &Name {
      overflow: hidden;
      padding-left: $spacing-tiny;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 188px;
    }
  }
}

.cart {
  margin-right: $spacing-tiny;

  &Wrapper {
    display: flex;
    width: 100%;
  }
}

.delivery {
  color: $color-green;
  display: flex;
  font-size: $font-size-base;
  padding-top: $spacing-tiny;

  &Icon {
    filter: $filter-image-green;
    height: 18px;
    margin-right: $spacing-tiny;
    width: 18px;
  }
}

.disabled {
  background-color: $color-gray-300;
  cursor: default;

  @include desktopHover {
    background-color: $color-gray-300;
  }
}

.stocks {
  background-color: $color-white;
  border: 1px solid $color-gray-200;
  border-radius: 5px;
  color: $color-gray-300;
  display: flex;
  min-height: 150px;
  padding: $spacing-small;
  position: absolute;
  top: 54px;
  @include zIndex(28);

  &Active {
    @include zIndex(3);

    @include mq(lg) {
      align-items: center;
      bottom: 0;
      display: flex;
      height: inherit;
      justify-content: center;
      left: 0;
      position: fixed;
      right: 0;
      top: auto;
    }

    &.stocksLeft {
      box-shadow: 3px 3px 5px $color-gray-200, -1px 3px 3px $color-gray-200;
      right: -1px;
    }

    &.stocksRight {
      box-shadow: 3px 3px 5px $color-gray-200, -1px 3px 3px $color-gray-200;
      left: -1px;
    }
  }
}

.interaction {
  display: flex;
  width: 100%;
}
