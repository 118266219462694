@import '../../theme/vars.scss'; 

.wrapper {
  display: flex;
  position: relative;
}

.productBalance {
  align-items: center;
  display: flex;
  justify-content: flex-start;

  &_emptyLoyalty {
    top: 0;
  }
}

.iconBase {
  background-color: $color-green;
  border-radius: $border-radius-circle;
  height: 10px;
  width: 10px;

  &_null {
    background-color: $color-red;
  }
}

.restAmountBase {
  color: $color-green;
  font-size: $font-size-base;

  line-height: $line-height-default;
  margin-left: $spacing-tiny;

  &_state_null {
    color: $color-red;
    margin-left: $spacing-tiny;
  }
}

.iconBasket {
  composes: iconBase;

  &_null {
    background-color: $color-orange;
  }
}

.restAmountBasket {
  composes: restAmountBase;
  line-height: normal;
  margin-left: $spacing-tiny;
  vertical-align: top;

  &_state_null {
    color: $color-orange;
  }
}

.iconProductOne {
  composes: iconBase;

  &_null {
    background-color: $color-red;
  }
}

.amountSeparator {
  color: $color-gray-400;
}

.stockAmountBase {
  color: $color-green;
}

.stockAmountOutOfBase {
  color: $color-red;
}

.stockAmountOther {
  color: $color-blue;
}

.restAmount_loyalty {
  line-height: $line-height-default;
  margin-top: 0;
}

.tooltipShow {
  background: $color-white;
  border-radius: $border-radius-button;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  color: $color-blue;
  font-size: $font-size-base;
  line-height: $line-height-default;
  padding: $spacing-small;
  position: fixed;
  text-align: center;
  width: 228px;
  @include zIndex(34);
}

.formIcon {
  align-items: center;
  display: flex;
  height: 17px;
  justify-content: center;
  width: 17px;
}

.iconInfo {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: $spacing-tiny;
  position: relative;
}
