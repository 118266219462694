@import '../../theme/vars.scss'; 

.dropdownPhone {
  left: $spacing-default;
  padding: $spacing-default;
  top: 81px;
  width: 231px;

  &Name {
    display: block;
    font-weight: $font-bold;
    margin-bottom: $spacing-tiny;
  }

  &Address {
    display: block;
  }
}
